<template>
	<div class="evalvation_new_questionnaire_holder">
		
		<div class="new_questionnaire_intro">
			<span>Obrazec za to študijsko leto še ne obstaja. </span>
			<span v-if="q_type != 1 && q_type != 4">Lahko ustvarite kopijo iz enega od prejšnjih let, ali pa ustvarite praznega.</span>
		</div>

        <br><br>

		<div class="new_questionnaire_select_copy_year">
			Ustvari kopijo obrazca iz leta:
			<select v-model="selected_year">
                <option 
                    v-for="year in removeThisYear" 
                    v-bind:value="year" 
                    v-bind:key="year" 
                    :selected="year == selected_year"
                >
                    {{ year }}
                </option>
            </select>
			<br>
		</div>
		
		<br>
        <div class="button_holder">
            <div class="button" @click="copy()">
                Kopiraj obrazec
            </div>
        </div>

        <br>
        <div class="button_holder" v-if="q_type != 1 && q_type != 4">
            <div class="gray button" @click="create_new()">
                Ustvari prazen obrazec
            </div>
        </div>
		
	</div>
</template>


<script>
	import { mapActions } from 'vuex';

	export default {
		name: 'NewQuestionnaire',
		data: function() {
			return {
				selected_year: ''
			}
		},
		props: {
            q_type: {
                type: [String, Number],
                required: true
            }
        },
		computed: {
			getYear(){
				return this.$store.getters.year;
			},
			
			//remove current year from list
			removeThisYear(){
				let removed = JSON.parse(JSON.stringify(this.getYears));
				delete removed[this.getYear];
				this.selected_year = Object.keys(removed)[0];
				return removed;
			},

			// Get all years
			getYears() {
				return this.$store.getters.getYears;
			},
		},
		methods: { 		
			...mapActions([
                'copyQuestionnaire',
                'createNewQuestionnaire',
			]),
			copy(){
                this.copyQuestionnaire({year_from:this.selected_year, type:this.q_type});
            },
            create_new(){
                this.createNewQuestionnaire({type:this.q_type});
            }
		}
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
