<template>
	<footer>
		<div class="footer_content">
			<!--<span class="author">Avtorji: Peter Hrvatin, Miha Matjašič, Uroš Podkrižnik</span>-->
            <div class="version">Verzija: {{ getVersion }}</div>
            <div class="contact">Kontakt: <a href="mailto:porocanje@uni-lj.si">porocanje@uni-lj.si</a></div>
            <div class="licence">
                <div class="licence_images">
                    <img src="@/assets/img/licence/cc.png" alt="" style="margin-right:2px;"/>
                    <img src="@/assets/img/licence/by.png" alt=""/>
                </div>
                <a href="https://creativecommons.org/licenses/by/4.0/deed.sl">Licencirano po CC BY 4.0</a>
            </div>
		</div>
	</footer>
</template>


<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'Footer',
        data: function() {
            return {
                
            }
        },
        computed: {
            // Get app version
			getVersion(){
				return this.$store.getters.getVersion;
            }
        },
        methods: { 		
            ...mapActions([
                'prepareVersion'
            ]),
        },
        created(){
            this.prepareVersion();
		},
    }
</script>


<style lang="scss" scoped>
	@import "../scss/style.scss";
</style>
