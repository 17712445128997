<template>
    <div>
        <div class="question_add_holder" v-if="getUserType == 2 && (!info_table_columns || Object.keys(info_table_columns).length == 0) && !showAdminInfoTable">
            <div class="as_link icon question_add" title="Dodaj tabelo kot informacijo"
                @click="showAdminInfoTable = true;">
                <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj tabelo kot informacijo
            </div>
        </div>

        <div v-else class="table_content_work_plan">
            <div class="subsection_table_holder">
                <!-- Table -->
                <div class="subsection_table_table">
                    <!-- Table header row -->
                    <div class="row header">
                        <!-- Tools table columns -->
                        <div class="column tools"></div>

                        <!-- Table columns -->
                        <div class="column column_header" v-for="column in info_table_columns" :key="column.id">

                            <span class="text">
                                {{ column.name }}
                            </span>

                            <span class="description">
                                {{ column.description }}
                            </span>

                            <div class="row_header">
                                <div class="as_link icon edit row_child_header" title="Uredi stolpec"
                                    v-if="getUserType == 2">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({
                                        component: 'sections/EditColumnSectionDetailInfoTable',
                                        data: {
                                            name: column.name, description: column.description, column_id: column.id, order_number: column.order_number
                                        }
                                    })" />
                                </div>

                                <div class="as_link icon remove row_child_header" title="Pobriši stolpec"
                                    v-if="getUserType == 2">
                                    <font-awesome-icon :icon="['far', 'trash-alt']"
                                        @click="showModal({ component: 'sections/DeleteColumnSectionDetailTable', data: { column_id: column.id, table: 'info' } })" />
                                </div>
                            </div>
                        </div>

                        <!-- Add column -->
                        <div class="column add-column" title="Dodaj stolpec" v-if="getUserType == 2"
                            @click="showModal({ component: 'sections/CreateColumnDetailInfoTable' })">
                            <div class="as_link icon">
                                <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj stolpec
                            </div>
                        </div>

                        <!-- Tools table columns -->
                        <div class="column tools" v-if="getUserType == 2"></div>
                    </div>

                    <!-- Table data rows -->
                    <div class="row data" v-for="(row, key) in info_table_data" :key="key">
                        <!-- Table data rows level -->
                        <div class="column tools">
                            <div>
                                <select :value="row.level" @change="editRowLevel(key, $event)">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                            </div>
                        </div>

                        <!-- Table columns -->
                        <div class="column" v-for="column in info_table_columns" :key="column.id">
                            <div>
                                <textarea rows="1" :value="row.data[column.id]" :disabled="getUserType != 2"
                                    @change="cellChanged(key, column.id, $event)">
                                </textarea>
                            </div>
                        </div>

                        <!-- Add column - empty column -->
                        <div class="column add-column" v-if="getUserType == 2"></div>

                        <!-- Tools table columns - delete row -->
                        <div class="column tools" v-if="getUserType == 2">
                            <div class="as_link icon" title="Pobriši vrstico" v-if="!isLastRow()">
                                <font-awesome-icon :icon="['far', 'trash-alt']"
                                    @click="showModal({ component: 'sections/DeleteRowSectionDetailTable', data: { row_id: key, table: 'info' } })" />
                            </div>
                        </div>
                    </div>

                    <!-- Table data - add row -->
                    <div class="row add-data">
                        <div class="as_link icon" title="Dodaj vrstico" @click="addSubsectionInfoTableRow()"
                            v-if="Object.keys(info_table_columns).length > 0 && getUserType == 2">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
    name: 'SectionsDetailInfoTable',
    components: {
    },
    data: function () {
        return {
            showAdminInfoTable: false
        }
    },
    computed: {
        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
        active_subsection_id() {
            return this.$store.getters.active_subsection_id;
        },

        // Selected subsection table info
        info_table_columns() {
            if (this.$store.getters.subsection_data.info_table && this.$store.getters.subsection_data.info_table.columns)
                return this.$store.getters.subsection_data.info_table.columns;
            return {};
        },

        // Selected subsection table info
        info_table_data() {
            if (this.$store.getters.subsection_data.info_table && this.$store.getters.subsection_data.info_table.data)
                return this.$store.getters.subsection_data.info_table.data;
            return {};
        },
    },

    methods: {
        ...mapActions([
            'showModal',
            'addSubsectionInfoTableRow',
            'updateInfoTableCell',
            'editSubsectionInfoTableRowLevel'
        ]),

        // When cell is changed
        cellChanged(row_id, column_id, event){
            this.updateInfoTableCell({row_id:row_id, column_id:column_id, value:event.target.value});
            this.info_table_data[row_id].data[column_id] = event.target.value;
        },

        // Change row level
        editRowLevel(row_id, event){
            this.editSubsectionInfoTableRowLevel({row_id:row_id, level:event.target.value});
        },

        // Check if it is the only row in table
        isLastRow() {
            let rowCount = Object.keys(this.info_table_data).length;

            if (rowCount < 2)
                return true;
            else
                return false;
        },
    },
}
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

/* Transicije */
.fade-enter-active {
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
