<template>
    <Notices :tab="0"></Notices>
</template>


<script>
import Notices from './Notices.vue';

export default {
    name: "NoticesGeneral",
    components: {
        Notices,
    },
    data: function () {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
};
</script>


<style lang="scss" scoped>
</style>
