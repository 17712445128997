<template>
	<div class="sections_list_upload">
		<div class="toggle_upload_table button gray"
			@click="showModal({ component: 'attachments/EditAttachments', data: { upload_type: upload_type, tab: tab } })">
			Priponke
			<span v-show="Object.keys(getAttachments).length > 0">({{ Object.keys(getAttachments).length }})</span>
		</div>
		<div class="strategy_group" v-if="parseInt(year.split('/')[0]) >= 2022">
			<div class="strategy_label">
				<span class="semibold">Strategija Univerze v Ljubljani 2022-2027</span>
			</div>
			<div class="strategy_icon as_link" title="Diagram strategije"
				@click="showModal({ component: 'ModalImage', data: { title: 'Strategija Univerze v Ljubljani 2022-2027', image: 'strategy/StrategijaUL_22-27.png' } })">
				<font-awesome-icon :icon="['fas', 'project-diagram']" />

			</div>
			<div class="strategy_icon as_link" title="Dokument strategije" @click="downloadStrategyDocument()">
				<font-awesome-icon :icon="['fas', 'file-pdf']" />
			</div>
		</div>
		<br><br>
		<!--<transition name="expand" @after-enter="afterEnter" @enter="enter" @leave="leave" >
			<div v-show="showUploads" class="upload_list_table">
				<div class="row header">
					<div class="column column_header upload_name">Naslov</div>
					<div class="column column_header upload_type">Datoteka</div>
					<div class="column column_header upload_date">Datum</div>
					<div class="column column_header upload_tools"></div>
				</div>
				<div class="row" v-for="attachment in getAttachments" :key="attachment.id">
					<div class="column upload_name">{{ attachment.name }}</div>
					<div class="column upload_type">{{ getFileExtension(attachment.file_name) }}</div>
					<div class="column upload_date">{{ convertDateTime(attachment.upload_time) }}</div>
					<div class="column upload_tools">
						<div class="as_link icon right_margin" title="Shrani priponko">
							<font-awesome-icon :icon="['fas', 'download']" />
						</div>
						<div class="as_link icon" title="Izbriši priponko" @click="showModal({component: 'attachments/DeleteAttachment', data: {attachment_id: attachment.id, type: upload_type}})">
							<font-awesome-icon :icon="['far', 'trash-alt']" />
						</div>
					</div>
				</div>
				<div class="row">
					<div class="column upload_name"><input type="text" v-model="file_name"></div>
					<div class="column upload_type"><input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/></div>
					<div class="column upload_size"></div>
					<div class="column upload_tools">
						<div v-bind:class="buttonClassObject" @click="uploadFile()">
							Dodaj
						</div>
					</div>
				</div>
			</div>
		</transition>-->
	</div>
</template>


<script>
import { mapActions } from 'vuex';
//import moment from 'moment';

export default {
	name: 'SectionsListUpload',
	data: function () {
		return {
			/*file: '',
			file_name: '',
			is_file: false,
			showUploads: false*/
		}
	},
	props: {
		upload_type: {
			type: Number,
			required: true
		},
		tab: {
			type: Number,
			default: 0
		}
	},
	computed: {
		//Get object of class for button
		/*buttonClassObject: function () {
			return {
				'button': this.is_file,
				'button-disabled': !this.is_file,
			}
		},*/

		//Get object of attachments
		getAttachments() {
			if (this.upload_type == 1) {
				return this.filter_faculty_attachments_by_tab();
			}
			else if (this.upload_type == 2) {
				return this.$store.getters.section_attachments;
			}
		},

		year() {
			return this.$store.getters.year;
		},
	},
	methods: {
		...mapActions([
			/*'uploadFacultyAttachment',
			'deleteAttachment',
			'uploadSectionAttachment',*/
			'showModal'
		]),

		downloadStrategyDocument() {
			window.open('https://www.uni-lj.si/assets/Sluzba-za-spremljanje-kakovosti-analize-in-porocanje/Strategija-UL-2022-2027.pdf');
		},

		filter_faculty_attachments_by_tab() {
			return Object.values(this.$store.getters.faculty_attachments).filter((row) => {
				return (row.tab == this.tab);
			});
		},

		/*handleFileUpload(){
			this.file = this.$refs.file.files[0];
			this.is_file = this.file ? true : false;
		},

		//Execute upload file
		uploadFile(){
			if(this.is_file){
				if(this.upload_type == 1){
					this.uploadFacultyAttachment({file: this.file, file_name: this.file_name});
				}
				else if(this.upload_type == 2){
					this.uploadSectionAttachment({file: this.file, file_name: this.file_name, section_id: this.$route.params.section_id});
				}

				this.file = '';
				this.file_name = '';
				this.is_file = false;
				this.$refs.file.value = '';
			}
		},

		//Convert date and time
		convertDateTime: function (date) {
			if(date == '0000-00-00 00:00:00')
				return 'ni določen';
			return moment(date, 'YYYY-MM-DD HH:mm:ss').format('D. M. YYYY  HH:mm:ss');
		},

		//Get file extension
		getFileExtension(name){
			name = name.split("."); 
			return name[name.length - 1]; 
		},
		
		//ANIMATIONS
		//Source: https://github.com/maoberlehner/transition-to-height-auto-with-vue
		afterEnter(element) {
			element.style.height = `auto`;
		},
		enter(element) {
			const { width } = getComputedStyle(element);

			element.style.width = width;
			element.style.position = `absolute`;
			element.style.visibility = `hidden`;
			element.style.height = `auto`;

			const { height } = getComputedStyle(element);

			element.style.width = null;
			element.style.position = null;
			element.style.visibility = null;
			element.style.height = 0;

			// Force repaint to make sure the animation is triggered correctly.
			getComputedStyle(element).height;
			requestAnimationFrame(() => {
				element.style.height = height;
			});
		},
		leave(element) {
			const { height } = getComputedStyle(element);

			element.style.height = height;
			// Force repaint to make sure the animation is triggered correctly.
			getComputedStyle(element).height;
			requestAnimationFrame(() => {
				element.style.height = 0;
			});
		},*/
	},
}
</script>


<style lang="scss" scoped>

.expand-enter-active,
.expand-leave-active {
	transition: height 1s ease-in-out;
	overflow: hidden;
}

.expand-enter,
.expand-leave-to {
	height: 0;
}
</style>
