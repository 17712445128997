<template>
    <div>
        <Graph
            :graph_data="generate_line_graph_data()"
            :graph_layout="graph_layout()" :display_mode_bar="false">
        </Graph>
    </div>
</template>


<script>
import { mapActions } from 'vuex';
import Graph from "@/components/general/Graph.vue";

export default {
    name: 'GraphData',
    components: {
        Graph,
    },
    props: {
        dataset_name: {
            type: [String],
            required: true
        },
        stopnja: {
            type: String,
            required: false
        },
    },
    data: function () {
        return {
            fixed_range: true,
            min_value: 0,
            max_value: 0,
            overflow_percent: 0.07,
        }
    },
    computed: {
        // Get program dashboard info
        dataset_info() {
            if (this.$store.getters.evalvation_dashboard_program && this.$store.getters.evalvation_dashboard_program[this.dataset_name]
                && this.$store.getters.evalvation_dashboard_program[this.dataset_name].info)
                return this.$store.getters.evalvation_dashboard_program[this.dataset_name].info;
            return {};
        },

        // Get program dashboard data
        dataset_data() {
            if (this.$store.getters.evalvation_dashboard_program && this.$store.getters.evalvation_dashboard_program[this.dataset_name]
                && this.$store.getters.evalvation_dashboard_program[this.dataset_name].data)
                return this.$store.getters.evalvation_dashboard_program[this.dataset_name].data;
            return {};
        },
    },

    methods: {
        ...mapActions([
        ]),

        graph_layout() {
            if(this.fixed_range) {
                return this.graph_layout_normal();
            } else {
                return this.graph_layout_no_range();
            }
        },

        graph_layout_normal() {
            return {
                showlegend: true,
                margin: {
                    t: 30,
                    l: 30,
                    r: 30,
                    b: 40
                },
                xaxis: {
                    type: 'category'
                },
                yaxis: {
                    range: [1, 5.4],
                    dtick: 1,
                },
                height: 300, // Example height in pixels
                width: 600, // Example width in pixels
                mode:"lines+markers+text",
            };
        },

        graph_layout_no_range() {
            return {
                showlegend: true,
                margin: {
                    t: 30,
                    l: 30,
                    r: 30,
                    b: 40
                },
                xaxis: {
                    type: 'category'
                },
                yaxis: {
                    range: [this.min_value, this.max_value],
                    //dtick: 1,
                },
                height: 300, // Example height in pixels
                width: 600, // Example width in pixels
                mode:"lines+markers+text",
            };
        },

        generate_line_graph_data() {
            if(this.stopnja !== "tretja stopnja") {
                return this.generate_line_graph_data_normal();
            } else {
                return this.generate_line_graph_data_dr();
            }
        },

        generate_line_graph_data_normal() {
            let data = [];
            let program_values = this.dataset_data.program;
            let faculty_average_values = this.dataset_data.faculty_avg;

            if (program_values && Object.keys(program_values).length > 0) {
                let allCategories = new Set();

                // Extract all unique categories
                for (let year in program_values) {
                    let year_ext = year + '/' + (parseInt(year) + 1) + '<br>(N = ' + program_values[year].N + ')';
                    allCategories.add(year_ext);
                }

                // Sort the categories in ascending order
                let sortedCategories = Array.from(allCategories).sort((a, b) => {
                    const [yearA] = a.split('/');
                    const [yearB] = b.split('/');
                    return parseInt(yearA) - parseInt(yearB);
                });

                let x = sortedCategories;
                let y_faculty_avg = Array(sortedCategories.length).fill(null);
                let y = Array(sortedCategories.length).fill(null);

                for (let year in program_values) {
                    let program_value = program_values[year].value;
                    let faculty_value = faculty_average_values[year]?.value || null;

                    if (parseInt(program_value) > 0) {
                        program_value = parseFloat(program_value);
                        faculty_value = parseFloat(faculty_value);

                        let year_ext = year + '/' + (parseInt(year) + 1) + '<br>(N = ' + program_values[year].N + ')';
                        let index = sortedCategories.indexOf(year_ext);

                        y[index] = program_value;
                        y_faculty_avg[index] = faculty_value;

                        // Set min value just in case we won't have a fixed scale
                        const minNumber = Math.min(program_value, faculty_value);
                        if ((minNumber - (minNumber * this.overflow_percent)) < this.min_value) {
                            this.min_value = (minNumber - (minNumber * this.overflow_percent));
                        }

                        // If value is above 5, we do not have a fixed range
                        if (program_value > 5 || faculty_value > 5) {
                            this.fixed_range = false;

                            const maxNumber = Math.max(program_value, faculty_value);

                            // Set min value
                            if (this.min_value == 0) {
                                this.min_value = (maxNumber - (maxNumber * this.overflow_percent));
                            }

                            // Set min and max value and add 10% for overflow
                            if ((maxNumber + (maxNumber * this.overflow_percent)) > this.max_value) {
                                this.max_value = (maxNumber + (maxNumber * this.overflow_percent));
                            }
                        }
                    }
                }

                data.push({
                    x: x,
                    y: y_faculty_avg,
                    type: 'scatter',
                    name: 'Povprečje članice UL',
                    mode: "lines+markers",
                });

                data.push({
                    x: x,
                    y: y,
                    type: 'scatter',
                    name: 'Povprečje programa<br>(na x osi je prikazano<br>število odgovorov<br>za program)',
                    line: { color: "#E12A26" }, // Change color of the second line (program)
                    text: y.map(String), // Convert y values to strings for display
                    textposition: "top", // Position text above the points
                    mode: "lines+markers+text", // Display lines, markers, and text
                    textfont: {
                        color: "#E12A26", // Color of the text labels for the first series
                    },
                });
            }

            return data;
        },

        generate_line_graph_data_dr() {
            let data = [];
            let allCategories = new Set();

            if (this.dataset_data.program && Object.keys(this.dataset_data.program).length > 0) {
                //sort letnik from highest to lowest (maybe already done in backend)
                const program_values = Object.keys(this.dataset_data.program)
                    .sort((a, b) => b.localeCompare(a))
                    .reduce((obj, key) => {
                        obj[key] = this.dataset_data.program[key];
                        return obj;
                    }, {});

                // Extract all unique categories from all series
                for (let letnik in program_values) {
                    for (let year in program_values[letnik]) {
                        let year_ext = year + '/' + (parseInt(year) + 1);
                        allCategories.add(year_ext);
                    }
                }

                // Sort the categories in ascending order
                let sortedCategories = Array.from(allCategories).sort((a, b) => {
                    const [yearA] = a.split('/');
                    const [yearB] = b.split('/');
                    return parseInt(yearA) - parseInt(yearB);
                });

                // Process each series and align with sorted categories
                for(let letnik in program_values) {
                    let x = [];
                    let y = [];

                    // Initialize y with nulls to align with sorted categories
                    y = Array(sortedCategories.length).fill(null);

                    for (let year in program_values[letnik]) {
                        let letnik_value = program_values[letnik][year].value;

                        if (parseInt(letnik_value) > 0) {
                            letnik_value = parseFloat(letnik_value);

                            let year_ext = year + '/' + (parseInt(year) + 1);
                            let index = sortedCategories.indexOf(year_ext);

                            // Set the corresponding y value
                            y[index] = letnik_value;

                            //set min value just in case we won't have a fixed scale
                            if((letnik_value - (letnik_value * this.overflow_percent)) < this.min_value) {
                                this.min_value = (letnik_value - (letnik_value * this.overflow_percent));
                            }

                            //if value is above 5, we do not have a fixed range
                            if(letnik_value > 5) {
                                this.fixed_range = false;    

                                //set min value
                                if(this.min_value == 0) {
                                    this.min_value = (letnik_value - (letnik_value * this.overflow_percent));
                                }

                                //set min and max value and add 10% for overflow
                                if((letnik_value + (letnik_value * this.overflow_percent)) > this.max_value) {
                                    this.max_value = (letnik_value + (letnik_value * this.overflow_percent));
                                }
                            }
                        }
                    }

                    // Push the data for the current series
                    data.push({
                        x: sortedCategories,
                        y: y,
                        type: 'scatter',
                        name: letnik,
                        mode: letnik === "I. letnik" ? "lines+markers+text" : "lines+markers",
                        line: letnik === "I. letnik" ? { color: "#E12A26" } : {},
                        text: letnik === "I. letnik" ? y.map(String) : [],
                        textposition: "top",
                        textfont: letnik === "I. letnik" ? { color: "#E12A26" } : {},
                    });
                }
            }

            return data;
        },
    }
}
</script>


<style lang="scss" scoped></style>
