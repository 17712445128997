<template>
    <div class="submenu_holder">

        <ul class="submenu items">
            <router-link tag="li" class="item" :to="{ name: 'sectionsDashboard' }" v-if="getUserType > 1"><span class="item_text">Dashboard</span></router-link>			
            <router-link tag="li" class="item" :to="{ name: 'sectionsList' }" exact><span class="item_text">Vsa področja</span></router-link>
            <router-link tag="li" :to="{ name: 'FAQSections' }">Pogosto zastavljena vprašanja</router-link>
            <!-- <router-link tag="li" class="item" :to="{ name: 'analytics' }" v-if="getUserType > 1"><span class="item_text">Analitika</span></router-link> -->
        </ul>

        <div v-show="showyear" class="change_year">
            Študijsko leto: 
            <select @change="changeYear(selected_year)" v-model="selected_year">
                <option 
                    v-for="year in getYears" 
                    v-bind:value="year" 
                    v-bind:key="year" 
                    :selected="year == selected_year"
                >
                    {{ year }}
                </option>
            </select>
        </div>

    </div>
</template>


<script>
	import { mapActions } from 'vuex';
	
	export default {
        name: 'SectionsSubMenu',
        props: {
            showyear: {
                type: Boolean,
                default: true
            }
        },
		data: function() {
			return {
                selected_year: this.$store.getters.year
			}
		},
		computed: {
            getYear(){
				return this.$store.getters.year;
            },
            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },
            // Get all years
			getYears() {
				return this.$store.getters.getYears;
			},
		},
		methods: { 		
            ...mapActions([
				'changeYear',
            ]),
        },
		created(){
            
		}
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
