import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import * as store from './../store/store';

import Login from './../components/login/Login.vue';
import LoginPassword from './../components/login/LoginPassword.vue';
import usersList from './../components/users/usersList.vue';
import Informing from './../components/users/Informing.vue';
import Users from './../components/users/Users.vue';
import userProfile from './../components/users/userProfile.vue';
import NoticesGeneral from './../components/users/NoticesGeneral.vue';
import SetPassword from './../components/users/setPassword.vue';
import FAQGeneral from './../components/users/FAQGeneral.vue';
import FAQSections from './../components/sections/FAQSections.vue';
import FAQWorkPlan from './../components/sections/FAQWorkPlan.vue';
import FAQEvalvation from './../components/evalvation/FAQEvalvation.vue';
import EvalvationDashboard from './../components/evalvation/EvalvationDashboard.vue';
import Sections from './../components/sections/Sections.vue';
import SectionsWorkPlan from './../components/sections/SectionsWorkPlan.vue';
import SectionsList from './../components/sections/SectionsList.vue';
import SectionsDetail from './../components/sections/SectionsDetail.vue';
import SectionsDashboard from './../components/sections/SectionsDashboard.vue';
import Evalvation from './../components/evalvation/Evalvation.vue';
import EvalvationProgramList from './../components/evalvation/EvalvationProgramList.vue';
import EvalvationPrograms from './../components/evalvation/EvalvationPrograms.vue';
import QuestionnaireBuild from './../components/evalvation/EvalvationQuestionnaireBuild.vue';
/* import QuestionnaireBuildDoc from './components/evalvation/EvalvationQuestionnaireBuildDoc.vue'; */
import ProgramDetail from './../components/evalvation/EvalvationProgramDetail.vue';
import EvalvationDepartments from './../components/evalvation/EvalvationDepartments.vue';
import EvalvationDepartmentDetail from './../components/evalvation/EvalvationDepartmentDetail.vue';
import EvalvationDepartmentQuestionnaireBuild from './../components/evalvation/EvalvationDepartmentQuestionnaireBuild.vue';
import EvalvationAnalytics from './../components/evalvation/EvalvationAnalytics.vue';


// Router routes
export const routes = [
    { path: '/podrocja', 
        beforeEnter: (to, from, next) => {
            if (!store.state.user.id) {
                // In some rare cases (when setting password, params not present)
                next({ path: '/login' });
            }else if (store.state.user.access_sections == 1) {
                // User have access to sections
                next();
            } else if (store.state.user.access_work_plan == 1) {
                // User have access to sections
                next({ path: '/program-dela' });
            } else if (store.state.user.access_evalvation == 1) {
                // user does not have access to sections - redirect to evalvation
                next({ path: '/samoevalvacija/programi' });
            }
            else {
                next({ path: '/obvestila' });
            }
        },
        component: Sections, children: [	
        { path: '/', name: 'sectionsList', component: SectionsList },
        { path: '/podrocja/podrobnosti/:section_id', name: 'sectionsDetail', component: SectionsDetail },
        { path: '/podrocja/dashboard', name: 'sectionsDashboard', component: SectionsDashboard },
        { path: '/podrocja/faq', name: 'FAQSections', component: FAQSections },
    ] },

    { path: '/program-dela', 
        beforeEnter: (to, from, next) => {
            if (!store.state.user.id) {
                // In some rare cases (when setting password, params not present)
                next({ path: '/login' });
            } else if (store.state.user.access_work_plan == 1) {
                // User have access to sections
                next();
            }else if (store.state.user.access_sections == 1) {
                // User have access to sections
                next({ path: '/podrocja' });
            } else if (store.state.user.access_evalvation == 1) {
                // user does not have access to sections - redirect to evalvation
                next({ path: '/samoevalvacija/programi' });
            }
            else {
                next({ path: '/obvestila' });
            }
        },
        component: SectionsWorkPlan, children: [	
        { path: '/', name: 'sectionsListWorkPlan', component: SectionsList },
        { path: '/program-dela/podrobnosti/:section_id', name: 'sectionsDetailWorkPlan', component: SectionsDetail },
        { path: '/program-dela/dashboard', name: 'sectionsDashboardWorkPlan', component: SectionsDashboard },
        { path: '/program-dela/faq', name: 'FAQWorkPlan', component: FAQWorkPlan },
    ] }, 
    
    { path: '/samoevalvacija', component: Evalvation, children: [	
        { path: '/', 
            beforeEnter: (to, from, next) => {
                if (!store.state.user.id) {
                    // In some rare cases (when setting password, params not present)
                    next({ path: '/login' });
                } else if (store.state.user.access_evalvation == 1) {
                    //if user is not admin, redirect to programs
                    if (store.state.user.type < 2) {
                        next({ path: '/samoevalvacija/programi' });
                    }
                    else
                        next();
                } else if (store.state.user.access_work_plan == 1) {
                    // User have access to sections
                    next({ path: '/program-dela' });
                }else if (store.state.user.access_sections == 1) {
                    // User have access to sections
                    next({ path: '/podrocja' });
                }else {
                    next({ path: '/obvestila' });
                }
            },
            name: 'evalvationProgramList', component: EvalvationProgramList },
        { path: '/samoevalvacija/podrobnosti/:q_type/:programs', name: 'programDetail', component: ProgramDetail },
        { path: '/samoevalvacija/vprasalnik', name: 'questionnaireBuild', component: QuestionnaireBuild },
        /* { path: '/samoevalvacija/vprasalnik-doc', name: 'questionnaireBuildDoc', component: QuestionnaireBuildDoc }, */
        { path: '/samoevalvacija/programi', name: 'evalvationPrograms', component: EvalvationPrograms },
        { path: '/samoevalvacija/oddelki', name: 'evalvationDepartments', component: EvalvationDepartments },
        { path: '/samoevalvacija/oddelek/:department', name: 'departmentDetail', component: EvalvationDepartmentDetail },
        { path: '/samoevalvacija/oddelki-vprasalnik', name: 'departmentsQuestionnaire', component: EvalvationDepartmentQuestionnaireBuild },
        { path: '/samoevalvacija/samoevalvacija-analitika', name: 'evalvationAnalytics', component: EvalvationAnalytics },
        { path: '/samoevalvacija/faq', name: 'FAQEvalvation', component: FAQEvalvation },
        { path: '/samoevalvacija/dashboard', name: 'evalvationDashboard', component: EvalvationDashboard },
        
    ] },

    { path: '/uporabniki', 
        beforeEnter: (to, from, next) => {
            if (!store.state.user.id) {
                // In some rare cases (when setting password, params not present)
                next({ path: '/login' });
            } else if (store.state.user.type > 0) {
                // User have access to sections
                next();
            } else if (store.state.user.access_work_plan == 1) {
                // User have access to sections
                next();
            }else if (store.state.user.access_sections == 1) {
                // User have access to sections
                next({ path: '/podrocja' });
            } else if (store.state.user.access_evalvation == 1) {
                // user does not have access to sections - redirect to evalvation
                next({ path: '/samoevalvacija/programi' });
            }
            else {
                next({ path: '/obvestila' });
            }
        },
        component: Users, children: [	
        { path: '', name: 'usersList', component: usersList },
        { path: 'obvescanje', name: 'informing', component: Informing },
    ] },
    { path: '/moj-racun', name: 'userProfile', component: userProfile },

    { path: '/login', name: 'login', component: Login },
    { path: '/login-password', name: 'login-password', component: LoginPassword },

    { path: '/faq', name: 'FAQGeneral', component: FAQGeneral },
    { path: '/obvestila', name: 'NoticesGeneral', component: NoticesGeneral },

    { path: '/nastavitev-gesla/:email/:hash', name: 'setPassword', component: SetPassword },
		
	{ path: '*', beforeEnter: (to, from, next) => {
            if (!store.state.user.id) {
                // In some rare cases (when setting password, params not present)
                next({ path: '/login' });
            }else if (store.state.user.access_sections == 1) {
                // User have access to sections
                next({ path: '/podrocja' });
            } else if (store.state.user.access_work_plan == 1) {
                // User have access to sections
                next({ path: '/program-dela' });
            } else if (store.state.user.access_evalvation == 1) {
                // user does not have access to sections - redirect to evalvation
                next({ path: '/samoevalvacija/programi' });
            }
            else {
                next({ path: '/obvestila' });
            }
        }
    }
];


// Init Vue router
Vue.use(VueRouter);
const router = new VueRouter({
	routes: routes,
	mode: 'history',
	base: '/',
});

export default router;