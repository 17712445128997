<template>
    <div class="content section_detail_holder" v-if="Object.keys(section).length > 0">

        <div class="sections_upper_holder">
        <!-- Section title -->
            <div class="section_title" id="section_title">
                <h2>{{ section.name }} v letu {{ year }}</h2>
            </div>

            <div class="file_exports">
                <div 
                    v-if="section.ukrepi != '1'"
                    class="as_link icon" 
                    title="Izvoz Excel" 
                    @click="exportSectionData({format: 'xlsx', section_id: section_id, tab: getTabCode})">
                    <font-awesome-icon :icon="['far', 'file-excel']" />
                </div>
                <div 
                    v-else
                    class="as_link icon" 
                    title="Izvoz Excel" 
                    @click="exportUnitedMeasuresSectionData({format: 'xlsx', section_id: section_id})">
                    <font-awesome-icon :icon="['far', 'file-excel']" />
                </div>
                <div 
                    v-if="section.ukrepi != '1'"
                    class="as_link icon" 
                    title="Izvoz Word" 
                    @click="exportSectionData({format: 'doc', section_id: section_id, tab: getTabCode})">
                    <font-awesome-icon :icon="['far', 'file-word']" />
                </div>
                <div 
                    v-else
                    class="as_link icon" 
                    title="Izvoz Excel" 
                    @click="exportUnitedMeasuresSectionData({format: 'doc', section_id: section_id})">
                    <font-awesome-icon :icon="['far', 'file-word']" />
                </div>
            </div>
        </div>

        <!-- Section description -->
        <div class="section_description">
            <span v-html="section.description"></span>
        </div>

        <!-- Izpis roka za oddajo (datuma) znotraj področja, v kolikor ga je administrator nastavil-->
        <p class="deadline" :class="getDeadlineClass(section.deadline)" v-if="section.deadline !== '0000-00-00'"> Rok za oddajo: {{getDeadlineDate(section.deadline)}}</p> 

        <!-- File upload -->
        <ul-sections-list-upload :upload_type="2" v-if="section.ukrepi != '1'"></ul-sections-list-upload>

        <!-- Progress bar -->
        <div class="shell" v-if="section.ukrepi != '1' && getSectionStatistics" v-bind:class="{ full: getSectionStatistics.stat_percantage == 100 }">
            <div class="bar" :style="{ width: getSectionStatistics.stat_percantage + '%' }"></div>
            <div class="percentage">
                {{ getSectionStatistics.stat_percantage }}% ({{ getSectionStatistics.stat_count_solved_mandatory }}/{{ getSectionStatistics.stat_count_mandatory }})
            </div>
            <br>
        </div>
        <!-- Progress bar -->
                
        <!-- Already submitted warning -->
        <div v-if="getSectionStatistics && getSectionStatistics.submitted == '1'" class="already_submitted_warning warning">
            <text-already-submitted v-if="getDeadlineNotClosed"></text-already-submitted>
            <text-not-submitted-closed v-else></text-not-submitted-closed>
        </div>

        <div class="question_add_holder" v-if="getUserType == 2 && !multipleLevels && section.ukrepi != '1'">
            <br>
            <div class="as_link icon question_add" title="Dodaj stran"
                @click="showModal({component: 'sections/CreateSectionPage'})">
                <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj stran
            </div>
        </div>

        <br v-if="section.ukrepi != '1'">
        <br v-if="!multipleLevels && section.ukrepi != '1'">

        <div class="section_detail_content">

            <!-- Left menu - levels 1, 2, 3 -->
            <ul-sections-detail-level :deadline_not_closed="getDeadlineNotClosed"></ul-sections-detail-level>

            <div class="right" :class="{full_width: !multipleLevels}">

                <!-- Section subsections -->            
                <ul-sections-detail-subsection v-if="!lifelong_learning_hide"></ul-sections-detail-subsection>

                <!-- questions above table -->
                <ul-sections-detail-questions v-if="section.ukrepi != '1' && active_subsection_id != null && !lifelong_learning_hide" 
                    :deadline_not_closed="getDeadlineNotClosed" :section_submitted="(getSectionStatistics && getSectionStatistics.submitted == '1')"
                    :position="0" :is_subsection_united_measures="parseInt(active_subsection_data.united_measures)"></ul-sections-detail-questions>
                <ul-sections-detail-questions-united-measures v-if="section.ukrepi == '1' && active_subsection_id != null && !lifelong_learning_hide" :position="0" :deadline_not_closed="getDeadlineNotClosed"></ul-sections-detail-questions-united-measures>

                <!-- Button for saving input data if full width (when levels are not visible - only one level) -->
                <div v-if="getUserType == 2 && getSectionStatistics && getSectionStatistics.submitted == '1' && !multipleLevels 
                    && active_subsection_id != null/*|| (getSectionStatistics && getSectionStatistics.submit_time !== '0000-00-00 00:00:00' && getDeadlineNotClosed && !multipleLevels)*/" 
                    class="button_holder save_subsection_data short">
                    <div class="button large" @click="showModal({component: 'sections/SectionSaveAfterSubmitted'})">
                        Shrani
                    </div>
                </div>

                <!-- Button for copying table data in other facutlies if full width (when levels are not visible - only one level) -->
                <div v-if="getUserType == 2 && active_subsection_data && active_subsection_data.predefined == 1 && !multipleLevels && active_subsection_id != null" 
                    class="button_holder save_subsection_data">
                    <div class="button large predefined" @click="showModal({component: 'sections/CopyTableOverrideWarning'})">
                        Shrani vsebino tabele za vse članice
                    </div>
                </div>

                <!-- <ul-sections-detail-table-example v-if="getTabCode == 1 && getFacultyAcronym == 'FGG' && section.id == 8"></ul-sections-detail-table-example> -->

                <!-- <div v-else-if="getTabCode == 1" class="image_content_work_plan"> -->
                    <!-- <img 
                        :src="'@static/img/work_plan_images/'+section_id+'/'+getFacultyAcronym+'_1.png'" 
                        onerror="this.onerror=null; 
                            this.src='@static/img/work_plan_images/1.png';"> 
                    <img 
                        :src="'@static/img/work_plan_images/'+section_id+'/'+getFacultyAcronym+'_2.png'" 
                        onerror="this.onerror=null; 
                            this.src='@static/img/work_plan_images/2.png';">  -->
                    <!-- <img 
                        :src="'@static/img/work_plan_images/'+section_id+'/'+getFacultyAcronym+'_1.png'" 
                        onerror="this.style.display='none';"> 
                    <img 
                        :src="'@static/img/work_plan_images/'+section_id+'/'+getFacultyAcronym+'_2.png'" 
                        onerror="this.style.display='none';">
                </div> -->

                <!-- Section table with data --> 
                <ul-sections-detail-table-united-measures v-if="section.ukrepi == '1' && active_subsection_id != null && !lifelong_learning_hide" :deadline_not_closed="getDeadlineNotClosed"></ul-sections-detail-table-united-measures>
                <ul-sections-detail-table v-else-if="active_subsection_id != null && !lifelong_learning_hide" :deadline_not_closed="getDeadlineNotClosed" :section_submitted="(getSectionStatistics && getSectionStatistics.submitted == '1')"></ul-sections-detail-table>
                <br>
                <br>
                <!-- questions below table -->
                <ul-sections-detail-questions v-if="section.ukrepi != '1' && active_subsection_id != null && !lifelong_learning_hide" 
                    :deadline_not_closed="getDeadlineNotClosed" :section_submitted="(getSectionStatistics && getSectionStatistics.submitted == '1')"
                    :position="1" :is_subsection_united_measures="parseInt(active_subsection_data.united_measures)"></ul-sections-detail-questions>
                <ul-sections-detail-questions-united-measures v-if="section.ukrepi == '1' && active_subsection_id != null && !lifelong_learning_hide" :position="1" :deadline_not_closed="getDeadlineNotClosed"></ul-sections-detail-questions-united-measures>
            </div>
        </div>
    </div>

    <div class="content section_detail_holder" v-else>
        <br>
        <div class="section_detail_content">
            <div class="already_submitted_warning warning">
                <text-no-data></text-no-data>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions } from 'vuex';
    import SectionsDetailLevel from './SectionsDetailLevel.vue';
    import SectionsDetailSubsection from './SectionsDetailSubsection.vue';
    import SectionsDetailTable from './SectionsDetailTable.vue';
    import SectionsDetailQuestions from './SectionsDetailQuestions.vue';
    import SectionsDetailQuestionsUnitedMeasures from './SectionsDetailQuestionsUnitedMeasures.vue';
    import SectionsListUpload from './SectionsListUpload.vue';
    import SectionsDetailTableExample from './SectionsDetailTableExample.vue';
    import SectionsDetailTableUnitedMeasures from './SectionsDetailTableUnitedMeasures.vue';

     // For formating dates (European standards)
    import moment from 'moment';
    import router from '../../router/router';

    //Text warning template for already submitted
    var  alreadySubmitted = {
        template: '<p>Področje je že oddano in zaključeno, s tem pa v procesu pregledovanja podatkov na strani administratorja. <br>Ob morebitnih nadalnjih spremembah podatkov kontaktirajte administratorja za razveljavo oddaje.</p>'
    };

    //Text warning template for passed closed deadline
    var notSubmittedClosed = {
        template: '<p>Rok za oddajo in pregled je potekel, urejanje in oddaja ni več mogoča. Kontaktirajte administratorja za navodila naslednjih korakov.</p>'
    };

    //Text warning template for section not exist
    var sectionNoData = {
        template: '<p>Ni podatkov za izbrano področje. Preverite, ali je izbrano študijsko leto združljivo s področjem, do katerega želite dostopati.</p>'
    };
    
	export default {
		name: 'SectionsDetail',
		components: {
            'ul-sections-detail-level': SectionsDetailLevel,
            'ul-sections-detail-subsection': SectionsDetailSubsection,
            'ul-sections-detail-table': SectionsDetailTable,
            'ul-sections-detail-questions': SectionsDetailQuestions,
            'ul-sections-detail-questions-united-measures': SectionsDetailQuestionsUnitedMeasures,
            'text-already-submitted': alreadySubmitted,
            'text-not-submitted-closed': notSubmittedClosed,
            'text-no-data': sectionNoData,
            'ul-sections-list-upload': SectionsListUpload,
            'ul-sections-detail-table-example': SectionsDetailTableExample,
            'ul-sections-detail-table-united-measures' : SectionsDetailTableUnitedMeasures
        },
        data: function() {
			return {
			}
        },
        computed: {
            getTabCode(){
                if(router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela')
                    return 1;
                return 0;
            },

            year(){
                if(this.getTabCode == 1){
                    let year = this.$store.getters.year;
                    return parseInt(year.split("/").pop())+1;
                }
				return this.$store.getters.year;
            },

            // Get loged user faculty id
            getFacultyAcronym() {
                return this.$store.getters.getFaculty.acronym;
            },
            
			// Selected section object
			section(){
                // Get section id from url
                let section_id = this.$route.params.section_id;
                
                // Return section object
                if(this.$store.getters.sections[section_id])
				    return this.$store.getters.sections[section_id];
                else return {};
            },

            section_id(){
                return this.$route.params.section_id;
            },

            active_subsection_id(){
                return this.$store.getters.active_subsection_id;
            },

            section_level(){
                let section_level = this.$store.getters.section_level;
                return section_level;
            },

            lifelong_learning:{
                get(){
                    let lifelong_learning = this.getSectionStatistics.lifelong_learning;      
                    return lifelong_learning;
                },
                set(newName){ 
                    return newName;
                } 
            },

            lifelong_learning_hide(){
                // temporary disable lifelong learning
                /* if(this.section_level == 4 && this.lifelong_learning != '1'){
                    return true;
                } */
                
                return false;
            },

            getSectionStatistics(){
                let section_id = this.$route.params.section_id;
                return this.$store.getters.sections_statistics[section_id];
            },

            //Compute whether deadline is not closed or is
            getDeadlineNotClosed(){
                let section_id = this.$route.params.section_id;
                let deadline_closed_date = this.section.deadlineClosed;

                //No data of deadline closed - allow all
                if(deadline_closed_date == '0000-00-00')
                    return true;

                let date_now = new Date();
                date_now.setHours(0,0,0,0);

                let this_date = new Date(deadline_closed_date);
                this_date.setHours(0,0,0,0);
 
                return date_now.getTime() <= this_date.getTime();
            },

            //for section levels - full width if only one level
            getSectionLevels(){
                return this.$store.getters.subsections_data.levels;
            },
            multipleLevels(){
                let cnt = 0;
                for(let level in this.getSectionLevels){
                    if(this.getSectionLevels[level].enabled == 1)
                        cnt ++;
                    if(cnt > 1)
                        return true;
                }
                return false;
            },

            // Selected subsection data
			active_subsection_data(){
                let level = this.$store.getters.section_level;
                let subsections = this.$store.getters.subsections_data[level];
                let active_subsection = this.$store.getters.active_subsection_id;
				return subsections ? subsections[active_subsection] : {};
            },

            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },
		}, 
        methods: { 		
            ...mapActions([
                'prepareSubsectionsData',
                'prepareSubsectionsRelationInfoTableData',
                'prepareSections',
                'prepareSectionsStat',
                'getSectionAttachments',
                'exportSectionData',
                'exportUnitedMeasuresSectionData',
                'showModal',
            ]),

            // Format date to European standards
            getDeadlineDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('DD. MM. YYYY');
            },
            // Get deadline class (if its passed or not)
            getDeadlineClass : function (date) {
                let deadlineTS = new Date(date).getTime();
                let nowTS = new Date().getTime();

                return deadlineTS < nowTS ? 'deadline_passed' : '';
            }
        },
        created(){

            this.prepareSections();

            // Get section id from url
            let section_id = this.$route.params.section_id;

            this.prepareSubsectionsData(section_id);
            this.prepareSubsectionsRelationInfoTableData(section_id);
            
            if(Object.keys(this.$store.getters.sections_statistics).length === 0){
                this.prepareSectionsStat();
            }

            this.getSectionAttachments({section_id: section_id});
		}
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
