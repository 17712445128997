<template>
	<div class="content sections_dashboard">
        <h2>Seznam oddelkov za leto {{ year }}</h2>

		<!-- Not active year warning -->
		<div v-if="!isActiveYear" class="questionnaire_warning warning">
			<p>Izbrali ste študijsko leto, ki trenutno ni aktivno. Pri urejanju podatkov bodite še posebej previdni!</p>
		</div>

        <div class="table-wrapper user_list">
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th class="lettter_spacing"> Oddelek </th>
						<!--<th> Opis </th>-->
						<!-- <th> Predstojnik</th>
						<th> Vodja komisije</th> -->
						<th class="lettter_spacing align_center" style="width: 10px;"> Zaključeno </th>
					</tr>
				</thead>

				<tbody>
                    <router-link
                        tag="tr"
                        :to="{
                            name: 'departmentDetail',
							params: { department: departnemt.id }
                        }"
                        :key="departnemt.id"
                        v-for="departnemt in departments_list"
                        class="pointer lettter_spacing"
                    >
                        <td>{{ departnemt.name }}</td>
                        <!--<td>{{ departnemt.description }}</td>-->
                        <!-- <td>{{ departnemt.predstojnik }}</td>
                        <td>{{ departnemt.vodja_komisije }}</td> -->

						<td class="align_center" style="width: 10px;"><font-awesome-icon  v-if="departnemt.finished == 1" :icon="['fas', 'check']" /></td>
                    </router-link>
                </tbody>
			</table>
		</div>
    </div>
		
</template>


<script>
import { mapActions } from 'vuex';

export default {
	name: 'EvalvationDepartments',
	components: {
	},
	data: function() {
		return {
        }
	},
	watch: {
		departments_list(newVal, oldVal) {
			if (!newVal || newVal.length == 0)
				this.$router.push({ name: 'evalvationPrograms' });
		}
	},
	computed: {
		year(){
			return this.$store.getters.year;
        },

		// Check if we are in active year
		isActiveYear(){
			return this.$store.getters.is_active_year;
		},
        
        departments_list(){
            let data = this.$store.getters.evalvation_departments ? this.$store.getters.evalvation_departments : {};
			return Object.values(data);
		},

		// Get loged user type
		getUserType(){
			return this.$store.getters.getUser.type;
		},
		
	},
	methods: { 		
        ...mapActions([
			'getDepartmentsListForFaculty',
			'getDepartmentsListForUser'
		])
	},
	created(){
		if(this.getUserType > 0)
			this.getDepartmentsListForFaculty();
		else
			this.getDepartmentsListForUser();
	},
}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
