<template>
	<div class="main_navigation">

		<ul>
            <!-- Show modules based on user priviledges -->
			<router-link tag="li" v-if="getUser.access_sections == 1 || getUserType == 2" :to="{ name: 'sectionsList' }">Poslovno poročanje</router-link>
			<router-link tag="li" v-if="getUser.access_work_plan == 1 || getUserType == 2" :to="{ name: 'sectionsListWorkPlan' }">Letni program dela</router-link>
			<router-link tag="li" v-if="getUser.access_evalvation == 1 || getUserType == 2"  :to="{ name: getEvalvationPage }">Samoevalvacija ŠP</router-link>
		    <router-link tag="li" v-if="getUserType == 1 || getUserType == 2" :to="{ name: 'usersList' }">Uporabniki</router-link>
            <!-- <router-link tag="li" :to="{ name: 'FAQGeneral' }">Pogosto zastavljena vprašanja</router-link> -->
            <router-link tag="li" :to="{ name: 'NoticesGeneral' }">Obvestila</router-link>
		</ul>

        <div class="top_right">
            <div class="user_data" v-if="isAuthenticated">

                <div class="user_dropdown">
                    <div class="as_link" :to="{ name: 'userProfile' }">
                        <span>{{ getUser.name }} {{ getUser.surname }} <font-awesome-icon :icon="['fas', 'sort-down']" /></span>
                        <!--<span>{{ getUser.email }}</span>-->
                    </div>
                    <div class="user_dropdown_content">
                        <router-link tag="span" :to="{ name: 'userProfile' }">Moj račun</router-link>
                        <span @click="logoutUser">Odjava</span>
                    </div>
                </div>

                <div class="menu_right_dropdowns">
                    <div v-if="getUserType == 2">
                        <select class="change_faculty" @change="changeUserType($event)">
                            <option value="2" selected>administrator</option>
                            <option value="1">upravitelj</option>
                            <option value="0">poročevalec/skrbnik</option>
                            <option value="3">pregledovalec</option>
                        </select>
                    </div>
                    <div v-if="getUserType == 2 || getUserType == 3">
                        <select class="change_faculty" @change="changeFaculty(activeFaculty.id)" v-model="activeFaculty.id">
                            <option 
                                v-for="faculty in getSortedFacultiesByAcronym" 
                                v-bind:value="faculty.id" 
                                v-bind:key="faculty.id" 
                                :selected="faculty.id == activeFaculty.id">
                                {{ faculty.acronym }}
                            </option>
                        </select>
                    </div>
                    <div v-else>
                        {{ activeFaculty.name }}
                    </div>
                </div>
                
            </div>

            <div class="mobile_menu_icon" @click="mobileShowMenu = !mobileShowMenu" v-bind:class="{mobileShow: mobileShowMenu}">
                <font-awesome-icon :icon="['fas', 'bars']" class="pointer" />
            </div>
        </div>

	</div>
</template>


<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'Navigation',
        data: function() {
            return {
                mobileShowMenu: false
            }
        },
        watch: {
            activeFaculty(newVal, oldVal) {
                this.prepareFaculties();
            }
        },
        computed: {
            getEvalvationPage(){
                if(this.getUserType > 1)
                    return 'evalvationProgramList'
                else
                    return 'evalvationPrograms'
            },

            // Get loged user type
            getUserType() {
                return this.$store.getters.getUser.type;
            },

            // Current active faculty
            activeFaculty(){
				return this.$store.getters.getFaculty;
            },

			// If user is not logged in hide main menu
			isAuthenticated(){
				return this.$store.getters.isAuthenticated;
            },
            
            // Get loged user data
			getUser(){
                return this.$store.getters.getUser;
            },
            
            // Get all faculties
			getSortedFacultiesByAcronym(){
				return this.$store.getters.getSortedFacultiesByAcronym;
			},
        },
        methods: { 		
            ...mapActions([
                'logoutUser',
                'prepareFaculties',
                'changeFaculty',
                'changeUserTypeTest'
            ]),

            changeUserType(event) {
                const selectedOption = event.target.value;
                this.changeUserTypeTest(selectedOption);
            },
        },
        created(){
            this.prepareFaculties();
		},
    }
</script>


<style lang="scss" scoped>
	@import "../scss/style.scss";
</style>
