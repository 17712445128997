<template>
    <div class="dashboard_faculty_square_holder">
        <div class="dashboard_faculty_square_item">
            <div class="dashboard_faculty_item_left">
                <div class="as_link_grey dashboard_faculty_item_name" @click="changeFaculty(faculty_data.faculty_data.id)">
                    {{faculty_data.faculty_data.acronym}} - {{faculty_data.faculty_data.name}}
                </div>
                <br><br>
                <div class="deadline_status" :class="deadlineStatus">{{status_text}}</div>
                <div class="dashboard_faculty_item_progress">
                    Izpolnjeno: {{faculty_data.faculty_fill_percent}}%
                </div>
                <br><br><br>
                <!-- <div v-show="nextDeadlineTimestamp > 0" class="dashboard_faculty_square_item_deadline">
                    Naslednji rok: {{nextDeadline()}}
                </div> -->
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
    name: 'SectionsDashboardFacultySquareItem',
	
	data: function() {
		return {
            nextDeadlineTimestamp: 0,
            nextDeadlineClosedTimestamp: 0,
            status_text: '',
            showSections: false,
            nearDeadlineDays: 14
		}
    },
    props:{
        faculty_data: {
            type: Object,
            required: true
        }
    },
	computed: {    
        deadlineStatus(){
            let temp_date = new Date();
            let timestamp_now = temp_date.getTime();

            //If all submitted
            if(this.faculty_data.section_submitted_count == this.faculty_data.section_count){
                this.status_text = 'oddano';
                this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_filled'});
                return 'filled';
            }
            //If nearest deadline is already closed
            else if(this.nextDeadlineClosedTimestamp > 0 && timestamp_now > this.nextDeadlineClosedTimestamp){
                this.status_text = 'zamujeno';
                this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_after_late'});
                return 'after_late';
            }
            //If deadline exists
            else if(this.nextDeadlineTimestamp > 0){
                //If deadline is passed
                if(timestamp_now > this.nextDeadlineTimestamp){
                    this.status_text = 'zamuda';
                    this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_after'});
                    return 'after';
                }
                //If we are near deadline (14 days)
                else if(timestamp_now > this.nextDeadlineTimestamp - this.nearDeadlineDays*86400000){
                    this.status_text = 'v pripravi';
                    this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_preparation_late'});
                    return 'preparation_late';
                }
                //In preparation
                else {
                    this.status_text = 'v pripravi';
                    this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_preparation'});
                    return 'preparation';
                }
            }
            //In preparation
            else {
                this.status_text = 'v pripravi';
                this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_preparation'});
                return 'preparation';
            }
        },
        
    },
    methods: {
        ...mapActions([
            'changeFaculty',
            'dashboardAddLastStatusByFaculty'
        ]),
        
        nextDeadlineClosed(){
            let sections = this.faculty_data.sections;
            let next_deadline = null;
            if(sections){
                for(let section in sections){
                    let this_date = sections[section].deadlineClosed;
                    if(this_date !== '0000-00-00'){
                        let temp_date = new Date(this_date);
                        if(next_deadline == null || next_deadline.getTime() < temp_date.getTime())
                            next_deadline = temp_date;
                    }
                }
            }
            if(next_deadline)
                this.nextDeadlineClosedTimestamp = next_deadline.getTime();
            else
                this.nextDeadlineClosedTimestamp = 0;
        },

        nextDeadline(){
            let sections = this.faculty_data.sections;
            let next_deadline = null;
            if(sections){
                for(let section in sections){
                    let this_date = sections[section].deadline;
                    if(this_date !== '0000-00-00'){
                        let temp_date = new Date(this_date);
                        if(next_deadline == null || next_deadline.getTime() < temp_date.getTime())
                            next_deadline = temp_date;
                    }
                }
            }

            this.nextDeadlineClosed();

            if(next_deadline){
                this.nextDeadlineTimestamp = next_deadline.getTime();
                return next_deadline.toLocaleDateString("sl-SL");
            }
            else{
                this.nextDeadlineTimestamp = 0;
                return '';
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "../../scss/style.scss";
</style>

