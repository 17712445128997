<template>
    <div>
        <div class="question_title_row">
            <span class="as_link icon edit" title="Uredi vprašanje"
                v-if="build_mode && (getUserType == 2 || (getUserType == 1 && (is_internal_questionnaire || (q_type !== 1 && q_type !== 4))))">
                <font-awesome-icon :icon="['fas', 'pencil-alt']"
                    @click="showModal({
                        component: 'evalvation/EditQuestionnaireQuestion',
                        data: {
                            title: question_data.title, id: question_data.id, order_number: question_data.order_number, description: question_data.description, breakk: question_data.break, q_type: q_type, images: question_data.images,
                            skippable: question_data.skippable, improvements: question_data.improvements, id_last_year: question_data.id_last_year, stopnja: question_data.stopnja, vrsta: question_data.vrsta, lastnost: question_data.lastnost
                        }
                    })" />
            </span>
            <span class="as_link icon remove" title="Pobriši vprašanje"
                v-if="build_mode && (!question_data.improvements || question_data.improvements == 0) && (getUserType == 2 || (getUserType == 1 && (is_internal_questionnaire || (q_type !== 1 && q_type !== 4))))">
                <font-awesome-icon :icon="['far', 'trash-alt']"
                    @click="showModal({ component: 'evalvation/DeleteQuestionnaireQuestion', data: { id: question_data.id, order_number: question_data.order_number } })" />
            </span>

            <span class="question_title">
                <b>{{ (question_data.order_number - internal_hidden_questions_num) }}. {{ question_data.title }}</b>
                <i v-if="build_mode && question_data.improvements && question_data.improvements == 1">&nbsp;(vprašanje je
                    vključeno v tabelo predlogov izboljšav)</i>
            </span>
        </div>

        <div class="question_description" v-if="question_data.description && question_data.description !== ''"
            v-html="question_data.description"></div>

        <div class="question_images" v-if="!build_mode && question_data.images && show_images_program && !hide_images">
            <div class="question_images_tabs" v-show="Object.keys(question_data.images).length > 1">
                <div class="question_images_tab" v-for="image in question_data.images" :key="image.id"
                    :class="{ selected: image.code == selected_image }" @click="selected_image = image.code"
                    v-show="show_on_level(image.stopnja)">
                    {{ image.name }}
                </div>
            </div>
            <div class="question_images_group">
                <p class="addition_program" v-if="program_data.additional_programs">
                    {{ program_data.name }} ({{ program_data.ul_program_id }})
                </p>
                <div class="image_content" :class="(image.type == 1) ? 'big' :
                    (/*Object.keys(question_data.images).length > 1 ||*/ build_mode) ? 'small' : ''"
                    v-for="image in question_data.images" :key="image.id"
                    v-show="image.code == selected_image && show_on_level(image.stopnja)">

                    <p>{{ image.description }}</p>

                    <TableDataset v-if="image.type == 1" :dataset_name="image.code"></TableDataset>
                    <GraphDataset v-else-if="image.type == 0" :dataset_name="image.code" :stopnja="program_data.stopnja"></GraphDataset>

                    <!-- <img :src="getImagePath(image.type, image.title, program_data.ul_program_id)" @error="onImageError($event, image.type)" :alt="question_data.images.title"> -->
                </div>
            </div>
            <div v-if="program_data.additional_programs">
                <div v-for="additional_program in program_data.additional_programs" :key="additional_program.id"
                    class="question_images_group">
                    <p class="addition_program">{{ additional_program.name }} ({{ additional_program.ul_program_id }})</p>
                    <div class="image_content" :class="(image.type == 1) ? 'big' :
                        (/*Object.keys(question_data.images).length > 1 ||*/ build_mode) ? 'small' : ''"
                        v-for="image in question_data.images" :key="image.id"
                        v-show="image.code == selected_image && show_on_level(image.stopnja)">

                        <p>{{ image.description }}</p>

                        <TableDataset v-if="image.type == 1" :dataset_name="image.code"></TableDataset>
                        <GraphDataset v-else-if="image.type == 0" :dataset_name="image.code" :stopnja="program_data.stopnja"></GraphDataset>
                        
                        <!-- <ul-eval-question-table 
                            :build_mode="build_mode" :q_type="q_type" :table_info="q_type" >
                        </ul-eval-question-table> -->
                        <!-- <img :src="getImagePath(image.type, image.title, additional_program.ul_program_id)" @error="onImageError($event, image.type)" :alt="question_data.images.title"> -->
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div v-if="!build_mode && programs_id.length == 1 && q_type == 1 && program_breaks && question_data.break == 1">
                <div v-for="breakk in program_breaks" :key="breakk.id">
                    <br>
                    <div class="break_title">
                        <b>{{ breakk.name }}</b>
                    </div>

                    <div class="break_description" v-if="breakk.description && breakk.description !== ''">
                        {{ breakk.description }}
                    </div>

                    <textarea v-if="questionnaire_data[question_data.id] && check_break(question_data.id)"
                        @onload="auto_grow($event)" :class="textarea_sample" type="text" rows="4" :disabled="disable_input"
                        v-model="questionnaire_data[question_data.id].breaks[breakk.id]"
                        @change="saveBreakAnswer(question_data.id, breakk.id, $event)"></textarea>
                </div>
            </div>
            <!--<div v-else-if="q_type == 1 && program_breaks && question_data.break == 1 && !build_mode">
                <div v-for="breakk in program_breaks" :key="breakk.id">
                    <div class="break_description">
                        Nekateri programi pri tem vprašanju vsebujejo členitve. Vrednosti členitev je potrebno izpolniti posamično, v vsakem programu posebej (ni mogoče s skupnim izpolnjevanjem)! 
                    </div>
                </div>
            </div>-->
            <div v-else-if="question_data.type == 1">
                <div v-if="locked_input && !force_unlock && ((program_data.finished == 0 && program_data.submitted == 0 && program_data.disabled == 0) || getUserType == 2)"
                    class="locked_info_text">
                    Polje ste izpolnili že prejšnje leto. To polje se izpolni vsaki dve leti pod pogojem, da se
                    sestavine študijskega programa niso spremenile. V primeru sprememb sestavin programa ali bistvenih
                    odstopanj v trendih podatkov polje odklenite in ga izpolnite.
                </div>
                <div class="questoion_textarea_holder">
                    <AutoResizeTextarea 
                        :class="textarea_sample"
                        :disabled="disable_input" :value="question_answer" :qid="question_data.id"
                        @change="saveAnswer" name="textarea_answer">
                    </AutoResizeTextarea>
                    <div v-if="locked_input && !force_unlock && ((program_data.finished == 0 && program_data.submitted == 0 && program_data.disabled == 0) || getUserType == 2)"
                        class="as_link icon unlock_question_link" title="Odkleni vprašanje" @click="unlockQuestion">
                        <font-awesome-icon :icon="['fas', 'lock']" />
                    </div>
                    <div v-else-if="!build_mode && ((program_data.year == '2020/2021' && program_data.skippable == 1) || (question_data.id_last_year !== null && question_answer_last_year))
                        && question_data.skippable == 1 && ((program_data.finished == 0 && program_data.submitted == 0 && program_data.disabled == 0) || getUserType == 2)"
                        class="as_link_grey icon unlock_question_link" title="Zakleni vprašanje" @click="lockQuestion">
                        <font-awesome-icon :icon="['fas', 'unlock']" />
                    </div>
                </div>
            </div>
            <div v-else-if="question_data.type == 2">
                <div v-if="locked_input && !force_unlock && ((program_data.finished == 0 && program_data.submitted == 0 && program_data.disabled == 0) || getUserType == 2)"
                    class="locked_info_text">
                    Polje ste izpolnili že prejšnje leto. To polje se izpolni vsaki dve leti pod pogojem, da se
                    sestavine študijskega programa niso spremenile. V primeru sprememb sestavin programa ali bistvenih
                    odstopanj v trendih podatkov polje odklenite in ga izpolnite.
                    <br><br>
                </div>
                <ul-eval-question-improvements v-if="question_data.improvements == 1" :build_mode="build_mode"
                    :question_order_num="question_order_num" :q_type="q_type" @unlock_clicked="force_unlock = true"
                    @lock_clicked="force_unlock = false"></ul-eval-question-improvements>
                <ul-eval-question-table v-else :build_mode="build_mode" :question_order_num="question_order_num"
                    :q_type="q_type" @unlock_clicked="force_unlock = true" @lock_clicked="force_unlock = false">
                </ul-eval-question-table>
            </div>

            <div v-if="(!disable_input || question_answer_attachments > 0) && (q_type == 1 || q_type == 4)"
                class="sections_list_upload for_question">
                <div class="for_question button gray"
                    @click="showModal({
                        component: 'attachments/EditAttachments',
                        data: { upload_type: 4, question_id: question_data.id, program_id: get_one_program_id, additional_programs: Array.from(programs_id).slice(1), read_only: disable_input }
                    })">
                    Priponke
                    <span
                        v-show="question_answer_attachments > 0">({{ question_answer_attachments }})</span>
                </div>
            </div>

            <div class="question_add_holder"
                v-if="build_mode && page_data[active_page_id] && ((q_type !== 1 && q_type !== 4) ||
                    ((getUserType == 2 && active_page_id !== 'RU' && page_data[active_page_id].order_number != 1) || is_internal_questionnaire))">
                <div class="as_link icon question_add" title="Dodaj vprašanje" @click="showModal({
                    component: 'evalvation/CreateQuestionnaireQuestion',
                    data: { q_type: q_type, order_number: parseInt(question_data.order_number) + 1 }
                })">
                    <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vprašanje
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';
import QuestionTable from './EvalvationQuestionnaireQuestionTable.vue';
import QuestionImprovements from './EvalvationQuestionnaireQuestionImprovements.vue';
import TableDataset from './datasets/TableData.vue';
import GraphDataset from './datasets/GraphData.vue';
import AutoResizeTextarea from '../general/AutoResizeTextarea.vue';

export default {
    name: 'EvalvationQuestionnaireQuestion',
    components: {
        'ul-eval-question-table': QuestionTable,
        'ul-eval-question-improvements': QuestionImprovements,
        TableDataset,
        GraphDataset,
        AutoResizeTextarea
    },
    props: {
        build_mode: {
            type: Boolean,
            required: true
        },
        q_type: {
            type: [String, Number],
            required: true
        },
        question_order_num: {
            type: [String, Number],
            required: true
        },
        hidden_questions_num: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data: function () {
        return {
            selected_image: '',
            force_unlock: false,
            no_image_count: 0,
            internal_hidden_questions_num: this.hidden_questions_num
            //isEmptyString: false
        }
    },
    computed: {
        // Selected questions in active page
        question_data() {
            if (this.active_page_id && this.active_page_id !== 'RU' && this.$store.getters.evalvation_questionnaire_detail.pages) {
                if (this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id] &&
                    this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id].questions)
                    return this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id].questions[this.question_order_num];
                else
                    return this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id].questions[this.question_order_num];
            }
            return null;
        },
        // Selected questionnaire object for program
        page_data() {
            if (this.active_page_id && this.$store.getters.evalvation_questionnaire_detail.pages && this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id])
                return this.$store.getters.evalvation_questionnaire_detail.pages;
            else if (this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id])
                return this.$store.getters.evalvation_internal_questionnaire_detail.pages;
            else
                return {};
        },

        // in case question has images, set first image as selected
        get_first_image_title() {
            if (!this.selected_image && this.question_data.images && Object.keys(this.question_data.images).length > 0) {

                //go trough all images and find first visible image based on level
                for (let image of Object.values(this.question_data.images)) {
                    if (image.stopnja == 0)
                        return image.code;
                    else if (image.stopnja == 1 && (this.program_data.stopnja == 'prva stopnja' || this.program_data.stopnja == 'druga stopnja' || this.program_data.stopnja == 'izpopolnjevanje'))
                        return image.code;
                    else if (image.stopnja == 3 && this.program_data.stopnja == 'tretja stopnja')
                        return image.code;
                }

                // Get title of first question image
                //return this.selected_image = Object.values(this.question_data.images)[0].title;
            }
            return '';
        },

        is_internal_questionnaire() {
            return this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id];
        },

        //true if need to hide images section of question
        hide_images() {
            let multiply = (this.program_data.additional_programs ? Object.keys(this.program_data.additional_programs).length + 1 : 1);

            return (this.no_image_count == Object.keys(this.question_data.images).length * multiply &&
                this.page_data[this.active_page_id] && this.page_data[this.active_page_id].skip_enabled == 1);
        },

        //hide image sector if no mages for this stopnja
        show_images_program() {
            if (this.question_data.images) {
                for (let image in this.question_data.images) {
                    if (this.show_on_level(this.question_data.images[image].stopnja))
                        return true;
                }
            }
            return false;
        },

        //Get breaks
        program_breaks() {
            //if(this.getUserType == 2)
            return this.$store.getters.evalvation_program_detail.breaks;
            /*else
                return this.$store.getters.evalvation_programs_user[this.programs_id].breaks;*/
        },

        //get IDs of all programs for this questionnaire
        programs_id() {
            if (this.q_type == 1 || this.q_type == 4)
                return this.$route.params.programs.toString().split(",");
            else
                return this.$route.params.department;
        },

        get_one_program_id() {
            if (Array.isArray(this.programs_id))
                return this.programs_id[0];
            return this.programs_id;
        },

        // Get program data
        program_data() {
            if (this.build_mode)
                return {};

            //if user is admin, pull program data from admins program object
            if (this.programs_id.length > 0 || Number.isInteger(this.programs_id)) {
                return this.$store.getters.evalvation_program_detail;
            }
        },

        //convert year, switch / for -
        yearConvertCharts() {
            return this.program_data.year.replace('/', '-');
        },

        //id of active page
        active_page_id() {
            return this.$store.getters.active_q_page_id;
        },

        //get answers for this questionnaire
        questionnaire_data() {
            if (this.build_mode)
                return {};
            else {
                return this.$store.getters.questionnaire_data;
            }
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        //class for sample or input
        textarea_sample() {
            if (this.build_mode)
                return 'textarea_sample';
            else if (this.question_data.every_two_years == 1) {
                return 'textarea_sample';
            }
            return '';
        },

        //check if question has table data
        has_question_rows() {
            return (this.questionnaire_data[this.question_data.id] && this.questionnaire_data[this.question_data.id].table_rows);
        },

        disable_input() {
            return this.getUserType == 3 || ((this.locked_input && !this.force_unlock) || this.build_mode ||
                (this.getUserType < 2 && !this.is_internal_questionnaire &&
                    (this.q_type == 1 || this.q_type == 4) &&
                    (this.program_data.finished == 1 || this.program_data.submitted == 1 || this.program_data.disabled == 1)));
        },

        locked_input() {
            if (!this.build_mode) {
                //if question is table and already have data inside
                if (this.has_question_rows)
                    return false;

                //for first year, before connected questions
                if (this.program_data.year == '2020/2021') {
                    let programs_skippable = 1;

                    //if single program
                    if (this.programs_id.length == 1)
                        programs_skippable = this.program_data.skippable;

                    //if multiple programs
                    else
                        //if main program is skippable on 0, end here
                        if (this.program_data.skippable == 0)
                            programs_skippable = 0;
                        //go trough additional programs
                        else
                            for (let pid in this.program_data.additional_programs) {
                                if (this.program_data.additional_programs[pid].skippable == 0) {
                                    programs_skippable = 0;
                                    break;
                                }
                            }

                    // both, programs and question has to be skippable
                    if (this.question_data.skippable == 1 && programs_skippable == 1) {
                        if (!this.questionnaire_data[this.question_data.id])
                            return true;
                        else {
                            if (this.question_data.type == 1)
                                return (this.questionnaire_data[this.question_data.id].answer == '' ||
                                    this.questionnaire_data[this.question_data.id].answer == undefined);
                            else if (this.question_data.type == 2)
                                return (this.questionnaire_data[this.question_data.id].table_rows == undefined || Object.keys(this.questionnaire_data[this.question_data.id].table_rows).length == 0);
                        }
                    }
                }

                //after 2020/2021 questions are connected, so no need to skippable programs
                else {
                    if (this.question_data.type == 1 && this.question_data.skippable == 1 && this.questionnaire_data[this.question_data.id] &&
                        this.questionnaire_data[this.question_data.id].answer_last_year && !this.questionnaire_data[this.question_data.id].answer) {
                        return true;
                    }
                    else if (this.question_data.type == 2 && this.question_data.skippable == 1 && this.questionnaire_data[this.question_data.id] && this.questionnaire_data[this.question_data.id].table_rows_last_year
                        && Object.keys(this.questionnaire_data[this.question_data.id].table_rows_last_year).length > 0) {
                        return true;
                    }
                }
            }
            return false;
        },

        //get value of answer
        question_answer() {
            if (this.questionnaire_data[this.question_data.id] && this.questionnaire_data[this.question_data.id].answer) {
                return this.questionnaire_data[this.question_data.id].answer;
            }
            else
                return this.getPlaceholderLockedQ();
            //return '';
        },

        //get value of last year answer
        question_answer_last_year() {
            if (this.questionnaire_data[this.question_data.id] && this.questionnaire_data[this.question_data.id].answer_last_year) {
                return this.questionnaire_data[this.question_data.id].answer_last_year;
            }
            return '';
        },

        //get attachment data of answer
        question_answer_attachments() {
            if (this.questionnaire_data[this.question_data.id])
                return this.questionnaire_data[this.question_data.id].attachment_count;
            return '0';
        },
    },

    methods: {
        ...mapActions([
            'showModal',
            'saveQuestionData',
            'saveBreakData',
            'saveDepartmentQuestionData'
        ]),

        show_on_level(img_level) {
            if (img_level == 0)
                return true;
            else if (img_level == 1 && (this.program_data.stopnja == 'prva stopnja' || this.program_data.stopnja == 'druga stopnja' || this.program_data.stopnja == 'izpopolnjevanje'))
                return true
            else if (img_level == 3 && this.program_data.stopnja == 'tretja stopnja')
                return true
            else
                return false;
        },

        onImageError: function (event, type) {
            this.no_image_count++;

            let element = event.target;

            //if(this.program_data.stopnja !== 'tretja stopnja'){
            element.onerror = null;

            // Dynamically load the images using require
            const tableProgramDataImage = require('/img/evalvation_charts/general_charts/table_program_data.png');
            const chartNoDataImage = require('/img/evalvation_charts/general_charts/chart_no_data.png');

            // Set the image source based on the type
            element.src = (type == 1 ? tableProgramDataImage : chartNoDataImage);

            //element.src = (type == 1 ? '/static/img/evalvation_charts/general_charts/table_program_data.png' : '/static/img/evalvation_charts/general_charts/chart_no_data.png');
            if (type == 0)
                element.parentElement.classList.add('small');
            /* }
            else{
                element.parentNode.style.display='none';
            } */
        },

        getImagePath(type, title, programId) {
            try {
                if (this.build_mode) {
                    return type === 1
                        ? require('/img/evalvation_charts/general_charts/table_program_data.png')
                        : require('/img/evalvation_charts/general_charts/chart_program_data.png');
                } else {
                    return require(`/img/evalvation_charts/${this.yearConvertCharts}/${title}/${programId}.png`);
                }
            } catch (error) {
                return ''; 
            }
        },

        //Set height of textarea to fit content
        auto_grow(element) {
            element.target.style.height = "5px";
            if (element.target.value == "")
                element.target.style.height = "85px";
            else
                element.target.style.height = element.target.scrollHeight > 85 ? (element.target.scrollHeight) + "px" : "85px";

            element.target.scrollIntoView({ behavior: "smooth", block: "end" });
        },

        //save answer
        saveAnswer(question_id, content) {
            if (this.q_type == 1 || this.q_type == 4) {
                let additional_programs = Array.from(this.programs_id).slice(1);
                this.saveQuestionData({ program_id: this.programs_id[0], question_id: question_id, value: content, additional_programs: additional_programs });
            }
            else
                this.saveDepartmentQuestionData({ department_id: this.programs_id, question_id: question_id, value: content });
        },

        //save break answer
        saveBreakAnswer(question_id, break_id, event) {
            this.saveBreakData({ break_id: break_id, question_id: question_id, value: event.target.value });
        },

        check_break(question_id) {
            if (!this.questionnaire_data[question_id].breaks)
                this.questionnaire_data[question_id].breaks = {};
            return true;
        },

        unlockQuestion(event) {
            //this.saveAnswer(this.question_data.id, {target:{value:this.questionnaire_data[this.question_data.id].answer_last_year}});
            this.force_unlock = true;

            //copy last year answer to this year
            let element = event.target.parentNode.parentNode.parentNode.getElementsByTagName('textarea')[0];
            this.questionnaire_data[this.question_data.id].answer = element.value;
        },

        lockQuestion(event) {
            this.showModal({
                component: 'evalvation/LockQuestionConfirm', data: {
                    todo: () => {
                        this.force_unlock = false;

                        if (this.questionnaire_data[this.question_data.id])
                            this.questionnaire_data[this.question_data.id].answer = '';

                        let additional_programs = Array.from(this.programs_id).slice(1);

                        //we need timeout because textarea sends answer on change, so when clicked on icon, onchange is triggered and this at once
                        setTimeout(() => {
                            this.saveQuestionData({
                                program_id: this.programs_id[0], question_id: this.question_data.id,
                                value: '', additional_programs: additional_programs
                            });

                            let element = event.target.parentNode.parentNode.parentNode.getElementsByTagName('textarea')[0];
                            element.style.height = element.scrollHeight > 85 ? (element.scrollHeight) + "px" : "85px";
                        }, 100);
                    }
                }
            });
        },

        // get placeholder based on data of last year
        getPlaceholderLockedQ() {
            if (this.locked_input && !this.force_unlock) {
                if (!this.question_data.id_last_year)
                    return 'Polje ste izpolnili že prejšnje leto. \nTo polje se izpolni vsaki dve leti pod pogojem, da se sestavine študijskega programa niso spremenile. V primeru sprememb sestavin programa ali bistvenih odstopanj v trendih podatkov polje odklenite in ga izpolnite.';
                else
                    return this.question_answer_last_year;
            }
            return "";
        }

        /* lockQuestion(event){
            this.force_unlock = false;
        }, */

        /* checkEmptyString(event){
            let val = event.target.value;
            if(val === ''){
                if(this.questionnaire_data[this.question_data.id])
                    this.questionnaire_data[this.question_data.id].answer = '';
                this.isEmptyString = true;
            }
            else
                this.isEmptyString = false;
        }, */
    },

    watch: {
        question_data() {
            this.selected_image = this.get_first_image_title;
        }
    },

    created() {
        // set selected image to first
        this.selected_image = this.get_first_image_title;
    }
}
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

/* Transicije */
.fade-enter-active {
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
