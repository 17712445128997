<template>
    <div class="content section_detail_holder sections_list_holder" v-if="department_data_name && is_department_access">

        <div class="sections_upper_holder">
            <!-- Evalvation title -->
            <div>
                <div class="evalvation_title" id="evalvation_title">
                    Samoevalvacijski obrazec: {{department_data_name}} za leto {{department_data_year}}
                </div>

                <!-- Already submitted warning -->
                <div v-if="department_data.finished == 1" class="questionnaire_warning warning">
                    <p>Izpolnjevanje obrazca za ta oddelek je zaključeno</p>
                </div>

                <!-- Not active year warning -->
                <div v-if="!isActiveYear" class="questionnaire_warning warning">
                    <p>Izbrali ste študijsko leto, ki trenutno ni aktivno. Pri urejanju podatkov bodite še posebej previdni!</p>
                </div>

                <div class="evalvation_title_second">
                    {{active_page_name}}
                </div>
            </div>
        
            <!-- Exports icons -->
            <div class="file_exports">
                <!--<div class="as_link icon right_margin" title="Izvoz PDF" @click="/*deleteAttachmentConfirm(attachment.id)*/">
                    <font-awesome-icon :icon="['far', 'file-pdf']" />
                </div>-->
                <div class="as_link icon" title="Izvoz Word" @click="exportQuestionnaire({questionnaire_id: questionnaire_details.id, format: 'doc',
                    department_id: department_id})">
                    <font-awesome-icon :icon="['far', 'file-word']" />
                </div>
            </div>
        </div>
        <br>

        <div class="sections_intro" v-if="get_questionnaire_intro && get_questionnaire_intro !== ''">
            <div class="sections_intro_text" v-html="get_questionnaire_intro"></div>
        </div>


        <!-- Izpis roka za oddajo (datuma) znotraj področja, v kolikor ga je administrator nastavil
        <p class="deadline" :class="getDeadlineClass(section.deadline)" v-if="section.deadline !== '0000-00-00'"> Rok za oddajo: {{getDeadlineDate(section.deadline)}}</p> -->

        <div class="section_detail_content" v-if="is_page">

            <!-- Left menu - pages -->
            <ul-questionnaire-pages :build_mode="false" :q_type="getQuestionnaireType"></ul-questionnaire-pages>

            <div class="right">
                <!-- Questionnaire details -->
                <ul-questionnaire-detail :build_mode="false" :q_type="getQuestionnaireType"></ul-questionnaire-detail>

                <!-- Questionnaire questions -->
                <ul-questionnaire-questions :build_mode="false" :q_type="getQuestionnaireType"></ul-questionnaire-questions>
            </div>
        </div>
    </div>

    <div class="content section_detail_holder" v-else>
        <br>
        <div class="section_detail_content">
            <div class="already_submitted_warning warning">
                <text-no-data></text-no-data>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions } from 'vuex';
    import QuestionnaireBuildDetail from './EvalvationQuestionnaireBuildDetail.vue';
    import QuestionnairePageQuestions from './EvalvationQuestionnairePageQuestions.vue';
    import ProgramPages from './EvalvationProgramPages.vue';

    //Text warning template for program not exist
    var programNoData = {
        template: '<p>Ni podatkov za izbran oddelek. Preverite, ali je izbrano študijsko leto združljivo z oddelkom, do katerega želite dostopati.</p>'
    };
    
	export default {
		name: 'EvalvationDepartmentDetail',
		components: {
            'ul-questionnaire-detail': QuestionnaireBuildDetail,
            'ul-questionnaire-questions': QuestionnairePageQuestions,
            'ul-questionnaire-pages': ProgramPages,
            'text-no-data': programNoData,
        },
        data: function() {
			return {
                department_id: this.$route.params.department
			}
        },
        computed: {
            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },

            // Check if we are in active year
			isActiveYear(){
				return this.$store.getters.is_active_year;
            },

            // Get loged user faculty id
            getFacultyId() {
                return this.$store.getters.getFaculty.id;
            },

            getQuestionnaireType(){
                return '2_'+this.getFacultyId;
            },

            // Selected department year
			department_data_year(){
                if(this.$store.getters.evalvation_department_detail)
                    return this.$store.getters.evalvation_department_detail.year;
                else return false;
            },

            // Selected department name
			department_data_name(){
                if(this.$store.getters.evalvation_department_detail)
                    return this.$store.getters.evalvation_department_detail.name;
                else return false;
            },

            // Selected department
			department_data(){
                if(this.$store.getters.evalvation_department_detail)
                    return this.$store.getters.evalvation_department_detail;
                else return {};
            },

            //check if user has access to this department
            is_department_access(){
                if(this.getUserType > 1)
                    return true;
                else{
                    if(this.$store.getters.evalvation_departments[this.department_id])
                        return true;
                    return false;
                }
            },

            // Get details of questionnaire
			questionnaire_details(){
                if(this.$store.getters.evalvation_questionnaire_detail.id){
                    return this.$store.getters.evalvation_questionnaire_detail;
                }
                return null;
            },

            // Get name of active page
			active_page_name(){
                if(!this.is_page){
                    return "Obrazec za to leto še ne obstaja";
                }
                else if(this.$store.getters.active_q_page_id == "RU")
                    return "Pregled realizacije ukrepov in predlogi izboljšav";
                else if(this.$store.getters.active_q_page_id && this.$store.getters.evalvation_questionnaire_detail.pages 
                        && this.$store.getters.evalvation_questionnaire_detail.pages[this.$store.getters.active_q_page_id])
                    return this.$store.getters.evalvation_questionnaire_detail.pages[this.$store.getters.active_q_page_id].name;
            },

            // Is there any page
			is_page(){
                if(!this.$store.getters.active_q_page_id)
                    return false;
                return true;
            },

            // Get questionnaire intro
			get_questionnaire_intro(){
                return this.$store.getters.evalvation_questionnaire_detail.intro;
            },

            //check if there is department data for this faculty
            is_department_data() {
                return Object.keys(this.$store.getters.evalvation_departments).length > 0;
            },
		}, 
        methods: { 		
            ...mapActions([
                'getDepartmentDetail',
                'getDepartmentsListForFaculty',
                'getQuestionnaireDetails',
                'getDepartmentsListForUser',
                'exportQuestionnaire'
            ]),
        },
        created(){
            this.getQuestionnaireDetails({type: this.getQuestionnaireType});
            this.getDepartmentDetail(this.department_id);

            if(this.getUserType < 2 && !this.is_department_data){
                if(this.getUserType > 0)
                    this.getDepartmentsListForFaculty();
                else
                    this.getDepartmentsListForUser();
            }
		},
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
