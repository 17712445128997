<template>
    <div v-if="user_data.type == 2" class="main_holder">
        <div class="content_holder">
            <div class="content">
                <h2>Obveščanje za študijsko leto {{ getActiveYear }}</h2>

                <div class="my_account_form">

                    <div class="flex">
                        <div class="mr50">
                            <p class="mb5">Modul</p>
                            <select class="in-form" v-model="modul" @change="all_filled = check_all_filled()">
                                <option :value="0">Poslovno poročanje</option>
                                <option :value="1">Letni program dela</option>
                                <option :value="2">Samoevalvacija ŠP</option>
                                <option :value="3">vsi</option>
                            </select>
                        </div>

                        <div class="mr50">
                            <p class="mb5">Skupina prejemnikov</p>
                            <select class="in-form" v-model="user_type" @change="all_filled = check_all_filled()">
                                <option :value="2">vsi</option>
                                <option :value="1">upravitelji</option>
                                <option :value="0">poročevalci/skrbniki</option>
                            </select>
                        </div>

                        <div v-show="modul === 0 || modul === 1">
                            <p class="mb5">Oddana področja</p>
                            <select class="in-form" v-model="section_submitted" @change="all_filled = check_all_filled()">
                                <option :value="1">da</option>
                                <option :value="0">ne</option>
                                <option :value="2">vsi</option>
                            </select>
                        </div>

                        <div v-show="modul === 2 && user_type === 1">
                            <p class="mb5">Oddana samoevalvacija</p>
                            <select class="in-form" v-model="programs_submitted" @change="all_filled = check_all_filled()">
                                <option :value="1">da</option>
                                <option :value="0">ne</option>
                                <option :value="2">vsi</option>
                            </select>
                        </div>

                        <div v-show="modul === 2 && user_type === 0">
                            <p class="mb5">Zaključeno izpolnjenvanje</p>
                            <select class="in-form" v-model="programs_finished" @change="all_filled = check_all_filled()">
                                <option :value="1">da</option>
                                <option :value="0">ne</option>
                                <option :value="2">vsi</option>
                            </select>
                        </div>
                    </div>


                    <p class="mb5">Zadeva</p>
                    <input type="text" name="subject" maxlength="100" class="default_width_long" v-model="subject"
                        @keyup="all_filled = check_all_filled()" />
                    <br />

                    <p class="mb5">Vsebina sporočila</p>
                    <vue-editor class="text-editor" :editorToolbar="customToolbar" v-model="message"
                        @keyup.native="all_filled = check_all_filled()"></vue-editor>

                    <!-- <div>
                        <form style="margin-top:10px; font-size:15px;">
                            <label>
                                <input
                                    type="checkbox"
                                    v-model="notifications"
                                    true-value="1"
                                    false-value="0"
                                />{{ notificationsString }}
                            </label>
                        </form>
                    </div> -->

                    <p>{{ getInfoString }}</p>

                    <br>

                    <div class="modal_component_footer">
                        <button class="button orange" @click="sendTestEmail({subject:subject, message:message})">Testiraj obvestilo na {{ user_data.email }}</button>
                    </div>

                    <br>

                    <div class="modal_component_footer">
                        <button class="button large" @click="sendGroupEmailWithConfirm()" :disabled="!all_filled"
                            :class="{ disabled: !all_filled }">Pošlji obvestilo</button>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
    name: "Informing",
    components: {
        VueEditor
    },
    data: function () {
        return {
            customToolbar: [["bold", "italic", "underline", "link"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [false, 3, 4, 5, 6] }]],
            all_filled: false,
            subject: "",
            message: "",
            modul: null,
            user_type: null,
            section_submitted: null,
            programs_submitted: null,
            programs_finished: null,
        };
    },
    computed: {
        getInfoString() {
            if (this.validate_options()) {
                let info = "";
                const infointro = "Sporočilo bo poslano uporabnikom ";
                switch (this.modul) {
                    case 3: info = infointro + "vseh modulov"; break;
                    case 0: info = infointro + "modula Poslovno poročanje"; break;
                    case 1: info = infointro + "modula Letni program dela"; break;
                    case 2: info = infointro + "modula Samoevalvacija ŠP"; break;
                }
                switch (this.user_type) {
                    case 2: info += ", vsi tipi uporabnikov"; break;
                    case 1: info += ", tipa upravitelj"; break;
                    case 0: info += ", tipa poročevalec/skrbnik"; break;
                }
                if (this.modul === 0 || this.modul === 1) {
                    switch (this.section_submitted) {
                        case 1: info += ", ki so že oddali področja"; break;
                        case 0: info += ", ki še niso oddali področij"; break;
                    }
                }
                if (this.modul === 2 && this.user_type === 1) {
                    switch (this.programs_submitted) {
                        case 1: info += ", ki so že oddali samoevalvacisjke obrazce"; break;
                        case 0: info += ", ki še niso oddali samoevalvacijskih obrazcev"; break;
                    }
                }
                if (this.modul === 2 && this.user_type === 0) {
                    switch (this.programs_finished) {
                        case 1: info += ", ki so zaključili z izpolnjevanjem vsaj enega samoevalvacijskega obrazca"; break;
                        case 0: info += ", ki še imajo najmanj en nezaključen samoevalvacijski obrazec"; break;
                    }
                }
                return info;
            }
            return "";
        },

        user_data() {
            return this.$store.getters.getUser;
        },

        // Get loged user faculty id
        getFacultyId() {
            return this.$store.getters.getFaculty.id;
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        // Get loged user type
        getActiveYear() {
            return this.$store.getters.active_year;
        }
    },
    methods: {
        ...mapActions([
            "showModal",
            "sendTestEmail",
            "sendGroupEmail",
        ]),
        //check if all fields are filled
        check_all_filled() {
            if (
                this.subject !== "" &&
                this.message !== "" &&
                this.validate_options()
            )
                return true;
            return false;
        },

        validate_options() {
            if (
                this.modul !== null &&
                this.user_type !== null &&
                /* all modules */
                (this.modul === 3 ||
                    /* or section module with submitted */
                    ((this.modul === 0 || this.modul === 1) && this.section_submitted !== null) ||
                    /* or evalvation with all users or evalvation with specific user and submitted or finished option */
                    (this.modul === 2 && (this.user_type === 2 || this.programs_submitted !== null || this.programs_finished !== null))
                )
            )
                return true;
            return false;
        },

        sendGroupEmailWithConfirm() {
            this.showModal({
                component: 'ModalConfirm', data: {
                    title: 'Potrditev pošiljanja obvestila',
                    description: this.getInfoString,
                    confirm_button: 'Pošlji', 
                    todo: () => {
                        this.sendGroupEmail({
                            subject: this.subject,
                            message: this.message,
                            modul: this.modul,
                            user_type: this.user_type,
                            section_submitted: this.section_submitted,
                            programs_submitted: this.programs_submitted,
                            programs_finished: this.programs_finished
                        });
                    }
                }
            });
        },
    },
    created() {
    },
};
</script>


<style lang="scss" scoped>
</style>
