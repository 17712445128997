<template>
    <div class="section_detail_subsection_holder" v-if="active_page_id && (!build_mode && page_data[active_page_id].description) || 
        (build_mode && (getUserType == 2 || ((is_internal_questionnaire || (q_type != 1 && q_type != 4)) && (getUserType == 1 || getUserType == 2)) || (isPermissionToInternal && page_data[active_page_id].description)))">

        <div class="subsection_description evalvation_page_description" v-if="page_data[active_page_id]">
            <span v-if="page_data[active_page_id].description" v-html="page_data[active_page_id].description"></span>

            <div class="button_holder" v-if="build_mode && (getUserType == 2 || (getUserType == 1 && (is_internal_questionnaire || (q_type != 1 && q_type != 4))))">
                <div class="subsection_delete button gray" @click="showModal({component: 'evalvation/DeleteQuestionnairePage'})">
                    Izbriši
                </div>
                <div class="subsection_edit button gray" @click="showModal({component: 'evalvation/EditQuestionnairePage', data: 
                    {name: page_data[active_page_id].name, description: page_data[active_page_id].description}})">
                    Uredi
                </div>
            </div>
        </div>

    </div>
</template>


<script>
	import { mapActions } from 'vuex';
	
	export default {
		name: 'EvalvationQuestionnaireBuildDetail',
		components: {

        },
        props: {
            build_mode: {
                type: Boolean,
                required: true
            },
            q_type: {
                type: [String, Number],
                required: true
            }
        },
        data: function() {
			return {
			}
        },
        computed: {
			// Selected questionnaire object for program
			page_data(){
                if(this.active_page_id && this.$store.getters.evalvation_questionnaire_detail.pages && this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id])
                    return this.$store.getters.evalvation_questionnaire_detail.pages;
                else if(this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id])
                    return this.$store.getters.evalvation_internal_questionnaire_detail.pages;
                else
                    return {};
            },
            active_page_id(){
                return this.$store.getters.active_q_page_id;
            },
            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },
            is_internal_questionnaire(){
                return (this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id]);
            },
            isPermissionToInternal(){
                return this.getUserType == 1 && this.getFacultyInternalPermission.includes(this.$store.getters.getFaculty.id);
            },
            //Get array of faculties that have permission to internal questionnaire
            getFacultyInternalPermission(){
                return this.$store.getters.faculties_internal_permission;
            }
        }, 
        methods: { 		
            ...mapActions([
                'changeActivePageQ',
                'showModal',
            ]),

            changeActivePage(id){
                this.changeActivePageQ(id);
            }
        },
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
