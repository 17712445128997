<template>
	<div class="main">
		<transition 
				appear
				mode="out-in"
				enter-active-class="animated fadeIn"
				leave-active-class="animated fadeOut"
			>		
			<router-view></router-view>
		</transition>
	</div>
</template>


<script>
import Sections from './sections/Sections.vue';
import Evalvation from './evalvation/Evalvation.vue';
import { mapActions } from 'vuex';

export default {
	name: 'Main',
	components: {
		'ul-section': Sections,
		'ul-evalvation': Evalvation,
	},
}
</script>


<style lang="scss" scoped>	
	@import "../scss/style.scss";
</style>
