var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_detail_subsection_holder"},[(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 54)?_c('div',[_vm._m(0),_vm._m(1)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 57)?_c('div',[_vm._m(2),_vm._m(3)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 58)?_c('div',[_vm._m(4),_vm._m(5)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 59)?_c('div',[_vm._m(6),_vm._m(7)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 60)?_c('div',[_vm._m(8),_vm._m(9)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 62)?_c('div',[_vm._m(10),_vm._m(11)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 63)?_c('div',[_vm._m(12),_vm._m(13)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 64)?_c('div',[_vm._m(14),_vm._m(15)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 65)?_c('div',[_vm._m(16),_vm._m(17)]):(_vm.subsections_data[_vm.active_subsection_id] && _vm.active_subsection_id == 66)?_c('div',[_vm._m(18),_vm._m(19)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Spodbujanje interdisciplinarnosti, multidisciplinarnosti kot prednosti UL, kot univerze, ki pokriva vsa področja.")]),_c('li',[_vm._v("Povečati vključenost uporabnikov znanja v oblikovanje/posodabljanje študijskih programov.")]),_c('li',[_vm._v("Dvig kompetenc študentov za prehod na trg dela.")]),_c('li',[_vm._v("Krepitev in promocija doktorskega študija.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Posodobitev študijskih programov, novi poklici, novi študijski pristopi, nove družbene prioritete: digitalno, zeleno, družbene spremembe")]),_c('td',[_vm._v("Priprava analiz študijskih programov z analizo zaposljivosti in predlogi razvoja")])]),_c('tr',[_c('td',[_vm._v("Vzpostavitev sistema usklajenih urnikov za pospeševanje izbirnosti predmetov med članicami")]),_c('td',[_vm._v("Nabava skupne aplikacije za elektronske urnike, ki bo omogočila boljši pregled nad urniki po članicah")])]),_c('tr',[_c('td',[_vm._v("Vzpostavitev svetovalnih odborov v katere so vključeni predstavniki delodajalcev (gospodarstvo in negospodarstvo)")]),_c('td',[_vm._v("Uvedba na nekaj pilotnih članicah")])]),_c('tr',[_c('td',[_vm._v("Uvedba raznih oblik kreditno ovrednotenih delovnih praks študentov v kurikulum, kjer to ni obvezno že s študijskim programom")]),_c('td',[_vm._v("Uvedba na nekaj pilotnih članicah")])]),_c('tr',[_c('td',[_vm._v("Začetek izvajanja interdisciplinarnega doktorskega študijskega programa Umetnost")]),_c('td',[_vm._v("Priprava prvega razpisa za vpis")])]),_c('tr',[_c('td',[_vm._v("Sprememba izvedbe promocije doktorandk in doktorandov")]),_c('td',[_vm._v("Prva javna predstavitev v sodelovanju z MOL")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Zagotavljanje stabilnega financiranja raziskovalne dejavnosti")]),_c('li',[_vm._v("Povečati učinkovitost in uspešnost pri prijavah na velike EU projekte")]),_c('li',[_vm._v("Zagotavljanje etičnosti pri raziskovalnem delu")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Vzpostavitev procesa notranje delitve sredstev na podlagi novega zakona o raziskovalni dejavnosti")]),_c('td',[_vm._v("Priprava analize stanja in meril za razporejanje sredstev ")])]),_c('tr',[_c('td',[_vm._v("Zagotoviti prenos dobrih praks med članicami")]),_c('td',[_vm._v("Priprava strategije sodelovanja med članicami")])]),_c('tr',[_c('td',[_vm._v("Finančna spodbuda prijaviteljem prek Razvojnega sklada ")]),_c('td',[_vm._v("Priprava in objava razpisov Razvojnega sklada")])]),_c('tr',[_c('td',[_vm._v("Usposabljanja za raziskovalce")]),_c('td',[_vm._v("Usposabljanja za prijavo projektov Marie Skłodowska-Curie, ERC, Obzorje Evropa")])]),_c('tr',[_c('td',[_vm._v("Preprečevanje neetičnega ravnanja")]),_c('td',[_vm._v("Izvedba evalvacije sistema in priprava ukrepov za zaznana neetična ravnanja")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Promocija umetnosti in kulture znotraj UL, v širšem domačem in mednarodnem okolju okolju")]),_c('li',[_vm._v("Zagotoviti dodatne vire financiranja za področje umetnosti")]),_c('li',[_vm._v("Spremljanje kakovosti na umetniškem področju")]),_c('li',[_vm._v("Skrb za kulturno dediščino UL")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Aktivacija akademske TV kot univerzitetnega kanala")]),_c('td')]),_c('tr',[_c('td',[_vm._v("Organizacija umetniških dogodkov akademij za vse zaposlene")]),_c('td',[_vm._v("Izvedba dogodkov v Mali galeriji in drugih dogodkov")])]),_c('tr',[_c('td',[_vm._v("Prijave na razpise raznih ministrstev")]),_c('td',[_vm._v("Ureditev statusa akademij za prijave na razpise Ministrstva za kulturo")])]),_c('tr',[_c('td',[_vm._v("Oblikovanje kazalnikov uspešnosti na področju umetnosti")]),_c('td',[_vm._v("Olikovanje kazalnikov uspešnosti na področju umetnosti")])]),_c('tr',[_c('td',[_vm._v("Vzpostavitev evidence umetniških del na ravni UL")]),_c('td',[_vm._v("Vzpostavitev evidence umetniških del na ravni UL")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Skrb za trajnostni razvoj na vseh področjih delovanja UL")]),_c('li',[_vm._v("Tesnejše sodelovanje z drugimi inštitucijami")]),_c('li',[_vm._v("Krepitev alumnov")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Vzpostaviti \"Sustainable Development Goals\"")]),_c('td',[_vm._v("Oblikovati delovno skupino za trajnostni razvoj")])]),_c('tr',[_c('td',[_vm._v("Vzpostavitev medsebojnih dogovorov o pridruženem članstvu in medsebojnem sodelovanju")]),_c('td',[_vm._v("Začetek pogovorov z UKC, ŠDL, inštituti")])]),_c('tr',[_c('td',[_vm._v("Vzpostavitev sistema donacij")]),_c('td',[_vm._v("Priprava načrta ustanovitve fundacije alumnov")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Odprta znanost")]),_c('li',[_vm._v("Zagotavljanje zdravega, varnega in stimulativnega študijskega okolja")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Vzpostavitev formalnih pogojev za upravljanje z raziskovalnimi podatki")]),_c('td',[_vm._v("Priprava strategije in drugih pravil o odprti znanosti")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Izvedba izobraževanj študentk, študentov in zaposlenih o upravljanju z odprtimi podatki")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Vzpostavitev pisarne varuha študentskih pravic")])]),_c('tr',[_c('td',[_vm._v("Evalvacija vključenosti športnih aktivnosti študentov v študijske programe")]),_c('td')]),_c('tr',[_c('td'),_c('td',[_vm._v("Ustanovitev nagrade Uroša Seljaka")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Priprava pravilnika za nagrado dr. Ane Mayer Kansky za odmevna doktorska dela")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Strateško upravljanje")]),_c('li',[_vm._v("Krepitev notranje koordinacije upravljanja ")]),_c('li',[_vm._v("Zagotoviti transparentno in stabilno financiranje ")]),_c('li',[_vm._v("Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja")]),_c('li',[_vm._v("Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih")]),_c('li',[_vm._v("Nadaljevanje projekta APIS")]),_c('li',[_vm._v("Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu")]),_c('li',[_vm._v("Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje")]),_c('li',[_vm._v("Krepiti prepoznavnost UL")]),_c('li',[_vm._v("Krepiti notranji sistem komuniciranja")]),_c('li',[_vm._v("Nadaljevanje prostorskega razvoja")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Sprejeti strategijo razvoja UL")]),_c('td',[_vm._v("Sprejeti strategijo in podstrategije")])]),_c('tr',[_c('td',[_vm._v("Redni sestanki z dekani, tajniki in drugimi strokovnimi službami")]),_c('td',[_vm._v("Vzpostaviti mehanizme vodenja sestankov in spremljanja sprejetih sklepov")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Priprava pravilnika o zaslužnih profesorjih")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Podaljšanje mandata študentskega sveta na 2 leti")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Vzpostavitev sodelovanja predsednikov komisij senata in UO")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Priprava analize stroškov izvedbe študijskih programov in razprava o dolgoročni usmeritvi načina financiranja")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Zagotoviti rezervna sredstva za razvojne projekte in posebna tveganja")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Strateško upravljanje")]),_c('li',[_vm._v("Krepitev notranje koordinacije upravljanja ")]),_c('li',[_vm._v("Zagotoviti transparentno in stabilno financiranje ")]),_c('li',[_vm._v("Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja")]),_c('li',[_vm._v("Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih")]),_c('li',[_vm._v("Nadaljevanje projekta APIS")]),_c('li',[_vm._v("Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu")]),_c('li',[_vm._v("Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje")]),_c('li',[_vm._v("Krepiti prepoznavnost UL")]),_c('li',[_vm._v("Krepiti notranji sistem komuniciranja")]),_c('li',[_vm._v("Nadaljevanje prostorskega razvoja")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Odpraviti razlike v plačnem sistemu")]),_c('td',[_vm._v("Priprava analize plač v javnem sektorju in gospodarstvu kot izhodišče pogajanj z Vlado")])]),_c('tr',[_c('td',[_vm._v("Priprava pravnih podlag za delo in kadrovski razvoj")]),_c('td',[_vm._v("Dokončanje pravilnika o varovanju dostojanstva")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Dokončanje strategije o enakosti spolov")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Dokončanje akcijskega načrta kariernega razvoja raziskovalcev - HR strategija")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Priprava analize položaja raziskovalcev na delovnih mestih skupine H in izhodišča za urejanje njihovega statusa")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Pričetek prenove habilitacijskih meril")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Izbirni postopki zaposlovanja novih kadrov")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Priprava kompetenčnega modela za strokovne delavce")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Strateško upravljanje")]),_c('li',[_vm._v("Krepitev notranje koordinacije upravljanja ")]),_c('li',[_vm._v("Zagotoviti transparentno in stabilno financiranje ")]),_c('li',[_vm._v("Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja")]),_c('li',[_vm._v("Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih")]),_c('li',[_vm._v("Nadaljevanje projekta APIS")]),_c('li',[_vm._v("Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu")]),_c('li',[_vm._v("Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje")]),_c('li',[_vm._v("Krepiti prepoznavnost UL")]),_c('li',[_vm._v("Krepiti notranji sistem komuniciranja")]),_c('li',[_vm._v("Nadaljevanje prostorskega razvoja")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Implementacija po sprejetem načrtu")]),_c('td',[_vm._v("Implementacija po sprejetem načrtu")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Strateško upravljanje")]),_c('li',[_vm._v("Krepitev notranje koordinacije upravljanja ")]),_c('li',[_vm._v("Zagotoviti transparentno in stabilno financiranje ")]),_c('li',[_vm._v("Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja")]),_c('li',[_vm._v("Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih")]),_c('li',[_vm._v("Nadaljevanje projekta APIS")]),_c('li',[_vm._v("Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu")]),_c('li',[_vm._v("Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje")]),_c('li',[_vm._v("Krepiti prepoznavnost UL")]),_c('li',[_vm._v("Krepiti notranji sistem komuniciranja")]),_c('li',[_vm._v("Nadaljevanje prostorskega razvoja")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Prenova CGP - nadgradnja za sodobne načine komuniciranja")]),_c('td')]),_c('tr',[_c('td',[_vm._v("Prenova spletnih strani")]),_c('td')]),_c('tr',[_c('td'),_c('td',[_vm._v("Redna neformalna srečanja z novinarji")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Redne okrogle mize (Spre)govori znanost, znanost (spre)govori – pogovori o perečih temah ")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Organizacija poljudnoznanstvenih predavanj")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Partnerstvo z Muzejem in galerijami mesta Ljubljana (MGML) pri projektu M_oder --> študentje v muzeju enkrat mesečno predstavljajo diplomska,magistrska ali doktorska del")])]),_c('tr',[_c('td'),_c('td',[_vm._v("Pristop h konzorciju, ki izdaja Alternator in povabilo zaposlenim k pisanju za Alternator")])]),_c('tr',[_c('td',[_vm._v("Priprava strategije komuniciranja")]),_c('td')]),_c('tr',[_c('td'),_c('td',[_vm._v("Sprememba načina internega komuniciranja")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection_description"},[_c('p',[_vm._v(" CILJI: "),_c('ul',[_c('li',[_vm._v("Strateško upravljanje")]),_c('li',[_vm._v("Krepitev notranje koordinacije upravljanja ")]),_c('li',[_vm._v("Zagotoviti transparentno in stabilno financiranje ")]),_c('li',[_vm._v("Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja")]),_c('li',[_vm._v("Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih")]),_c('li',[_vm._v("Nadaljevanje projekta APIS")]),_c('li',[_vm._v("Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu")]),_c('li',[_vm._v("Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje")]),_c('li',[_vm._v("Krepiti prepoznavnost UL")]),_c('li',[_vm._v("Krepiti notranji sistem komuniciranja")]),_c('li',[_vm._v("Nadaljevanje prostorskega razvoja")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_static_data"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("AKTIVNOSTI V LETIH 2022 IN 2023")]),_c('th',[_vm._v("IZVEDBA V LETU 2022")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Investicije: VF, FS, FFA, MF, ALUO, AG")]),_c('td')])])])])
}]

export { render, staticRenderFns }