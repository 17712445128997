<template>
    <div class="section_detail_subsection_holder">

        <!-- tempbadworksections1 -->
        <!-- <div class="image_content_work_plan" v-if="subsections_data[active_subsection_id] && 
            active_subsection_id > 66 && active_subsection_id < 72">
            <br>
            <br>
            
            <p>Tukaj se nahajajo podatki, ki ste jih vnesli v letni program dela za 2021</p>
            <a class="as_link icon export_item" title="Letni program dela 2021/2022"
                :href="'/static/img/work_plan_indicators_data/'+getFacultyAcronym+'_2021.xlsx'">
                <font-awesome-icon :icon="['far', 'file-excel']" /> Letni program dela 2021
            </a>
        </div> -->


        <!-- tempbadworksections2 -->
        <!-- <div class="subsection_description"> -->

            <a class="bold" @click="show_fgg_table = !show_fgg_table">Za pregled načrtovanih aktivnosti v prejšnjem letu razširi pogled</a>
            <div class="subsection_table_holder" v-show="show_fgg_table">
                <!-- Table -->
                <div class="subsection_table_table">

                    <!-- Table header row -->
                    <div class="row header">
                        <!-- Table columns -->
                        <div class="column column_header small">
                            <span class="text">Cilji članice/univerze</span>
                        </div>

                        <div class="column column_header">
                            <span class="text">Načrtovani ukrepi</span>
                        </div>
                    </div>

                    <!-- Table data rows -->
                    <div class="row data">    
                        <!-- Table columns -->
                        <div class="column only_text small">
                            <div class="only_text">Izboljšanje študijskih programov.</div>
                        </div>
                        <div class="column only_text">
                            <div class="only_text">(1) Izvedba samoevalvacije vseh ŠP UL FGG. (2) Spremljanje zanke kakovosti ŠP preko KzKR in pedagoških dnevov. (3) Analiza načina preverjanja in doseganja kompetenc ŠP. (4) Prenova študijskih programov v okviru priprave enovitega magistrskega ŠP Gradbeništvo, ki bo združil en prvostopenjski ŠP in dva drugostopenjska ŠP. (5) Prenova interdisciplinarnega doktorskega študijskega programa Grajeno okolje. (6) Uvedba elektronske evalvacije praktičnega usposabljanja. (7) Prenova strokovnih predmetov (sprememba neobveznih sestavin) za vključevanje študentov na strokovne konference/simpozije.</div>
                        </div>
                    </div>
                    <div class="row data">    
                        <!-- Table columns -->
                        <div class="column only_text small">
                            <div class="only_text">Izboljšanje postopkov poučevanja.</div>
                        </div>
                        <div class="column only_text">
                            <div class="only_text">(1) Prilagoditev na kombiniran način poučevanja in/ali poučenja na daljavo. Oprema predavalnic z videokonferenčno ter ostalo opremo za izvajanje kombiniranega in/ali pouka na daljavo. (2) Nabava sodobne merilne in laboratorijske opreme za izvedbo vaj (tudi na daljavo) in raziskav v okviru zaključnih del.</div>
                        </div>
                    </div>
                    <div class="row data">    
                        <!-- Table columns -->
                        <div class="column only_text small">
                            <div class="only_text">Krepitev prepoznavnosti UL in UL FGG ter njunega dela ter vpliva na skupnost.</div>
                        </div>
                        <div class="column only_text">
                            <div class="only_text">(1) Organizacija dogodkov: dan odprtih vrat; tehniški dnevi za osnovne šole; tematska predavanja za srednje šole; tekmovanje v gradbeni mehaniki. (2) Izvedba študentskih delavnic, taborov in poletnih šol v sodelovanju z lokalnimi skupnostmi, regionalnimi razvojnimi agencijami in drugimi institucijami na različnih področjih. (3) Krepitev mreže alumnov UL FGG v okviru mreže alumnov UL.</div>
                        </div>
                    </div>
                    <div class="row data">    
                        <!-- Table columns -->
                        <div class="column only_text small">
                            <div class="only_text">Organizacija mednarodnih kongresov in poletnih šol.</div>
                        </div>
                        <div class="column only_text">
                            <div class="only_text">Organizacija (1) mednarodne konference ASFE 21 o požarni varnosti  stavb in (2) mednarodne poletne šole ESA o uporabi daljinskega zaznavanja in umetne inteligence v gozdarstvu.</div>
                        </div>
                    </div>
                    <div class="row data">    
                        <!-- Table columns -->
                        <div class="column only_text small">
                            <div class="only_text">Internacionalizacija študijske dejavnosti.</div>
                        </div>
                        <div class="column only_text">
                            <div class="only_text">(1) Povečanje ponudbe predmetov v angleškem jeziku za povečanje zanimanja študentskih izmenjav za tuje študente. (2) Organizacija in izvedba mednarodnih študijskih programov BIM A+ in Flood Risk Management. (3) Gostovanje 4-5 tujih strokovnjakov z izvedbo dela predmetov na UL FGG. (4) Povečanje število tujih študentov na interdisciplinarnem doktorskem študiju Grajeno okolje.</div>
                        </div>
                    </div>
                    <div class="row data">    
                        <!-- Table columns -->
                        <div class="column only_text small">
                            <div class="only_text">Promocija ŠP UL FGG za domače študente.</div>
                        </div>
                        <div class="column only_text">
                            <div class="only_text">(1) Predstavitev ŠP UL FGG na spletnih platformah (primer: Virtualni karierni sejem). (2) Povečati prepoznavnost in zanimanje za prvo- in drugostopenjski ŠP VOI z bolj aktivno promocijo obeh ŠP v medijih in na spletnih platformah.</div>
                        </div>
                    </div>
                    <div class="row data">    
                        <!-- Table columns -->
                        <div class="column small">
                            <div></div>
                        </div>
                        <div class="column">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->

        <div class="subsection_table_holder" style="display:none;">
            <!-- Table -->
            <div class="subsection_table_table">

                <!-- Table header row -->
                <div class="row header">
                    <!-- Table columns -->
                    <div class="column column_header small">
                        <span class="text">KLJUČNE</span>
                    </div>

                    <div class="column column_header">
                        <span class="text">OPIS</span>
                    </div>

                    <div class="column column_header">
                        <span class="text">CILJ(I)</span>
                    </div>

                    <div class="column column_header">
                        <span class="text">PREDLOGI UKREPOV</span>
                    </div>
                </div>

                <!-- Table data rows -->
                <div class="row data">    
                    <!-- Table columns -->
                    <div class="column only_text small">
                        <div class="only_text">NEVARNOST</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Nezainteresiranost študentov VS študijskih programov za Erasmus izmenjavo.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Povečati zanimanje študentov VS študijskih programov za izmenjavo s tujino (semestralna izmenjava, PU).</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Mentorji letnikov in koordinator PU na sestankih svetov letnikov vzpodbudijo študente za Erasmus izmenjavo.</div>
                    </div>
                </div>
                <div class="row data">    
                    <!-- Table columns -->
                    <div class="column only_text small">
                        <div class="only_text">NEVARNOST</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Padec kakovosti izvedbe pouka za Erasmus+ študente zaradi Covid omejitev.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Zagotavljanje ustreznih kompetenc za izmenjalne študente.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Možnost organizacije pouka za Erasmus+ v živo v manjših skupinah.</div>
                    </div>
                </div>
                <div class="row data">    
                    <!-- Table columns -->
                    <div class="column only_text small">
                        <div class="only_text">NEVARNOST</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Premalo izvedenega praktičnega usposabljanja zaradi Covid omejitev.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Organizirati praktično usposabljanje  v zunanjih inštitucijah kljub omejitvam.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Dogovor z inštitucijami za izvedbo PU v raznih oblikah (tudi na daljavo).</div>
                    </div>
                </div>
                <div class="row data">    
                    <!-- Table columns -->
                    <div class="column only_text small">
                        <div class="only_text">NEVARNOST</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Sprememba gradbene zakonodaje.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Okrepitev vloge gradbenika v stroki.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Vključitev v strokovna združenja in civilno družbo pri obravnavi zakonodaje in s predlogi neposredno zakonodajalcu.</div>
                    </div>
                </div>
                <div class="row data">    
                    <!-- Table columns -->
                    <div class="column only_text small">
                        <div class="only_text">SLABOSTI</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Premalo strokovnih vsebin, premalo praktičnega usposabljanja na študijskih programih prve in druge stopnje.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Povečati obseg strokovnih vsebin in obseg praktičnega usposabljanja.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Povečati obseg v okviru sprememb neobveznih sestavin.</div>
                    </div>
                </div>
                <div class="row data">    
                    <!-- Table columns -->
                    <div class="column only_text small">
                        <div class="only_text">SLABOSTI</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Slaba prehodnost iz 1. v 2. letnik na prvostopenjskih študijskih programih.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Povečati prehodnost.</div>
                    </div>
                    <div class="column only_text">
                        <div class="only_text">Nekatere težje predmete prestaviti v 2. letnik. </div>
                    </div>
                </div>
                <div class="row data">    
                    <!-- Table columns -->
                    <div class="column small">
                        <div></div>
                    </div>
                    <div class="column">
                        <div></div>
                    </div>
                    <div class="column">
                        <div></div>
                    </div>
                    <div class="column">
                        <div></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
    import router from '../../router/router'
	import { mapActions } from 'vuex';
	
	export default {
		name: 'SectionsDetailTableExample',
		components: {

        },
        data: function() {
			return {
                show_fgg_table: false
			}
        },
        computed: {
			// Selected section object for level
			subsections_data(){

                let level = this.$store.getters.section_level;

                let subsections = this.$store.getters.subsections_data[level];
   
                // Return subsections for section and level
				return subsections ? subsections : {};
            },
            active_subsection_id(){
                return this.$store.getters.active_subsection_id;
            },

            // Get loged user faculty id
            getFacultyID() {
                return this.$store.getters.getFaculty.id;
            },

            // Get loged user faculty id
            getFacultyAcronym() {
                return this.$store.getters.getFaculty.acronym;
            },

            getTabCode(){
                if(router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela')
                    return 1;
                return 0;
            },
        }, 
        methods: { 		
            ...mapActions([
            ]),
        },
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
