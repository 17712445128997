<template>
    <div v-if="getUserType == 2 || filteredQuestions.length > 0" class="evalvation_questions_holder">
        <div class="questions_list">
            
            <div class="evalvation_question_holder">          
                <div v-for="question in filteredQuestions" 
                    :key="question.id"
                    class="evalvation_question_holder question"
                >
                    <div class="question_title_row">
                        <span class="as_link icon edit" title="Uredi vprašanje"  v-if="getUserType == 2">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'sections/EditSubsectionQuestion', 
                                data:{title:question.title, id:question.id, order_number:question.order_number, description:question.description,
                                    position:position, is_subsection_united_measures:is_subsection_united_measures, mandatory:question.mandatory, united_measures:question.united_measures}})" />
                        </span>

                        <span class="as_link icon remove" title="Pobriši vprašanje" 
                            v-if="getUserType == 2">
                            <font-awesome-icon :icon="['far', 'trash-alt']" @click="showModal({component: 'sections/DeleteSubsectionQuestion', data:{id:question.id, order_number:question.order_number}})" />
                        </span>

                        <span class="question_title">
                            <b>{{ question.title }}</b>
                        </span>
                    </div>

                    <div class="question_description" v-if="question.description && question.description !== ''" v-html="question.description"></div>

                    <div class="questoion_textarea_holder">
                        <AutoResizeTextarea 
                            :disabled="disable_input" :value="question_answer(question.id)" :qid="question.id"
                            @change="saveAnswer" name="textarea_answer">
                        </AutoResizeTextarea>
                    </div>

                    <!-- <div class="question_add_holder" v-if="getUserType > 1">
                        <div class="as_link icon question_add" title="Dodaj vprašanje" @click="showModal({component: 'sections/CreateSubsectionQuestion'/*, 
                            data:{order_number:parseInt(question.order_number)+1}*/})">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vprašanje
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="question_add_holder" v-if="getUserType == 2/*  && (!filteredQuestions || Object.keys(filteredQuestions).length == 0) */">
                <div class="as_link icon question_add" title="Dodaj vprašanje" @click="showModal({component: 'sections/CreateSubsectionQuestion', 
                    data:{order_number:(filteredQuestions.length+1), position:position, is_subsection_united_measures:is_subsection_united_measures}})">
                    <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vprašanje
                </div>
            </div>
        </div>
        
    </div>
</template>


<script>
	import { mapActions } from 'vuex';
    import AutoResizeTextarea from '../general/AutoResizeTextarea.vue';
    
	export default {
		name: 'SectionsDetailQuestions',
		components: {
            AutoResizeTextarea
        },
        data: function() {
			return {
			}
        },
        props: {
            position: {
                type: Number,
                required: true
            },
            is_subsection_united_measures: {
                type: Number,
                required: true
            },
            deadline_not_closed: {
                type: Boolean,
                default: false
            },
            section_submitted: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            // Selected subsection object with questions data
			subsectionQuestions(){
                if(this.$store.getters.subsection_data && this.$store.getters.subsection_data.questions)
                    return this.$store.getters.subsection_data.questions;
                return[];
            },

            filteredQuestions: function() {
                return Object.values(this.subsectionQuestions).filter(data => {
                    if(data.position == this.position)
                        return true;
                    return false;
                });
            },

            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },

            disable_input(){
                return this.getUserType == 3 || ((this.section_submitted || !this.deadline_not_closed) && this.getUserType < 2);
            }
        }, 

        methods: { 		
            ...mapActions([
                'showModal',
                'saveSubsectionQuestionAnswer'
            ]),

            //save answer
            saveAnswer(question_id, content){   
                this.saveSubsectionQuestionAnswer({question_id:question_id, value:content});
            },

            //get value of answer
            question_answer(id){
                if(this.subsectionQuestions[id] && this.subsectionQuestions[id].answer)
                    return this.subsectionQuestions[id].answer;
                return '';
            },
        },

        watch:{
            // Resize all textareas
            subsectionQuestions(){
                this.$nextTick(()=>{
                    [...document.querySelectorAll('textarea')].forEach(ta => {
                        ta.dispatchEvent(new Event("onload"));
                    });
                });
            },
            active_subsection_id(){
                this.$nextTick(()=>{
                    [...document.querySelectorAll('textarea')].forEach(ta => {
                        ta.dispatchEvent(new Event("onload"));
                    });
                });
            }
        },

        mounted() {
            this.$nextTick(()=>{
                [...document.querySelectorAll('textarea')].forEach(ta => {
                    ta.dispatchEvent(new Event("onload"));
                });
            });
        }
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
