<template>
    <FAQ :tab="0"></FAQ>
</template>


<script>
import FAQ from './FAQ.vue';

export default {
    name: "FAQGeneral",
    components: {
        FAQ,
    },
    data: function () {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
};
</script>


<style lang="scss" scoped>
</style>
