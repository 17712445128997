<template>
	<div id="app">
		
		<div class="wrapper">
			
			<ul-header></ul-header>		
			
			<ul-main></ul-main>
			
			<ul-footer></ul-footer>

			<ul-modal></ul-modal>

			<ul-modal-working></ul-modal-working>
		</div>	
	</div>
</template>



<script>
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import TheMain from './components/TheMain.vue';
import Modal from './components/modal/Modal.vue';
import ModalWorking from './components/modal/ModalWorking.vue';

export default {
	name: 'App',
	components: {
		'ul-header': TheHeader,
		'ul-footer': TheFooter,
		'ul-main': TheMain,
		'ul-modal': Modal,
		'ul-modal-working': ModalWorking
	}
}
</script>



<style lang="scss">
	@import "./scss/style.scss";
</style>
