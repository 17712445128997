<template>
    <div class="main_holder login">
        <div class="content_holder">
            <div class="login_box">

                <div class="password_login_box" v-if="!production">
                    <div class="input_holder">
                        Elektronska pošta: <input type="email" maxlength="50" v-model="email" ref="email">
                        Geslo: <input type="password" maxlength="50" v-model="password" @keyup.enter="loginUser()" ref="password">
                    </div>

                    <div class="input_holder button_holder">
                        <button class="button" @click="loginUser({email: email, password: password})">Vpis</button>
                    </div>

                    <div class="as_link pass_recovery_link" @click="passRecoveryClick()">
                        <span>Ste pozabili geslo?</span>
                    </div>
                </div>

                <button class="button sso_login_button" @click="loginWithAzureSSO()" :class="{disabled:loading}">
                    <font-awesome-icon :icon="['fas', 'right-to-bracket']" />
                    {{ loading ? 'Zaključite prijavo v pojavnem oknu ...' : 'Vpis z digitalno identiteto UL' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PublicClientApplication } from "@azure/msal-browser";

export default {
    name: 'Login',
    data() {
        return {
            msalInstance: null,
            loading: false,
            error: null,
            production: window.location.hostname === 'porocanje.uni-lj.si',
            email: '',
            password: '',
        };
    },
    methods: {
        ...mapActions([
            'showModal', 
            'loginUserSaml',
            'loginUser',
            'passRecovery'
        ]),
        async loginWithAzureSSO() {
            this.loading = true; // Set loading indicator

            let clientID = '';
            let redirectUrl = window.location.origin + '/blank.html';

            switch (window.location.hostname) {
                case 'porocanje.uni-lj.si':
                    clientID = '9ed42378-d80d-411b-b6df-9e4f17abeb02';
                    break;
                case 'porocanje-test.uni-lj.si':
                    clientID = '42dd1584-76ac-4b50-af1e-36dcb73070dc';
                    break;
                default:
                    clientID = '42dd1584-76ac-4b50-af1e-36dcb73070dc';
            }

            try {
                if (!this.msalInstance) {
                    this.msalInstance = new PublicClientApplication({
                        auth: {
                            clientId: clientID,
                            authority: 'https://login.microsoftonline.com/a6cc90df-f580-49dc-903f-87af5a75338e/v2.0',
                            redirectUri: redirectUrl, // Vue.js app URL
                        },
                        cache: {
                            cacheLocation: 'localStorage',
                            storeAuthStateInCookie: false,
                        },
                    });

                    await this.msalInstance.initialize();

                    // Initialize MSAL instance
                    await this.msalInstance.handleRedirectPromise();
                }

                // Acquire token from Azure AD
                const loginRequest = {
                    scopes: ["User.Read", "openid", "profile", "email", "User.ReadBasic.All"],
                    redirectUri: redirectUrl,
                };

                // Acquire token from Azure AD
                const response = await this.msalInstance.loginPopup(loginRequest);

                // Handle successful authentication
                if (response && response.idToken) {
                    // Store token securely
                    //localStorage.setItem("idToken", response.idToken);
                    //localStorage.setItem("accessToken", response.accessToken);

                    //console.log(response.idToken);

                    //const email = idTokenClaims.email || idTokenClaims.preferred_username;
                    //console.log("User Email:", email);

                    this.loginUserSaml({token:response.idToken});
                }
            } catch (error) {
                // Handle login error
                console.error('Azure SSO login failed:', error);
                let error_string = "Napaka pri prijavi z Microsoft Azure. Prosimo, poskusite ponovno. \n\n Podrobnosti napake: " + error.message;

                if (error.message.includes("popups are blocked")) {
                    error_string = 'Imate blokiran prikaz pojavnih oken. Prosimo, omogočite jih in poskusite znova.';
                }

                if(!error.message.includes("User cancelled"))
                    this.showModal({ component: 'ModalError', data: error_string });
            } finally {
                this.loading = false; // Reset loading indicator
            }
        },

        passRecoveryClick(){
            if(!this.email){
                this.showModal({component: 'ModalWarning', data:{title:"Vnestie elektronsko pošto", description:"Za nadaljevanje, prosimo vpišite vašo elektronsko pošto"}});
            }
            else
                this.passRecovery({email: this.email});
        },
    },
    created(){
        //this.loginWithAzureSSO();
    }
};
</script>

<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
