<template>
    <div class="section_detail_subsection_holder">

        <div v-if="subsections_data[active_subsection_id] && active_subsection_id == 54">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Spodbujanje interdisciplinarnosti, multidisciplinarnosti kot prednosti UL, kot univerze, ki pokriva vsa področja.</li>
                    <li>Povečati vključenost uporabnikov znanja v oblikovanje/posodabljanje študijskih programov.</li>
                    <li>Dvig kompetenc študentov za prehod na trg dela.</li>
                    <li>Krepitev in promocija doktorskega študija.</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Posodobitev študijskih programov, novi poklici, novi študijski pristopi, nove družbene prioritete: digitalno, zeleno, družbene spremembe</td>
                            <td>Priprava analiz študijskih programov z analizo zaposljivosti in predlogi razvoja</td>
                        </tr>
                        <tr>
                            <td>Vzpostavitev sistema usklajenih urnikov za pospeševanje izbirnosti predmetov med članicami</td>
                            <td>Nabava skupne aplikacije za elektronske urnike, ki bo omogočila boljši pregled nad urniki po članicah</td>
                        </tr>
                        <tr>
                            <td>Vzpostavitev svetovalnih odborov v katere so vključeni predstavniki delodajalcev (gospodarstvo in negospodarstvo)</td>
                            <td>Uvedba na nekaj pilotnih članicah</td>
                        </tr>
                        <tr>
                            <td>Uvedba raznih oblik kreditno ovrednotenih delovnih praks študentov v kurikulum, kjer to ni obvezno že s študijskim programom</td>
                            <td>Uvedba na nekaj pilotnih članicah</td>
                        </tr>
                        <tr>
                            <td>Začetek izvajanja interdisciplinarnega doktorskega študijskega programa Umetnost</td>
                            <td>Priprava prvega razpisa za vpis</td>
                        </tr>
                        <tr>
                            <td>Sprememba izvedbe promocije doktorandk in doktorandov</td>
                            <td>Prva javna predstavitev v sodelovanju z MOL</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 57">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Zagotavljanje stabilnega financiranja raziskovalne dejavnosti</li>
                    <li>Povečati učinkovitost in uspešnost pri prijavah na velike EU projekte</li>
                    <li>Zagotavljanje etičnosti pri raziskovalnem delu</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Vzpostavitev procesa notranje delitve sredstev na podlagi novega zakona o raziskovalni dejavnosti</td>
                            <td>Priprava analize stanja in meril za razporejanje sredstev </td>
                        </tr>
                        <tr>
                            <td>Zagotoviti prenos dobrih praks med članicami</td>
                            <td>Priprava strategije sodelovanja med članicami</td>
                        </tr>
                        <tr>
                            <td>Finančna spodbuda prijaviteljem prek Razvojnega sklada </td>
                            <td>Priprava in objava razpisov Razvojnega sklada</td>
                        </tr>
                        <tr>
                            <td>Usposabljanja za raziskovalce</td>
                            <td>Usposabljanja za prijavo projektov Marie Skłodowska-Curie, ERC, Obzorje Evropa</td>
                        </tr>
                        <tr>
                            <td>Preprečevanje neetičnega ravnanja</td>
                            <td>Izvedba evalvacije sistema in priprava ukrepov za zaznana neetična ravnanja</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 58">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Promocija umetnosti in kulture znotraj UL, v širšem domačem in mednarodnem okolju okolju</li>
                    <li>Zagotoviti dodatne vire financiranja za področje umetnosti</li>
                    <li>Spremljanje kakovosti na umetniškem področju</li>
                    <li>Skrb za kulturno dediščino UL</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Aktivacija akademske TV kot univerzitetnega kanala</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Organizacija umetniških dogodkov akademij za vse zaposlene</td>
                            <td>Izvedba dogodkov v Mali galeriji in drugih dogodkov</td>
                        </tr>
                        <tr>
                            <td>Prijave na razpise raznih ministrstev</td>
                            <td>Ureditev statusa akademij za prijave na razpise Ministrstva za kulturo</td>
                        </tr>
                        <tr>
                            <td>Oblikovanje kazalnikov uspešnosti na področju umetnosti</td>
                            <td>Olikovanje kazalnikov uspešnosti na področju umetnosti</td>
                        </tr>
                        <tr>
                            <td>Vzpostavitev evidence umetniških del na ravni UL</td>
                            <td>Vzpostavitev evidence umetniških del na ravni UL</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 59">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Skrb za trajnostni razvoj na vseh področjih delovanja UL</li>
                    <li>Tesnejše sodelovanje z drugimi inštitucijami</li>
                    <li>Krepitev alumnov</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Vzpostaviti "Sustainable Development Goals"</td>
                            <td>Oblikovati delovno skupino za trajnostni razvoj</td>
                        </tr>
                        <tr>
                            <td>Vzpostavitev medsebojnih dogovorov o pridruženem članstvu in medsebojnem sodelovanju</td>
                            <td>Začetek pogovorov z UKC, ŠDL, inštituti</td>
                        </tr>
                        <tr>
                            <td>Vzpostavitev sistema donacij</td>
                            <td>Priprava načrta ustanovitve fundacije alumnov</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 60">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Odprta znanost</li>
                    <li>Zagotavljanje zdravega, varnega in stimulativnega študijskega okolja</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Vzpostavitev formalnih pogojev za upravljanje z raziskovalnimi podatki</td>
                            <td>Priprava strategije in drugih pravil o odprti znanosti</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Izvedba izobraževanj študentk, študentov in zaposlenih o upravljanju z odprtimi podatki</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Vzpostavitev pisarne varuha študentskih pravic</td>
                        </tr>
                        <tr>
                            <td>Evalvacija vključenosti športnih aktivnosti študentov v študijske programe</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Ustanovitev nagrade Uroša Seljaka</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Priprava pravilnika za nagrado dr. Ane Mayer Kansky za odmevna doktorska dela</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 62">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Strateško upravljanje</li>
                    <li>Krepitev notranje koordinacije upravljanja </li>
                    <li>Zagotoviti transparentno in stabilno financiranje </li>
                    <li>Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja</li>
                    <li>Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih</li>
                    <li>Nadaljevanje projekta APIS</li>
                    <li>Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu</li>
                    <li>Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje</li>
                    <li>Krepiti prepoznavnost UL</li>
                    <li>Krepiti notranji sistem komuniciranja</li>
                    <li>Nadaljevanje prostorskega razvoja</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Sprejeti strategijo razvoja UL</td>
                            <td>Sprejeti strategijo in podstrategije</td>
                        </tr>
                        <tr>
                            <td>Redni sestanki z dekani, tajniki in drugimi strokovnimi službami</td>
                            <td>Vzpostaviti mehanizme vodenja sestankov in spremljanja sprejetih sklepov</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Priprava pravilnika o zaslužnih profesorjih</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Podaljšanje mandata študentskega sveta na 2 leti</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Vzpostavitev sodelovanja predsednikov komisij senata in UO</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Priprava analize stroškov izvedbe študijskih programov in razprava o dolgoročni usmeritvi načina financiranja</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Zagotoviti rezervna sredstva za razvojne projekte in posebna tveganja</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

<div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 63">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Strateško upravljanje</li>
                    <li>Krepitev notranje koordinacije upravljanja </li>
                    <li>Zagotoviti transparentno in stabilno financiranje </li>
                    <li>Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja</li>
                    <li>Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih</li>
                    <li>Nadaljevanje projekta APIS</li>
                    <li>Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu</li>
                    <li>Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje</li>
                    <li>Krepiti prepoznavnost UL</li>
                    <li>Krepiti notranji sistem komuniciranja</li>
                    <li>Nadaljevanje prostorskega razvoja</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Odpraviti razlike v plačnem sistemu</td>
                            <td>Priprava analize plač v javnem sektorju in gospodarstvu kot izhodišče pogajanj z Vlado</td>
                        </tr>
                        <tr>
                            <td>Priprava pravnih podlag za delo in kadrovski razvoj</td>
                            <td>Dokončanje pravilnika o varovanju dostojanstva</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Dokončanje strategije o enakosti spolov</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Dokončanje akcijskega načrta kariernega razvoja raziskovalcev - HR strategija</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Priprava analize položaja raziskovalcev na delovnih mestih skupine H in izhodišča za urejanje njihovega statusa</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Pričetek prenove habilitacijskih meril</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Izbirni postopki zaposlovanja novih kadrov</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Priprava kompetenčnega modela za strokovne delavce</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 64">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Strateško upravljanje</li>
                    <li>Krepitev notranje koordinacije upravljanja </li>
                    <li>Zagotoviti transparentno in stabilno financiranje </li>
                    <li>Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja</li>
                    <li>Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih</li>
                    <li>Nadaljevanje projekta APIS</li>
                    <li>Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu</li>
                    <li>Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje</li>
                    <li>Krepiti prepoznavnost UL</li>
                    <li>Krepiti notranji sistem komuniciranja</li>
                    <li>Nadaljevanje prostorskega razvoja</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Implementacija po sprejetem načrtu</td>
                            <td>Implementacija po sprejetem načrtu</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 65">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Strateško upravljanje</li>
                    <li>Krepitev notranje koordinacije upravljanja </li>
                    <li>Zagotoviti transparentno in stabilno financiranje </li>
                    <li>Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja</li>
                    <li>Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih</li>
                    <li>Nadaljevanje projekta APIS</li>
                    <li>Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu</li>
                    <li>Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje</li>
                    <li>Krepiti prepoznavnost UL</li>
                    <li>Krepiti notranji sistem komuniciranja</li>
                    <li>Nadaljevanje prostorskega razvoja</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Prenova CGP - nadgradnja za sodobne načine komuniciranja</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Prenova spletnih strani</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Redna neformalna srečanja z novinarji</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Redne okrogle mize (Spre)govori znanost, znanost (spre)govori – pogovori o perečih temah </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Organizacija poljudnoznanstvenih predavanj</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Partnerstvo z Muzejem in galerijami mesta Ljubljana (MGML) pri projektu M_oder --> študentje v muzeju enkrat mesečno predstavljajo diplomska,magistrska ali doktorska del</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Pristop h konzorciju, ki izdaja Alternator in povabilo zaposlenim k pisanju za Alternator</td>
                        </tr>
                        <tr>
                            <td>Priprava strategije komuniciranja</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Sprememba načina internega komuniciranja</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 66">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li>Strateško upravljanje</li>
                    <li>Krepitev notranje koordinacije upravljanja </li>
                    <li>Zagotoviti transparentno in stabilno financiranje </li>
                    <li>Vzpostavitev zdravega, varnega in stimulativnega delovnega okolja</li>
                    <li>Zagotoviti kompetentne in strokovne kadre na vseh delovnih mestih</li>
                    <li>Nadaljevanje projekta APIS</li>
                    <li>Zagotavljanje IKT infrastrukture za digitalizacijo pedagoških in administrativno-upravnih procesov v podporo visokošolskemu študijskemu procesu</li>
                    <li>Vzpostavitev simulacijskega učnega okolja, ki bo omogočalo kvalitetno uporabo IKT v pedagoškem procesu in razvoj didaktične uporabe IKT za poučevanje</li>
                    <li>Krepiti prepoznavnost UL</li>
                    <li>Krepiti notranji sistem komuniciranja</li>
                    <li>Nadaljevanje prostorskega razvoja</li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Investicije: VF, FS, FFA, MF, ALUO, AG</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- <div v-else-if="subsections_data[active_subsection_id] && active_subsection_id == 54">
            <div class="subsection_description">
                <p> CILJI:
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul></p>
            </div>
            <div class="table_static_data">
                <table>
                    <thead>
                        <tr>
                            <th>AKTIVNOSTI V LETIH 2022 IN 2023</th>
                            <th>IZVEDBA V LETU 2022</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->

    </div>
</template>


<script>
    import router from '../../router/router'
	import { mapActions } from 'vuex';
	
	export default {
		name: 'SectionsDetailTableStaticCilji',
		components: {

        },
        data: function() {
			return {
			}
        },
        computed: {
			// Selected section object for level
			subsections_data(){

                let level = this.$store.getters.section_level;

                let subsections = this.$store.getters.subsections_data[level];
   
                // Return subsections for section and level
				return subsections ? subsections : {};
            },
            active_subsection_id(){
                return this.$store.getters.active_subsection_id;
            },
            
            // Get loged user faculty id
            getFacultyID() {
                return this.$store.getters.getFaculty.id;
            },

            // Get loged user faculty id
            getFacultyAcronym() {
                return this.$store.getters.getFaculty.acronym;
            },

            getTabCode(){
                if(router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela')
                    return 1;
                return 0;
            },
        }, 
        methods: { 		
            ...mapActions([
            ]),
        },
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
