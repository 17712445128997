<template>                               
    <div class="pagination">
        <span class="pagination_page_item">
            <font-awesome-icon 
                class="as_link_grey"
                v-show="currentPage > 1" 
                @click="set_current_page(currentPage-1)"
                :icon="['far', 'caret-square-left']"
            />
        </span>
        <span class="pagination_page_item as_link_grey"
            v-show="currentPage > 1" 
            @click="set_current_page(1)">
            1
        </span>
        <span class="pagination_page_item"
            v-show="currentPage > 1">
            ...
        </span>
        <span class="pagination_page_item">
            {{currentPage}}
        </span>
        <span class="pagination_page_item"
            v-show="currentPage < maxPage">
            ...
        </span>
        <span class="pagination_page_item as_link_grey"
                v-show="currentPage < maxPage" 
                @click="set_current_page(maxPage)">
            {{maxPage}}
        </span>
        <span class="pagination_page_item">
            <font-awesome-icon 
                class="as_link_grey"
                v-show="currentPage < maxPage" 
                @click="set_current_page(currentPage+1)"
                :icon="['far', 'caret-square-right']"
            />
        </span>
    </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "PaginationRow",
    data: function () {
        return {
        };
    },
    props: {
        currentPage:{
            type: Number,
            required: true
        },
        maxPage:{
            type: Number,
            required: true
        }
    },
    computed: {
        
    },
    methods: {
        //change current page
        set_current_page(page){
            this.$emit('change_page', page);
        },
    },
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
