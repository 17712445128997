<template>
    <textarea
      :value="value"
      @input="autoResize"
      @change="handleChange($event)"
      ref="textarea"
      :style="{ overflow: 'hidden', resize: 'none' }"
      rows="4"
    ></textarea>
  </template>
  
  <script>
  export default {
    name: "AutoResizeTextarea",
    props: {
      value: {
        type: String,
        required: true
      },
      qid: {
        type: [Number, String]
      },
      cid: {
        type: [Number, String]
      },
      rid: {
        type: [Number, String]
      }
    },
    data() {
      return {
        minRows: 4
      };
    },
    watch: {
      value() {
        this.autoResize();
      }
    },
    methods: {
      handleChange($event) {
        if(this.qid !== undefined)
            this.$emit("change", this.qid, $event.target.value); // Emit updated content on question change
        else if(this.cid !== undefined && this.rid !== undefined)
            this.$emit("change", this.rid, this.cid, $event.target.value); // Emit updated content on cell change
        else
            console.error("No question id or cell id and row id provided");
      },
      autoResize() {
        const textarea = this.$refs.textarea;
        textarea.style.height = "auto";
  
        const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight);
        const minHeight = lineHeight * this.minRows;
  
        textarea.style.height = `${Math.max(textarea.scrollHeight, minHeight)}px`;
      }
    },
    mounted() {
      this.autoResize();
    }
  };
  </script>