
// Popup modal getters
export const getModalVisible = state => {
	return state.modalVisible;
};
export const getModalComponent = state => {
	return state.modalComponent;
};
export const getModalData = state => {
	return state.modalData;
};
export const getModalWorkingVisible = state => {
	return state.modalWorkingVisible;
};

// User getters
export const isAuthenticated = state => {
	return state.isAuthenticated;
};
export const getToken = state => {
	return state.token;
};
export const getUser = state => {
	return state.user;
};


// Version getter
export const getVersion = state => {
	return state.version;
};


// Faculty getters
export const getFaculty = state => {
	return state.faculty;
};

export const getFaculties = state => {
	return state.faculties;
};

export const getSortedFacultiesByAcronym = state => {
	return state.sorted_faculties_by_acronym;
};

export const getYears = state => {
	return state.years;
};

// Return year
export const year = state => {
	return state.year;
};

// Return true if current year meets standards to active year
export const is_active_year = state => {
	return state.active_year_date.active_year == state.year;
};

// Return active year
export const active_year = state => {
	return state.active_year_date.active_year;
};
