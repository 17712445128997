<template>
    <div class="dashboard_faculty_square_holder">
        <div class="dashboard_faculty_square_item">
            <div class="dashboard_faculty_item_left">
                <div class="as_link_grey dashboard_faculty_item_name" @click="changeFacultySection({data: {section_id: section_data.id}})">
                    {{section_data.name}}
                </div>
                <br><br>
                <div class="deadline_status" :class="deadlineStatus">{{status_text}}</div>
                <div class="dashboard_faculty_item_progress">
                    Oddano: {{faculty_submitted_count}} / {{faculty_count}}
                </div>
                <br><br><br>
                <div v-show="deadlineTimestamp > 0" class="dashboard_faculty_square_item_deadline">
                    Rok: {{getDeadline()}}
                </div><br>
                <div v-show="deadlineClosedTimestamp > 0" class="dashboard_faculty_square_item_deadline">
                    Zadnji rok: {{getDeadlineClosed()}}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    name: 'SectionsDashboardFacultySquareItem',
	
	data: function() {
		return {
            deadlineTimestamp: 0,
            deadlineClosedTimestamp: 0,
            status_text: '',
            showSections: false,
            nearDeadlineDays: 14
		}
    },
    props:{
        section_data: {
            type: Object,
            required: true
        },
        faculty_submitted_count: {
            type: Number,
            required: true
        },
        faculty_count: {
            type: Number,
            required: true
        }
    },
	computed: {    
        deadlineStatus(){
            let temp_date = new Date();
            let timestamp_now = temp_date.getTime();

            //It is all filled
            if(this.faculty_submitted_count == this.faculty_count){
                this.status_text = 'oddano';
                this.dashboardAddLastStatusBySection({id: this.section_data.id, status: 'status_filled'});
                return 'filled';
            }
            //If nearest deadline is already closed
            else if(this.deadlineClosedTimestamp > 0 && timestamp_now > this.deadlineClosedTimestamp){
                this.status_text = 'zamujeno';
                this.dashboardAddLastStatusBySection({id: this.section_data.id, status: 'status_after_late'});
                return 'after_late';
            }
            //If deadline exists
            else if(this.deadlineTimestamp > 0){
                //If deadline is passed
                if(timestamp_now > this.deadlineTimestamp){
                    this.status_text = 'zamuda';
                    this.dashboardAddLastStatusBySection({id: this.section_data.id, status: 'status_after'});
                    return 'after';
                }
                //If we are near deadline (14 days)
                else if(timestamp_now > this.deadlineTimestamp - this.nearDeadlineDays*86400000){
                    this.status_text = 'v pripravi';
                    this.dashboardAddLastStatusBySection({id: this.section_data.id, status: 'status_preparation_late'});
                    return 'preparation_late';
                }
                //In preparation
                else {
                    this.status_text = 'v pripravi';
                    this.dashboardAddLastStatusBySection({id: this.section_data.id, status: 'status_preparation'});
                    return 'preparation';
                }
            }
            //In preparation
            else {
                this.status_text = 'v pripravi';
                this.dashboardAddLastStatusBySection({id: this.section_data.id, status: 'status_preparation'});
                return 'preparation';
            }
        },
        
    },
    methods: {
        ...mapActions([
            'changeFacultySection',
            'dashboardAddLastStatusBySection'
        ]),
        
        getDeadlineClosed(){
            if(this.section_data.deadlineClosed !== '0000-00-00'){
                let date = new Date(this.section_data.deadlineClosed);
                this.deadlineClosedTimestamp = date.getTime();
                return date.toLocaleDateString("sl-SL");
            }
            else{
                this.deadlineClosedTimestamp = 0;
                return '';
            }
        },

        getDeadline(){
            if(this.section_data.deadline !== '0000-00-00'){
                let date = new Date(this.section_data.deadline);
                this.deadlineTimestamp = date.getTime();
                return date.toLocaleDateString("sl-SL");
            }
            else{
                this.deadlineTimestamp = 0;
                return '';
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "../../scss/style.scss";
</style>

