<template>
	<div class="main_holder evalvation">

        <ul-evalvation-submenu :showyear="showYear"></ul-evalvation-submenu>

        <div class="content_holder">

			<transition 
					appear
					mode="out-in"
					enter-active-class="animated fadeIn"
					leave-active-class="animated fadeOut"		
				>		
				<router-view></router-view>
			</transition>
		</div>
	</div>
</template>


<script>
    import EvalvationSubMenu from './EvalvationSubMenu.vue';

    export default {
        name: 'Evalvation',
        components: {
            'ul-evalvation-submenu': EvalvationSubMenu,
        },
        data: function() {
            return {
            }
		},
		computed: {
            showYear() {
				if (this.$route.name === 'programDetail' || this.$route.name === 'departmentDetail') { return false }
				else return true;
			}
		}
    }
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
