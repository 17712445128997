<template>
    <div class="submenu_holder">

        <ul class="submenu items">
            <router-link tag="li" class="item" :to="{ name: 'evalvationProgramList' }" v-if="getUserType > 1" exact><span class="item_text">Seznam programov</span></router-link>			
            <router-link tag="li" class="item" :to="{ name: 'evalvationPrograms' }" exact><span class="item_text">Programi</span></router-link>
            <router-link tag="li" class="item" :to="{ name: 'evalvationDashboard' }" exact><span class="item_text">Dashboard</span></router-link>
            <router-link tag="li" class="item" :to="{ name: 'questionnaireBuild'}" v-if="dashboard_data_by_program.length > 0 && getUserType == 2 || isPermissionToInternal" exact><span class="item_text">Obrazec</span></router-link>	
            <!-- <router-link tag="li" class="item" :to="{ name: 'questionnaireBuildDoc'}" v-if="getUserType > 1 || isPermissionToInternal" exact><span class="item_text">Obrazec (doktorski)</span></router-link>	 -->
            <router-link tag="li" class="item" :to="{ name: 'evalvationDepartments' }" v-if="is_department_data" exact><span class="item_text">Oddelki</span></router-link>
            <router-link tag="li" class="item" :to="{ name: 'departmentsQuestionnaire' }" v-if="is_department_data && (getUserType == 1 || getUserType == 2)" exact><span class="item_text">Obrazec za oddelke</span></router-link>
            <router-link tag="li" class="item" :to="{ name: 'evalvationAnalytics' }" v-if="getUserType > 1" exact><span class="item_text">Analitika</span></router-link>
            <router-link tag="li" :to="{ name: 'FAQEvalvation' }">Pogosto zastavljena vprašanja</router-link>
        </ul>

        <div v-show="showyear" class="change_year">
            Študijsko leto: 
            <select @change="changeYear(selected_year)" v-model="selected_year">
                <option 
                    v-for="year in getYears" 
                    v-bind:value="year" 
                    v-bind:key="year" 
                    :selected="year == selected_year"
                >
                    {{ year }}
                </option>
            </select>
        </div>
    </div>
</template>


<script>
	import { mapActions } from 'vuex';
	
	export default {
        name: 'EvalvationSubMenu',
        props: {
            showyear: {
                type: Boolean,
                default: true
            }
        },
		data: function() {
			return {
                selected_year: this.$store.getters.year
			}
		},
		computed: {
            getYear(){
				return this.$store.getters.year;
            },
            // Get loged user faculty id
            getFacultyId() {
                return this.$store.getters.getFaculty.id;
            },
            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },
            // Get all years
			getYears() {
				return this.$store.getters.getYears;
            },
            isPermissionToInternal(){
                return this.getUserType == 1 && this.getFacultyInternalPermission.includes(this.$store.getters.getFaculty.id);
            },
            //Get array of faculties that have permission to internal questionnaire
            getFacultyInternalPermission(){
                return this.$store.getters.faculties_internal_permission;
            },

            dashboard_data_by_program() {
                return Object.values(this.$store.getters.evalvation_programs_all);
            },

            //check if there is department data for this faculty
            is_department_data() {
                //admin could have all departments from all faculties fetched in users list
                if(this.getUserType > 1){
                    for(let department of Object.values(this.$store.getters.evalvation_departments)){
                        if(department.faculty_id == this.getFacultyId)
                            return true
                    }
                    return false;
                }

                return Object.keys(this.$store.getters.evalvation_departments).length > 0;
            },
		},
		methods: { 		
            ...mapActions([
                'changeYear',
                'getDepartmentsListForFaculty',
                'getDepartmentsListForUser',
                'getProgramsListAll'
            ]),
        },
		created(){
            if(!this.is_department_data) {
                if (this.getUserType > 0) this.getDepartmentsListForFaculty();
                else this.getDepartmentsListForUser();
            }

            if(this.getUserType > 1 && !this.dashboard_data_by_program.length == 0) {
                this.getProgramsListAll();
            }
		}
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
