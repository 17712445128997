<template>
	<div class="main_holder sections">

        <ul-users-submenu></ul-users-submenu>
		
		<div class="content_holder">

			<transition 
					appear
					mode="out-in"
					enter-active-class="animated fadeIn"
					leave-active-class="animated fadeOut"		
				>		
				<router-view></router-view>
			</transition>
		</div>
	</div>
</template>


<script>
    import UsersSubMenu from './UsersSubMenu.vue';

    export default {
        name: 'Users',
        components: {
            'ul-users-submenu': UsersSubMenu,
        },
        data: function() {
            return {
            }
		},
		computed: {
		}
    }
</script>


<style lang="scss" scoped>
</style>
