<template>
	<transition name="fadeHeight">
		<div class="modal_holder" v-if="visible" v-bind:class="modalComponent">
			<div class="modal_overlay" @click="hideModal()"></div>
			
			<div class="modal_content">
				<div class="modal_content_inner">
					<component :is="component"></component>
				</div>
			</div>
		</div>
	</transition>
</template>


<script>
	import Vue from 'vue';
	import { mapState } from 'vuex';
	import { mapActions } from 'vuex';

	export default {
		name: 'Modal',
		data: function() {
			return {	
				component: null
			}
		},
		methods: {
			...mapActions([
				'hideModal'
			])
		},
		computed: {
			visible(){
				return this.$store.getters.getModalVisible;
			},
			modalComponent(){
				return this.$store.getters.getModalComponent;
			}
		},
		watch: {
			modalComponent(componentPath) {
				if (!componentPath) return;

				let componentName = componentPath.substring(componentPath.lastIndexOf('/')+1);
				Vue.component(componentName, () => import(`./${componentPath}`));

				this.component = componentName;
			}
		},
		created() {
			document.onkeydown = evt => {
				evt = evt || window.event;
				if (evt.keyCode == 27) {
					this.hideModal();
				}
			};
		}
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
	
	/* Transicije */
	.fadeHeight-enter-active,
	.fadeHeight-leave-active {
	  	transition: all 0.3s;
	}
	.fadeHeight-enter,
	.fadeHeight-leave-to{
	  	opacity: 0;
	}
</style>
