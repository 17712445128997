import { render, staticRenderFns } from "./NoticesGeneral.vue?vue&type=template&id=6558c67c&scoped=true"
import script from "./NoticesGeneral.vue?vue&type=script&lang=js"
export * from "./NoticesGeneral.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6558c67c",
  null
  
)

export default component.exports