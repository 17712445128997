<template>
    <div>
        <div class="as_link icon bold" v-if="Object.keys(info_table_columns).length > 0" @click="show_table = !show_table">
            <font-awesome-icon v-if="!show_table" :icon="['fas', 'chevron-down']" title="Razširi pogled" />
            <font-awesome-icon v-else :icon="['fas', 'chevron-up']" title="Skrči pogled" />
            Usmeritve
        </div>

        <transition name="expandd" mode="out-in">
            <div v-show="show_table" class="table_static_data" style="margin-top: 10px;">
                <table>
                    <thead>
                        <tr>
                            <th class="invisible_cell"></th>
                            <th v-for="(column, key) of info_table_columns" :key="key">
                                <div class="name">
                                    {{ column.name }}
                                </div>

                                <div class="description">
                                    {{ column.description }}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr :class="getLevelClass(row.level)" v-for="(row, key) of info_table_data" :key="key">
                            <td v-for="(column, key) of row.data" :key="key"> 
                                <span>{{column}}</span>
                            </td>
                        </tr> -->

                        <template v-for="(row, key) of info_table_data">

                            <!-- Level One Row -->
                            <transition name="expand" mode="out-in">
                                <tr :class="getLevelClass(row.level)" :key="'level1-' + key" v-if="row.level === '1'">
                                    <td class="invisible_cell"></td>
                                    <td v-for="(column, key1) of row.data" :key="key1">
                                        <span>{{ column }}</span>
                                    </td>
                                </tr>
                            </transition>

                            <!-- Level Two Row -->
                            <transition name="expand" mode="out-in">
                                <tr :class="getLevelClass(row.level)" :key="'level2-' + key" v-if="row.level === '2'">
                                    <td class="invisible_cell">
                                        <div class="as_link_grey icon" v-if="hasLevel3Rows(key)" @click="toggleLevelThree(key)">
                                            <font-awesome-icon v-if="!row.expanded" :icon="['fas', 'chevron-down']" title="Razširi pogled" />
                                            <font-awesome-icon v-else :icon="['fas', 'chevron-up']" title="Skrči pogled" />
                                        </div>
                                    </td>
                                    
                                    <td v-for="(column, key2) of row.data" :key="key2">
                                        <span>{{ column }}</span>
                                    </td>
                                </tr>
                            </transition>

                            <!-- Level Three Rows (Only if expanded) -->
                            <transition name="expand" mode="out-in">
                                <tr :class="getLevelClass(row.level)" :key="'level3-' + key"
                                    v-if="row.level === '3' && row.expanded">
                                    <td class="invisible_cell"></td>
                                    <td v-for="(column, key3) of row.data" :key="key3">
                                        <span>{{ column }}</span>
                                    </td>
                                </tr>
                            </transition>

                        </template>
                    </tbody>
                </table>
            </div>
        </transition>
    </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
    name: 'SectionsDetailInfoTable',
    components: {
    },
    data: function () {
        return {
            show_table: false
        }
    },
    computed: {
        active_subsection_id() {
            return this.$store.getters.active_subsection_id;
        },

        // Selected subsection table info
        info_table_columns() {
            if (this.$store.getters.subsection_data.info_table && this.$store.getters.subsection_data.info_table.columns)
                return this.$store.getters.subsection_data.info_table.columns;
            return {};
        },

        // Selected subsection table info
        info_table_data() {
            if (this.$store.getters.subsection_data.info_table && this.$store.getters.subsection_data.info_table.data)
                return this.$store.getters.subsection_data.info_table.data;
            return {};
        },
    },

    methods: {
        ...mapActions([
        ]),

        getLevelClass(level) {
            return 'level' + level;
        },

        /* toggleLevelThree(row) {
            // Toggle the expanded state of level three rows for the clicked level two row
            this.$set(row, 'expanded', !row.expanded);
            console.log(row);
        }, */

        toggleLevelThree(rowKey) {
            if(this.hasLevel3Rows(rowKey)){
                const levelTwoRow = this.info_table_data[rowKey];
                const dataKeys = Object.keys(this.info_table_data);
                const currentIndex = dataKeys.indexOf(rowKey);

                levelTwoRow.expanded = !levelTwoRow.expanded; // Toggle the state of level 2 row

                if (currentIndex === -1) {
                    return; // Row key not found, exit early
                }

                const levelKeys = ['1', '2']; // Keys representing level 1 and level 2 rows

                // Find the index of the last level 3 row
                let lastLevelThreeRowIndex = currentIndex;
                for (let i = currentIndex + 1; i < dataKeys.length; i++) {
                    const nextRowKey = dataKeys[i];
                    const nextRow = this.info_table_data[nextRowKey];

                    if (levelKeys.includes(nextRow.level)) {
                        break;
                    }

                    // Keep track of the last level 3 row index
                    if (nextRow.level === '3') {
                        lastLevelThreeRowIndex = i;
                    }
                }

                // Toggle the expanded state of all level 3 rows within the range
                for (let i = currentIndex + 1; i <= lastLevelThreeRowIndex; i++) {
                    const nextRowKey = dataKeys[i];
                    const nextRow = this.info_table_data[nextRowKey];

                    if (nextRow.level === '3') {
                        nextRow.expanded = !nextRow.expanded; // Toggle the state
                    }
                }
            }
        },

        // Check if there are level 3 rows below the level 2 row
        hasLevel3Rows(level2RowKey) {
            const dataKeys = Object.keys(this.info_table_data);
            const currentIndex = dataKeys.indexOf(level2RowKey);

            if (currentIndex === -1) {
                return false; // Row key not found, no level 3 rows
            }

            const levelKeys = ['1', '2']; // Keys representing level 1 and level 2 rows

            // Search for the first level 3 row after the current level 2 row
            for (let i = currentIndex + 1; i < dataKeys.length; i++) {
                const nextRowKey = dataKeys[i];
                const nextRow = this.info_table_data[nextRowKey];

                if (levelKeys.includes(nextRow.level)) {
                    // Reached the next level 1 or level 2 row, no more level 3 rows
                    break;
                }

                if (nextRow.level === '3') {
                    return true; // Found a level 3 row
                }
            }

            return false; // No level 3 rows found
        },
    },
}
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

/* Define the expand animation */
.expand-enter-active,
.expand-leave-active {
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.expand-enter,
.expand-leave-to {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}

/* Define the expand animation - ignore leave because its not working */
.expandd-enter-active{
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.expandd-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}



/* Transicije */
.fade-enter-active {
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
