<template>
	<div class="main_holder login">
		
		<div class="content_holder">

			<div class="login_box">

                <div class="input_holder">
                    Elektronska pošta: <input type="email" maxlength="50" v-model="email" ref="email">
                    Geslo: <input type="password" maxlength="50" v-model="password" @keyup.enter="loginUser()" ref="password">
                </div>

                <div class="input_holder button_holder">
                    <button class="button" @click="loginUser({email: email, password: password})">Vpis</button>
                </div>

                <div class="as_link pass_recovery_link" @click="passRecoveryClick()">
                    <span>Ste pozabili geslo?</span>
                </div>
            </div>
            
		</div>
	</div>
</template>


<script>
    import { mapActions } from 'vuex';
        
    export default {
        name: 'Login',

        data: function() {
            return {
                email: '',
                password: '',
            }
        },
        methods: { 
            ...mapActions([
                'loginUser',
                'showModal',
                'passRecovery'
            ]),

            passRecoveryClick(){
                if(!this.email){
                    this.showModal({component: 'ModalWarning', data:{title:"Vnestie elektronsko pošto", description:"Za nadaljevanje, prosimo vpišite vašo elektronsko pošto"}});
                }
                else
                    this.passRecovery({email: this.email});
            },
        },
        mounted(){
            this.$refs.password.focus();
        }
    }
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
