<template>
    <div class="main_holder">
        <div class="content_holder">
            <div class="content">
                <h2>Pogosta vprašanja</h2>

                <div v-if="getUserType==2" class="faq_texteditor_content">
                    <vue-editor class="text-editor full_width" :editorToolbar="customToolbar" v-model="editor"></vue-editor>
                    <button class="button" @click="send()">Shrani</button>
                </div> 

                <div class="faq_text_content">
                    <span v-html="getFaqText"></span>
                </div>
            </div>
        </div> 
    </div>
</template>


<script>
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
    name: "faq",
    components: {
        VueEditor,
    },
    props: {
        tab: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            editor: "",
            customToolbar: [["bold", "italic", "underline", "link"],
					[{ list: "ordered" }, { list: "bullet" }]],
        };
    },
    computed: {
        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        getFaqText() {
            this.editor = this.$store.getters.faq_text;
            return this.$store.getters.faq_text;
        },
    },
    methods: {
        ...mapActions([
            "sendFaq",
            "getFaq",
        ]),

        send() {
            this.sendFaq({
                text: this.editor,
                tab: this.tab
            });
        },
    },
    created() {
        this.getFaq({tab: this.tab});
    },
};
</script>


<style lang="scss" scoped>
</style>
