<template>
    <div class="submenu_holder">

        <ul class="submenu items">
            <router-link tag="li" class="item" :to="{ name: 'usersList' }" exact><span class="item_text">Seznam uporabnikov</span></router-link>			
            <router-link tag="li" class="item" :to="{ name: 'informing' }" v-if="getUserType==2"><span class="item_text">Obveščanje</span></router-link>
        </ul>

    </div>
</template>


<script>
	import { mapActions } from 'vuex';
	
	export default {
        name: 'UsersSubMenu',

		data: function() {
			return {
			}
		},
		computed: {
            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },
		},
		methods: { 		
            ...mapActions([
            ]),
        },
		created(){
            
		}
	}
</script>


<style lang="scss" scoped>
</style>
