import Vue from 'vue'
import App from './App'
import router from './router/router'
import { store } from './store/store'
import axios from 'axios'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'animate.css/animate.min.css'


// Font awesome component
library.add(fas)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)


switch (window.location.hostname) {
    case 'porocanje.uni-lj.si':
        axios.defaults.baseURL = 'https://porocanje.uni-lj.si/backend/api.php';
        break;
    case 'porocanje-test.uni-lj.si':
        axios.defaults.baseURL = 'https://porocanje-test.uni-lj.si/backend/api.php';
        break;
    default:
        axios.defaults.baseURL = 'http://localhost/backend/api.php';
}

// Axios for backend api connection
//axios.defaults.baseURL = 'https://porocanje.uni-lj.si/backend/api.php';
//axios.defaults.baseURL = 'http://localhost/backend/api.php';

// Axios interceptor for adding token to api requests
axios.interceptors.request.use(function (config) {

    var token = store.getters.getToken;
    config.headers.Authorization = token;

    return config;
});

// Get token and user_id from local storage
var token = localStorage.getItem('token');
//var user_id = localStorage.getItem('user_id');
var expirationDate = localStorage.getItem('expirationDate');


// If token and user_id is not set - redirect to login
if (!token /* || !user_id */ || !expirationDate) {

    // Redirect to login unless there is a password reset site
    // todo - maybe check for params too (but then you have to init vue first)
    if (!location.href.match(/nastavitev-gesla/) && !location.href.match(/login-password/))
        router.push('/login/');

    // Init vue
    new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App)
    })
}
// Check if login has expired
else if (new Date().getTime() >= expirationDate) {
    //Auto logout
    store.dispatch('logoutUser');
}
// Token and user_id in local storage - try to auto login
else {

    // Save token to store
    store.commit('setToken', token);

    // Check if user logged in with valid token and get user data
    axios.get('?module=user&action=auto_login_user')
        .then(
            res => {

                // Autologin succesful
                if (res.data.success === true) {
                    store.dispatch('getActiveYear');
                    store.commit('setAuthData', res);
                }
                // Login failed - clear storage and redirect to login page
                else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('faculty');
                    localStorage.removeItem('year');
                    localStorage.removeItem('expirationDate');

                    router.push('/login/');
                }

                // Init vue
                new Vue({
                    el: '#app',
                    router,
                    store,
                    render: h => h(App)
                })

                if (router.currentRoute.name == 'login' && res.data.success === true) {
                    router.push('/podrocja/');
                }
            }
        )
        .catch(error => console.log(error))
}
