<template>
    <div class="main_holder">
        <div class="content_holder">
            <div class="content">
                <h2>Moj račun</h2>

                <div class="my_account_form">
                    <p>Elektronska pošta</p>
                    <input
                        type="email"
                        id="user-email"
                        name="user-email"
                        maxlength="50"
                        class="default_width" 
                        v-model="email"
                        disabled
                        @keyup="/* all_filled = check_all_filled() */"
                    />
                    <br />

                    <p>Ime</p>
                    <input
                        type="text"
                        id="user-name"
                        name="user-name"
                        maxlength="50"
                        class="default_width" 
                        v-model="name"
                        @keyup="all_filled = check_all_filled()"
                    />
                    <br />

                    <p>Priimek</p>
                    <input
                        type="text"
                        id="user-surname"
                        name="user-surname"
                        maxlength="50"
                        class="default_width" 
                        v-model="surname"
                        @keyup="all_filled = check_all_filled()"
                    />
                    <br />

                    <div v-show="getUserType == 2 || (getUserType == 1 && user_data.access_evalvation == 1)">
                        <form style="margin-top:10px; font-size:15px;">
                            <label>
                                <input
                                    type="checkbox"
                                    v-model="notifications"
                                    true-value="1"
                                    false-value="0"
                                />{{ notificationsString }}
                            </label>
                        </form>
                    </div>

                    <!-- <p>Geslo</p>
                    <input
                        type="password"
                        id="user-surname"
                        name="user-surname"
                        maxlength="50"
                        class="default_width" 
                        v-model="password"
                        autocomplete="new-password"
                    />
                    <br><i class="input_info">Če želite spremeniti svoje geslo za vstop v aplikacijo, v zgornje polje vnesite novo geslo in kliknite na gumb "Shrani"</i> -->
                    <br><br><br>

                    <div class="modal_component_footer">
                        <button class="button" @click="send()" :disabled="!all_filled" :class="{disabled: !all_filled}">Shrani</button>
                    </div>  
                    
                    <br>
                    <div v-if="email=='uros.podkriznik@gmail.com'" class="modal_component_footer">
                        <button class="button" @click="sendTest()">Poslji test mail</button>
                    </div> 
                </div> 
            </div>
        </div> 
    </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "userProfile",
    components: {
    },
    data: function () {
        return {
            name: "",
            surname: "",
            email: "",
            password: "",
            all_filled: true,
            notifications: 0
        };
    },
    computed: {
        notificationsString(){
            return 'Elektronsko obveščanje o ' + (this.getUserType == 1 ? 'zaključenih programih' : 'oddaji modulov članic');
        },

        user_data() {
            return this.$store.getters.getUser;
        },

        // Get loged user faculty id
        getFacultyId() {
            return this.$store.getters.getFaculty.id;
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
    },
    methods: {
        ...mapActions([
            "showModal",
            "editUser",
            "sendTest"
        ]),
        //check if all fields are filled
        check_all_filled(){
            if(
                this.name !== "" && 
                this.surname !== "" && 
                this.email !== ""
            )
                return true;
            return false;
        },

        set_basic_user_data(){
            if(this.user_data.name){
                this.name = this.$store.getters.getUser.name;
                this.surname = this.$store.getters.getUser.surname;
                this.email = this.$store.getters.getUser.email;
                this.notifications = this.$store.getters.getUser.notifications;
            }
        },

        send() {
            this.editUser({
                show_message: true,
                id: this.user_data.id,
                name: this.name,
                surname: this.surname,
                //email: this.email,
                //password: this.password,
                faculty_id: this.getFacultyId,
                type: this.user_data.type,
                active: 1,
                access_sections: this.user_data.access_sections,
                access_work_plan: this.user_data.access_work_plan,
                access_evalvation: this.user_data.access_evalvation,
                notifications: this.notifications
            });
        },
    },
    created() {
        this.set_basic_user_data();
    },
};
</script>


<style lang="scss" scoped>
</style>
