<template>
  <div
    class="content section_detail_holder sections_list_holder"
    v-if="getUserType == 2 || isPermissionToInternal"
  >
    <div class="sections_upper_holder">
      <div>
        <!-- Evalvation title -->
        <div class="evalvation_title" id="evalvation_title">
          Samoevalvacijski obrazec za leto {{ year }}
        </div>
        <div class="evalvation_title_second">
          {{ active_page_name }}
        </div>
      </div>

      <!-- Exports icons - temp disabled for doctorate programs -->
      <div class="file_exports" v-if="q_type != '4'">
        <!--<div class="as_link icon right_margin" title="Izvoz PDF" @click="/*deleteAttachmentConfirm(attachment.id)*/">
                    <font-awesome-icon :icon="['far', 'file-pdf']" />
                </div>-->
        <div
          class="as_link icon"
          title="Izvoz Word"
          @click="
            exportQuestionnaire({
              questionnaire_id: questionnaire_details.id,
              format: 'doc',
            })
          "
        >
          <font-awesome-icon :icon="['far', 'file-word']" />
        </div>
      </div>
    </div>
    <br />

    <div v-if="questionnaire_details" class="sections_intro edit">
      <div class="sections_intro_text"
        v-if="get_questionnaire_intro && get_questionnaire_intro !== ''"
        v-html="get_questionnaire_intro"
      ></div>
      <div class="sections_intro_text" v-else><i>Vnesite uvod/navodila</i></div>

      <div class="button_holder" v-if="getUserType == 2">
        <div
          class="subsection_edit button"
          @click="showModal({ component: 'evalvation/EditQuestionnaireIntro' })"
        >
          Uredi
        </div>
      </div>
    </div>

    <!-- Izpis roka za oddajo (datuma) znotraj področja, v kolikor ga je administrator nastavil
        <p class="deadline" :class="getDeadlineClass(section.deadline)" v-if="section.deadline !== '0000-00-00'"> Rok za oddajo: {{getDeadlineDate(section.deadline)}}</p> -->

    <div v-if="questionnaire_details" class="section_detail_content">
      <!-- Left menu - pages -->
      <ul-questionnaire-pages
        :build_mode="true"
        :q_type="q_type"
      ></ul-questionnaire-pages>

      <div class="right">
        <!-- Questionnaire details -->
        <ul-questionnaire-detail
          v-if="active_page != 'RU'"
          :build_mode="true"
          :q_type="q_type"
        ></ul-questionnaire-detail>

        <!--<br v-if="active_page != 'RU'">-->

        <!-- Questionnaire questions -->
        <ul-questionnaire-questions
          :build_mode="true"
          :q_type="q_type"
        ></ul-questionnaire-questions>
      </div>
    </div>

    <ul-new-questionnaire
      v-if="!questionnaire_details && getUserType == 2"
      :q_type="q_type"
    ></ul-new-questionnaire>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import QuestionnaireBuildDetail from "./EvalvationQuestionnaireBuildDetail.vue";
import QuestionnairePageQuestions from "./EvalvationQuestionnairePageQuestions.vue";
import ProgramPages from "./EvalvationProgramPages.vue";
import NewQuestionnaire from "./EvalvationNewQuestionnaire.vue";

export default {
  name: "EvalvationQuestionnaireBuild",
  components: {
    "ul-questionnaire-detail": QuestionnaireBuildDetail,
    "ul-questionnaire-questions": QuestionnairePageQuestions,
    "ul-questionnaire-pages": ProgramPages,
    "ul-new-questionnaire": NewQuestionnaire,
  },
  data: function () {
    return {};
  },
  props: {
    q_type: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    year() {
      return this.$store.getters.year;
    },

    // Get details of questionnaire
    questionnaire_details() {
      if (this.$store.getters.evalvation_questionnaire_detail.id) {
        return this.$store.getters.evalvation_questionnaire_detail;
      }
      return null;
    },

    // Get name of active page
    active_page_name() {
      if (this.active_page == "RU")
        return "Pregled realizacije ukrepov in predlogi izboljšav";
      else if (
        this.active_page &&
        (this.$store.getters.evalvation_questionnaire_detail.pages ||
          this.$store.getters.evalvation_internal_questionnaire_detail.pages)
      ) {
        if (
          this.$store.getters.evalvation_questionnaire_detail.pages[
            this.$store.getters.active_q_page_id
          ]
        )
          return this.$store.getters.evalvation_questionnaire_detail.pages[
            this.$store.getters.active_q_page_id
          ].name;
        else
          return this.$store.getters.evalvation_internal_questionnaire_detail
            .pages[this.$store.getters.active_q_page_id].name;
      }
    },

    // Get active page
    active_page() {
      return this.$store.getters.active_q_page_id;
    },

    // Get loged user type
    getUserType() {
      return this.$store.getters.getUser.type;
    },

    isPermissionToInternal() {
      return (
        this.getUserType == 1 &&
        this.getFacultyInternalPermission.includes(
          this.$store.getters.getFaculty.id
        )
      );
    },

    //Get array of faculties that have permission to internal questionnaire
    getFacultyInternalPermission() {
      return this.$store.getters.faculties_internal_permission;
    },

    // Get questionnaire intro
    get_questionnaire_intro() {
      return this.$store.getters.evalvation_questionnaire_detail.intro;
    },

    // Get list of image types for questions
    get_question_image_types() {
      return this.$store.getters.question_image_types;
    },
  },
  methods: {
    ...mapActions([
      "getQuestionnaireDetails",
      "showModal",
      "exportQuestionnaire",
      "getQuestionImageTypes",
    ]),
  },
  created() {
    this.getQuestionnaireDetails({ type: this.q_type });
    if (
      !this.get_question_image_types ||
      Object.keys(this.get_question_image_types) == 0
    )
      this.getQuestionImageTypes();
  },
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

/* Transicije */
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
