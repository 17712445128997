<template>
    <div class="section_detail_level_holder evalvation_detail_page_holder">

        <div v-for="page in page_data" 
            :key="page.id"
            class="level_item page_item pointer"
            v-bind:class="{ active: page.id == active_page_id }"
            @click="changeActivePage(page.id)"
        >     
            {{ page.name }} 
        </div>

        <div class="level_add_holder" v-if="build_mode && (getUserType == 2 || (getUserType == 1 && (typeof q_type === 'string' && q_type.toString().startsWith('2_'))))">
            <div class="level_add as_link" title="Dodaj stran" @click="showModal({component: 'evalvation/CreateQuestionnairePage'})">+</div>
        </div>

        <div v-if="(q_type == 1 || q_type == 4) && (active_page_id || build_mode)" class="level_item page_item pointer outstand"
            v-bind:class="{ active: 'RU' == active_page_id }"
            @click="changeActivePage('RU')"
        >     
            Pregled realizacije ukrepov in predlogi izboljšav
        </div>

        <div v-if="(q_type == 1 || q_type == 4) && 
            (build_mode && getFacultyInternalPermission.includes(getFacultyId)) || 
            (!build_mode && getFacultyInternalPermission.includes(program_detail.faculty_id) 
            && Object.keys(internal_page_data).length > 0)">
            <br>
            <h3>Interni del obrazca</h3>
    
            <div v-for="page in internal_page_data" 
                :key="page.id"
                class="level_item page_item pointer"
                v-bind:class="{ active: page.id == active_page_id }"
                @click="changeActivePage(page.id)"
            >     
                {{ page.name }} 
            </div>

            <div class="level_add_holder" v-if="build_mode">
                <div class="level_add as_link" title="Dodaj stran" @click="addPageInternal()">+</div>
            </div>
        </div>
    </div>
</template>


<script>
	import { mapActions } from 'vuex';
	
	export default {
		name: 'EvalvationProgramPages',
		components: {

        },
        data: function() {
			return {
			}
        },
        props: {
            build_mode: {
                type: Boolean,
                required: true
            },
            q_type: {
                type: [String, Number],
                required: true
            }
        },
        computed: {
            // Selected questionnaire object for program
			page_data(){
                if(this.$store.getters.evalvation_questionnaire_detail.pages)
                    return this.$store.getters.evalvation_questionnaire_detail.pages;
                else
                    return {};
            },
            // Selected questionnaire object for program
			internal_page_data(){
                if(this.$store.getters.evalvation_internal_questionnaire_detail.pages)
                    return this.$store.getters.evalvation_internal_questionnaire_detail.pages;
                else
                    return {};
            },

            internal_questionnaire_data(){
                return this.$store.getters.evalvation_internal_questionnaire_detail;
            },
            is_internal_questionnaire(){
                return this.active_page_id && this.internal_questionnaire_data.pages && this.internal_questionnaire_data.pages[this.active_page_id];
            },

            active_page_id(){
                return this.$store.getters.active_q_page_id;
            },
            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },
            // Get loged user faculty id
            getFacultyId() {
                return this.$store.getters.getFaculty.id;
            },
            //Get array of faculties that have permission to internal questionnaire
            getFacultyInternalPermission(){
                return this.$store.getters.faculties_internal_permission;
            },

            //get type of internal questionnaire
            get_internal_q_id(){
                return this.q_type == 4 ? '5_'+this.getFacultyId : '3_'+this.getFacultyId;
            },

            program_detail(){
                return this.$store.getters.evalvation_program_detail;
            },
		}, 
        methods: { 		
            ...mapActions([
                'changeActivePageQ',
                'showModal',
                'createNewQuestionnaire'
            ]),
            changeActivePage(id){
                this.changeActivePageQ(id);
                
                //timeout needed because new browsers are so fast that sometimes scrollTo() action fires before browsers default scroll to html anchor event
                //scroll to top
                //setTimeout(function() {window.scrollTo({top: 0, behavior: 'smooth'});},50);
                //scroll to title
                let element = document.getElementById("evalvation_title");
                setTimeout(function() {element.scrollIntoView({behavior: "smooth"})},150);
                //instant jump to title - works every time but its not smooth
                //location.href = "#evalvation_title";
            },

            addPageInternal(){
                if(this.internal_questionnaire_data.id){
                    //create new page
                    this.showModal({component: 'evalvation/CreateQuestionnairePage', data: {questionnaire_id: this.internal_questionnaire_data.id}});
                }
                //internal questionnaire doesnt exists yet, create it
                else{
                    this.createNewQuestionnaire({type: this.get_internal_q_id, create_page: true});
                }
            }
        }
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
