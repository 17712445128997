<template>
    <div ref="plotlyContainer" class="graph"></div>
</template>

<script>
import Plotly from "plotly.js-dist";
//import debounce from "lodash/debounce";

export default {
    name: "Graph",
    props: {
        graph_data: {
            type: Array,
            required: true,
        },
        graph_layout: {
            type: Object,
            required: true,
        },
        display_mode_bar: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            config: {
                responsive: true, // Ensure plots resize with the window
                displayModeBar: this.display_mode_bar,
                toImageButtonOptions: {
                    format: "svg", // one of png, svg, jpeg, webp
                    filename: this.graph_layout.title || "plot", // Use a default title if not provided
                    height: 700,
                    width: 1400,
                    scale: 1, // Multiply title/legend/axis/canvas sizes by this factor
                },
            },
        };
    },
    computed: {
        graph_layout_checked() {
            if (this.graph_data.length > 0 && this.graph_data[0].x.length > 0) {
                return this.graph_layout;
            } else {
                let layout = {
                    title: this.graph_layout.title,
                    showlegend: false,
                    xaxis: {
                        showticklabels: false,
                    },
                    yaxis: {
                        showticklabels: false,
                    },
                    annotations: [
                        {
                            text: "Ni podatkov",
                            xref: "paper",
                            yref: "paper",
                            showarrow: false,
                            font: {
                                size: 28,
                            },
                        },
                    ],
                    height: this.graph_layout.height, // Example height in pixels
                    width: this.graph_layout.width-100, // Example width in pixels
                };

                // Add margin if exists
                if (this.graph_layout.margin) layout.margin = this.graph_layout.margin;
                // Add height if exists
                if (this.graph_layout.height) layout.height = this.graph_layout.height;

                return layout;
            }
        },
    },
    mounted() {
        this.initPlot();
        //window.addEventListener("resize", this.debouncedResize);
    },
    /* beforeDestroy() {
        window.removeEventListener("resize", this.debouncedResize);
        this.destroyPlot();
    }, */
    methods: {
        initPlot() {
            const element = this.$refs.plotlyContainer;
            if (element) {
                // Assuming graph_data is an array of trace objects
                const customizedData = this.graph_data.map((trace) => ({
                    ...trace,
                    hoverinfo: "y", // Show only the y value on hover
                }));

                Plotly.newPlot(element, customizedData, this.graph_layout_checked, this.config);
            }
        },
        destroyPlot() {
            const element = this.$refs.plotlyContainer;
            if (element) {
                Plotly.purge(element);
            }
        },
        /* onResize() {
            const element = this.$refs.plotlyContainer;
            if (element && this.isElementVisible(element)) {
                Plotly.Plots.resize(element).catch(() => {
                    // Suppress resize errors
                });
            }
        },
        isElementVisible(el) {
            return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
        },
        debouncedResize: debounce(function () {
            this.onResize();
        }, 100), */
    },
    watch: {
        graph_data: "initPlot",
        graph_layout: "initPlot",
    },
};
</script>

<style lang="scss" scoped></style>
