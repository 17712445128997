<template>
    <div>
        <Graph
            :graph_data="generate_line_graph_data()"
            :graph_layout="graph_layout()" :display_mode_bar="false">
        </Graph>
    </div>
</template>


<script>
import { mapActions } from 'vuex';
import Graph from "@/components/general/Graph.vue";

export default {
    name: 'DashboardGraphData',
    components: {
        Graph,
    },
    props: {
        description: {
            type: String,
            required: true
        },
        dataset: {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {

        }
    },
    computed: {

    },

    methods: {
        ...mapActions([
        ]),

        graph_layout() {
            if(this.dataset.fixed_range) {
                return this.graph_layout_normal();
            } else {
                return this.graph_layout_no_range();
            }
        },

        graph_layout_normal() {
            return {
                title: {
                    text: this.insertNewline(this.description, 70),
                    font: {
                        size: 12
                    },
                },
                showlegend: true,
                margin: {
                    l: 50,
                    r: 50
                },
                xaxis: {
                    type: 'category'
                },
                yaxis: {
                    range: [1, 5.4],
                    dtick: 1,
                }
            };
        },

        graph_layout_no_range() {
            return {
                title: {
                    text: this.insertNewline(this.description, 70),
                    font: {
                        size: 12
                    },
                },
                showlegend: true,
                margin: {
                    l: 50,
                    r: 50
                },
                xaxis: {
                    type: 'category'
                },
                yaxis: {
                    range: [this.dataset.min_value, this.dataset.max_value],
                }
            };
        },

        generate_line_graph_data() {
            return this.dataset.data;
        },

        insertNewline(str, maxChars) {
            if (typeof str !== 'string' || typeof maxChars !== 'number') {
                console.error("Invalid input types in function insertNewline()");
                return str;
            }

            let result = '';
            let remaining = str;

            while (remaining.length > maxChars) {
                let index = -1;
                for (let i = maxChars - 1; i >= 0; i--) {
                    if (remaining[i] === ' ') {
                        index = i;
                        break;
                    }
                }

                if (index !== -1) {
                    const beforeSpace = remaining.substring(0, index);
                    result += beforeSpace + '<br>';
                    remaining = remaining.substring(index + 1);
                } else {
                    result += remaining.substring(0, maxChars) + '<br>';
                    remaining = remaining.substring(maxChars);
                }
            }

            result += remaining;
            return result;
        },
    }
}
</script>


<style lang="scss" scoped></style>
