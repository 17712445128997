<template>
    <div class="section_detail_subsection_holder">

        <div class="subsection_list" id="subsection_list">

            <!-- tempbadworksections1 v-show -->
            <div v-for="(subsection, key) in subsections_data" :key="subsection.id" class="subsection_tab pointer"
                v-bind:class="{ active: section.ukrepi == '1' ? key == active_subsection_id : subsection.id == active_subsection_id }"
                @click="changeActiveSubsection(subsection.id ? subsection.id : key)"
                v-show="subsection.id == 73 ? (getFacultyID == 7 ? true : false) : true">
                <!-- use key if united measure as name -->
                {{ subsection.name ? subsection.name : key }}
            </div>

            <div class="subsection_add_holder" v-if="section.ukrepi != '1' && getUserType == 2">
                <div class="subsection_add as_link" @click="showModal({ component: 'sections/CreateSubsection' })">+</div>
            </div>

        </div>

        <div class="subsection_upper_holder">
            <div v-if="subsections_data[active_subsection_id] && section.ukrepi != '1'" class="subsection_description">
                <p v-html="subsections_data[active_subsection_id].description"></p>

                <div class="button_holder" v-if="section.ukrepi != '1' && getUserType == 2">
                    <div class="subsection_delete button gray" @click="showModal({ component: 'sections/DeleteSubsection' })">
                        Izbriši
                    </div>
                    <div class="subsection_edit button gray" @click="showModal({ component: 'sections/EditSubsection' })">
                        Uredi
                    </div>
                </div>
            </div>

            <div v-if="subsections_data[active_subsection_id] && subsections_data[active_subsection_id].evalvation_exports == 1" class="subsection_right_actions">
                <div class="file_exports">
                    <div
                        class="as_link icon" 
                        title="Izvoz Excel realizacij ukrepov in predlogov izboljšav programov" 
                        @click="exportQuestionnaireLevelAllPrograms({
                            format: 'xls',
                            level_id: 'RU',
                            faculty_id: getFacultyID
                        })
                    ">
                        <font-awesome-icon :icon="['far', 'file-excel']" /><span class="export_text">Predlogi izboljšav programov</span>
                    </div>
                </div>
            </div>
        </div>        

        <!-- tempbadworksections1 -->
        <!-- <div class="image_content_work_plan" v-if="subsections_data[active_subsection_id] &&
            active_subsection_id > 66 && active_subsection_id < 72">
            <br>
            <br>

            <p>Tukaj se nahajajo podatki, ki ste jih vnesli v letni program dela za 2021</p>
            <a class="as_link icon export_item" title="Letni program dela 2021/2022"
                :href="'/static/img/work_plan_indicators_data/' + getFacultyAcronym + '_2021.xlsx'">
                <font-awesome-icon :icon="['far', 'file-excel']" /> Letni program dela 2021
            </a>
        </div> -->

        <div v-if="subsections_data[active_subsection_id] && getTabCode == 1 && section.ukrepi == 0 && year == '2020/2021'" class="table_content_work_plan">
            <ul-sections-detail-table-static-cilji></ul-sections-detail-table-static-cilji>
        </div>

        <div v-if="subsections_data[active_subsection_id] && getTabCode == 1 && section.ukrepi == 0 && active_subsection_id != 55 && year == '2020/2021'"
            class="table_content_work_plan">
            <ul-sections-detail-table-static-data :file_name="getFacultyAcronym + '_1'">
            </ul-sections-detail-table-static-data>
        </div>

        <div v-if="subsections_data[active_subsection_id] && getUserType == 2 && section.ukrepi == 0" class="table_content_work_plan">
            <ul-sections-detail-info-table-admin></ul-sections-detail-info-table-admin>
        </div>

        <div v-if="subsections_data[active_subsection_id] && getUserType != 2 && is_info_table" class="table_content_work_plan">
            <ul-sections-detail-info-table></ul-sections-detail-info-table>
        </div>

        <div v-if="subsections_data[active_subsection_id] && subsections_relation_info_table_data[active_subsection_id] && section.ukrepi == 0" class="table_content_work_plan">
            <ul-sections-detail-relation-info-table></ul-sections-detail-relation-info-table>
        </div>

    </div>
</template>


<script>
import router from '../../router/router'
import { mapActions } from 'vuex';
import SectionsDetailTableStaticData from './SectionsDetailTableStaticData.vue';
import SectionsDetailTableStaticCilji from './SectionsDetailTableStaticCilji.vue';
import SectionsDetailRelationInfoTables from './SectionsDetailRelationInfoTables.vue';
import SectionsDetailInfoTableAdmin from './SectionsDetailInfoTableAdmin.vue';
import SectionsDetailInfoTable from './SectionsDetailInfoTable.vue';


export default {
    name: 'SectionsDetailSubsection',
    components: {
        'ul-sections-detail-table-static-data': SectionsDetailTableStaticData,
        'ul-sections-detail-table-static-cilji': SectionsDetailTableStaticCilji,
        'ul-sections-detail-relation-info-table': SectionsDetailRelationInfoTables,
        'ul-sections-detail-info-table-admin': SectionsDetailInfoTableAdmin,
        'ul-sections-detail-info-table': SectionsDetailInfoTable,
    },
    data: function () {
        return {

        }
    },
    computed: {
        year() {
            return this.$store.getters.year;
        },

        // Selected subsection table info
        is_info_table() {
            return (this.$store.getters.subsection_data.info_table && 
                this.$store.getters.subsection_data.info_table.columns && 
                Object.keys(this.$store.getters.subsection_data.info_table.columns).length > 0);
        },

        subsections_relation_info_table_data() {
            return this.$store.getters.subsections_relation_info_table_data;
        },

        // Selected section object for level
        subsections_data() {
            let level = this.$store.getters.section_level;
            let subsections = this.section.ukrepi == '1' ? this.$store.getters.subsections_data : this.$store.getters.subsections_data[level];

            // Return subsections for section and level
            return subsections ? subsections : {};
        },
        active_subsection_id() {
            return this.$store.getters.active_subsection_id;
        },
        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        // Get loged user faculty id
        getFacultyID() {
            return this.$store.getters.getFaculty.id;
        },

        // Get loged user faculty id
        getFacultyAcronym() {
            return this.$store.getters.getFaculty.acronym;
        },

        // Selected section object
        section(){
            // Get section id from url
            let section_id = this.$route.params.section_id;

            // Return section object
            if(this.$store.getters.sections[section_id])
                return this.$store.getters.sections[section_id];
            else return {};
        },

        getTabCode() {
            if (router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela')
                return 1;
            return 0;
        },
    },
    methods: {
        ...mapActions([
            'changeActiveSubsection',
            'showModal',
            'exportQuestionnaireLevelAllPrograms'
        ]),
    },
}
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

/* Transicije */
.fade-enter-active {
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
