<template>
	<transition name="fadeHeight">
		<div class="modal_holder modal_working" v-if="visible">
			<div class="modal_overlay"></div>
			
			<div class="modal_content">
				<img :src="getImgUrl()">
			</div>
			
		</div>
	</transition>
</template>


<script>
	import Vue from 'vue';
	import { mapState } from 'vuex';
	import { mapActions } from 'vuex';

	export default {
		name: 'ModalWorking',
		data: function() {
			return {	

			}
		},
		methods: {
			getImgUrl() {
                return require('../../assets/img/preloader.gif');
            }
		},
		computed: {
			visible(){
				return this.$store.getters.getModalWorkingVisible;
			}
		},
		created() {
			document.onkeydown = evt => {
				evt = evt || window.event;
			};
		}
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
	
	/* Transicije */
	.fadeHeight-enter-active,
	.fadeHeight-leave-active {
	  	transition: all 0.3s;
	}
	.fadeHeight-enter,
	.fadeHeight-leave-to{
	  	opacity: 0;
	}
</style>
