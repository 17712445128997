<template>
    <FAQ :tab="2"></FAQ>
</template>


<script>
import FAQ from '../users/FAQ.vue';

export default {
    name: "FAQEvalvation",
    components: {
        FAQ,
    },
    data: function () {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
