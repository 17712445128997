export const showModal = (state, payload) => {
    state.modalVisible = true;
    state.modalComponent = payload.component;
    state.modalData = payload.data;
};

export const hideModal = (state, payload) => {
    state.modalVisible = false;
};

export const showModalWorking = (state, payload) => {
    state.modalWorkingVisible = true;
};

export const hideModalWorking = (state, payload) => {
    state.modalWorkingVisible = false;
};

// Set token
export const setToken = (state, payload) => {
    state.token = payload;
};

// Login user
export const setAuthData = (state, payload) => {

    state.user = payload.data.user;

    // If faculty is already set in local storage get from there
    let faculty = JSON.parse(localStorage.getItem('faculty'));
    if (faculty !== null) {
        state.faculty = faculty;
    }
    else {
        state.faculty = payload.data.faculty;
    }

    // If year is already set in local storage get from there
    let year = localStorage.getItem('year');
    if (year !== null) {
        state.year = year;
    }

    // Prolong expiration date
    let expirationDate = new Date(new Date().getTime() + state.expirationTime).getTime();
    localStorage.setItem('expirationDate', expirationDate);

    state.isAuthenticated = true;
};

// Logout user
export const clearAuthData = (state) => {

    state.user = {};
    state.faculty = {};
    state.faculies = {};
    state.sorted_faculties_by_acronym = {};

    state.isAuthenticated = false;
    state.token = '';

    state.year = '',

    // Clear local storage
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('faculty');
    localStorage.removeItem('year');
    localStorage.removeItem('expirationDate');
};

// Set Version
export const prepareVersion = (state, payload) => {
    state.version = payload.data.value;
};


// Set faculty
export const setFaculty = (state, payload) => {
    state.faculty = payload.data;
};

// Change faculty
export const changeFaculty = (state, faculty_id) => {
    state.faculty = state.faculties[faculty_id];

    // Save faculty id to local storage
    localStorage.setItem('faculty', JSON.stringify(state.faculties[faculty_id]));
};

// Get all faculties
export const prepareFaculties = (state, payload) => {
    state.faculties = payload.data;

    var sorted_faculties_by_acronym = [];
    for (var fid in payload.data) {
        sorted_faculties_by_acronym.push(payload.data[fid]);
    }

    sorted_faculties_by_acronym.sort(function (a, b) {
        return a.acronym > b.acronym ? 1 : -1;
    });
    state.sorted_faculties_by_acronym = sorted_faculties_by_acronym;
};

export const changeYear = (state, payload) => {
    state.year = payload;

    // Save year to local storage
    localStorage.setItem('year', state.year);
};
