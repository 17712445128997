<template>
	<div class="content sections_dashboard">

        <h2>Dashboard področij v letu {{ year }}</h2>

		<div class="dashboard_tools">
			<div class="view_by">
				<ul>
					<li :class="{active: view_by == 0}" @click="view_by = 0"><span class="item_text">Po fakultetah</span></li>			
					<li :class="{active: view_by == 1}" @click="view_by = 1"><span class="item_text">Po področjih</span></li>
				</ul>
			</div>

			<div class="dashboard_tools_top">
				<div class="dashboard_search_bar">
					<!-- Dashboard Search bar -->
					<input type="text" v-model="search" placeholder="Išči" />
				</div>

				<div class="dashboard_list_toggle">
					<span :class="{active: list_view == 0}"><font-awesome-icon :icon="['fas', 'th-list']" title="Seznam" @click="list_view = 0" /></span>
					<span :class="{active: list_view == 1}"><font-awesome-icon :icon="['fas', 'table']" title="Tabela" @click="list_view = 1" /></span>
				</div>
			</div>

			<div class="dashboard_tools_stamp_filter">
				<div class="filled" :class="{deadline_status_empty: !stamp_filter_active.filled, deadline_status: stamp_filter_active.filled}" 
					@click="stamp_filter_active.filled = !stamp_filter_active.filled">oddano</div>
				<div class="preparation" :class="{deadline_status_empty: !stamp_filter_active.preparation, deadline_status: stamp_filter_active.preparation}" 
					@click="stamp_filter_active.preparation = !stamp_filter_active.preparation">v pripravi</div>
				<div class="preparation_late" :class="{deadline_status_empty: !stamp_filter_active.preparation_late, deadline_status: stamp_filter_active.preparation_late}" 
					@click="stamp_filter_active.preparation_late = !stamp_filter_active.preparation_late">v pripravi</div>
				<div class="after" :class="{deadline_status_empty: !stamp_filter_active.after, deadline_status: stamp_filter_active.after}" 
					@click="stamp_filter_active.after = !stamp_filter_active.after">zamuda</div>
				<div class="after_late" :class="{deadline_status_empty: !stamp_filter_active.after_late, deadline_status: stamp_filter_active.after_late}" 
					@click="stamp_filter_active.after_late = !stamp_filter_active.after_late">zamujeno</div>
			</div>	
		</div>

        <div v-show="list_view == 0 && view_by == 0" class="dashboard_list">
			<faculty-list-item 
				:key="dash_faculty.id"
				v-for="dash_faculty in filteredRowByFaculty"
				:faculty_data="dash_faculty">
			</faculty-list-item>
		</div>

		<div v-show="list_view == 1 && view_by == 0" class="dashboard_square_list">
			<faculty-square-item 
				:key="dash_faculty.id"
				v-for="dash_faculty in filteredRowByFaculty"
				:faculty_data="dash_faculty">
			</faculty-square-item>
		</div>

		<div v-show="list_view == 0 && view_by == 1" class="dashboard_list">
			<section-list-item 
				:key="dash_section.section_id"
				v-for="dash_section in filteredRowBySection"
				:section_data="section_data[dash_section.section_id]"
				:faculty_data="faculty_data"
				:list_data="dash_section">
			</section-list-item>
		</div>

		<div v-show="list_view == 1 && view_by == 1" class="dashboard_square_list">
			<section-square-item 
				:key="dash_section.section_id"
				v-for="dash_section in filteredRowBySection"
				:section_data="section_data[dash_section.section_id]"
				:faculty_count="dash_section.faculty_count"
				:faculty_submitted_count="dash_section.faculty_submitted_count">
			</section-square-item>
		</div>
	</div>
</template>


<script>
import FacultyListItem from './SectionsDashboardFacultyListItem.vue';
import FacultySquareItem from './SectionsDashboardFacultySquareItem.vue';
import SectionListItem from './SectionsDashboardSectionListItem.vue';
import SectionSquareItem from './SectionsDashboardSectionSquareItem.vue';
import router from '../../router/router';
import { mapActions } from 'vuex';

export default {
	name: 'SectionsDashboard',
	components: {
		'faculty-list-item': FacultyListItem,
		'faculty-square-item': FacultySquareItem,
		'section-list-item': SectionListItem,
		'section-square-item': SectionSquareItem
	},
	data: function() {
		return {
			search: "",
			list_view: 1,
			view_by: 0,
			faculty_data: {},
			section_data: {},
			stamp_filter_active: {
				filled: false,
				preparation: false,
				preparation_late: false,
				after: false,
				after_late: false
			}
		}
	},
	computed: {
		year(){
			if(router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela'){
				let year = this.$store.getters.year;
				return parseInt(year.split("/").pop())+1;
			}
			return this.$store.getters.year;
		},

		dashboard_data_by_faculty(){
			let data = this.$store.getters.dashboard_list_data.by_faculty ? this.$store.getters.dashboard_list_data.by_faculty : {};
			return Object.values(data);
		},

		filteredRowByFaculty: function() {
			let farr = this.dashboard_data_by_faculty.filter(data => {
				let is_stamp = true;
				//Stamps filter
				if(!(Object.values(this.stamp_filter_active).every(Boolean) || (this.stamp_filter_active.filled === false && 
						this.stamp_filter_active.preparation === false && this.stamp_filter_active.preparation_late === false && 
						this.stamp_filter_active.after === false && this.stamp_filter_active.after_late === false))){

					let stamp_filter_arr = [];
					for(let key in this.stamp_filter_active){
						if(this.stamp_filter_active[key])
							stamp_filter_arr.push('status_'+key);
					}
					
					if(!stamp_filter_arr.includes(data.faculty_data.last_status))
						is_stamp = false;
				}

				//make string of all names of sections for this faculty
				let sections_names = "";
				for(let key in data['sections']){
					sections_names += ' ' + data['sections'][key]['name'];
				}

				//filter
				let filter_string = data['faculty_data']['acronym']+' '+data['faculty_data']['name']
					+' '+sections_names;

				if (filter_string.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && is_stamp) {
					return true;
				}
				return false;
			});

			return farr.sort((a, b) => (a.faculty_data.acronym > b.faculty_data.acronym) ? 1 : -1);
		},

		dashboard_data_by_section(){
			//json parsing needed so further deletion of properties do not delete it in original too
			let data = this.$store.getters.dashboard_list_data.by_section ? 
				JSON.parse(JSON.stringify(this.$store.getters.dashboard_list_data.by_section)) : {};
				
			//delete faculty data for filter
			if(Object.keys(data).length !== 0){
				this.faculty_data = this.$store.getters.dashboard_list_data.by_section.faculty_data;
				this.section_data = this.$store.getters.dashboard_list_data.by_section.section_data;
				delete data.faculty_data;
				delete data.section_data;
			}

			return Object.values(data);
		},

		filteredRowBySection: function() {
			let sarr = this.dashboard_data_by_section.filter(data => {
				let sectionData = this.$store.getters.dashboard_list_data.by_section.section_data[data.section_id];
				let is_stamp = true;
				//Stamps filter
				if(!(Object.values(this.stamp_filter_active).every(Boolean) || (this.stamp_filter_active.filled === false && 
						this.stamp_filter_active.preparation === false && this.stamp_filter_active.preparation_late === false && 
						this.stamp_filter_active.after === false && this.stamp_filter_active.after_late === false))){

					let stamp_filter_arr = [];
					for(let key in this.stamp_filter_active){
						if(this.stamp_filter_active[key])
							stamp_filter_arr.push('status_'+key);
					}
					
					if(!stamp_filter_arr.includes(sectionData.last_status))
						is_stamp = false;
				}

				//make string of all names of sections for this faculty
				let faculties_names = "";
				for(let key in data['faculty']){
					faculties_names += ' ' + this.faculty_data[key]['name'] +  ' ' + this.faculty_data[key]['acronym'];
				}

				//filter
				let filter_string = sectionData['name']+' '+faculties_names;

				if (filter_string.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && is_stamp) {
					return true;
				}
				return false;
			});

			return sarr.sort((a, b) => (parseInt(a.section_order_number) > parseInt(b.section_order_number)) ? 1 : -1);
		},
	},
	methods: { 		
		...mapActions([
			'getDashboard'
		]),
	},
	created(){
		this.getDashboard();
	},
}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>
