<template>
    <div class="content section_detail_holder sections_list_holder" v-if="getUserType == 1 || getUserType == 2">

        <div class="sections_upper_holder">
            <!-- Evalvation title -->
            <div>
                <div class="evalvation_title" id="evalvation_title">
                    Samoevalvacijski obrazec za oddelke za leto {{year}}
                </div>
                <div class="evalvation_title_second">
                    {{active_page_name}}
                </div>
            </div>
        
            <!-- Exports icons -->
            <div class="file_exports">
                <!--<div class="as_link icon right_margin" title="Izvoz PDF" @click="/*deleteAttachmentConfirm(attachment.id)*/">
                    <font-awesome-icon :icon="['far', 'file-pdf']" />
                </div>-->
                <div class="as_link icon" title="Izvoz Word" @click="exportQuestionnaire({questionnaire_id: questionnaire_details.id, format: 'doc'})">
                    <font-awesome-icon :icon="['far', 'file-word']" />
                </div>
            </div>
        </div>
        <br>

        <div v-if="questionnaire_details" class="sections_intro edit">
            <div class="sections_intro_text" v-if="get_questionnaire_intro && get_questionnaire_intro !== ''" v-html="get_questionnaire_intro"></div>
            <div class="sections_intro_text" v-else><i>Vnesite uvod/navodila</i></div>

            <div class="button_holder">
                <div class="subsection_edit button" @click="showModal({component: 'evalvation/EditQuestionnaireIntro'})">
                    Uredi
                </div>
            </div>
        </div>

        <!-- Izpis roka za oddajo (datuma) znotraj področja, v kolikor ga je administrator nastavil
        <p class="deadline" :class="getDeadlineClass(section.deadline)" v-if="section.deadline !== '0000-00-00'"> Rok za oddajo: {{getDeadlineDate(section.deadline)}}</p> -->

        <div v-if="questionnaire_details" class="section_detail_content">

            <!-- Left menu - pages -->
            <ul-questionnaire-pages :build_mode="true" :q_type="getQuestionnaireType"></ul-questionnaire-pages>

            <div class="right">
                <!-- Questionnaire details -->
                <ul-questionnaire-detail :build_mode="true" :q_type="getQuestionnaireType"></ul-questionnaire-detail>

                <!-- Questionnaire questions -->
                <ul-questionnaire-questions :build_mode="true" :q_type="getQuestionnaireType"></ul-questionnaire-questions>
            </div>
        </div>

        <ul-new-questionnaire v-if="!questionnaire_details" :q_type="getQuestionnaireType"></ul-new-questionnaire>
    </div>
</template>


<script>
    import { mapActions } from 'vuex';
    import QuestionnaireBuildDetail from './EvalvationQuestionnaireBuildDetail.vue';
    import QuestionnairePageQuestions from './EvalvationQuestionnairePageQuestions.vue';
    import ProgramPages from './EvalvationProgramPages.vue';
    import NewQuestionnaire from './EvalvationNewQuestionnaire.vue';
     // For formating dates (European standards)
    import moment from 'moment'
    
	export default {
		name: 'EvalvationDepartmentQuestionnaireBuild',
		components: {
            'ul-questionnaire-detail': QuestionnaireBuildDetail,
            'ul-questionnaire-questions': QuestionnairePageQuestions,
            'ul-questionnaire-pages': ProgramPages,
            'ul-new-questionnaire': NewQuestionnaire,
        },
        data: function() {
			return {
			}
        },
        watch: {
            year(newVal, oldVal) {
                this.getQuestionnaireDetails({type: '2_'+this.getFacultyId});
            },

            is_department_data(newVal, oldVal) {
                if (newVal !== oldVal)
                    this.$router.push({ name: 'evalvationPrograms' });
            }
        },
        computed: {
            year(){
				return this.$store.getters.year;
            },

            is_department_data() {
                //admin could have all departments from all faculties fetched in users list
                if(this.getUserType == 2 || this.getUserType == 3){
                    for(let department of Object.values(this.$store.getters.evalvation_departments)){
                        if(department.faculty_id == this.getFacultyId)
                            return true
                    }
                    return false;
                }

                return Object.keys(this.$store.getters.evalvation_departments).length > 0;
            },

            // Get loged user faculty id
            getFacultyId() {
                return this.$store.getters.getFaculty.id;
            },

            getQuestionnaireType(){
                return '2_'+this.getFacultyId;
            },

            // Get details of questionnaire
			questionnaire_details(){
                if(this.$store.getters.evalvation_questionnaire_detail.id){
                    return this.$store.getters.evalvation_questionnaire_detail;
                }
                return null;
            },

            // Get name of active page
			active_page_name(){
                if(this.active_q_page_id == "RU")
                    return "Pregled realizacije ukrepov in predlogi izboljšav";
                else if(this.active_q_page_id && this.$store.getters.evalvation_questionnaire_detail.pages)
                    return this.$store.getters.evalvation_questionnaire_detail.pages[this.active_q_page_id].name;
            },

            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },

            //id of active page
            active_page_id(){
                return this.$store.getters.active_q_page_id;
            },

            // Get questionnaire intro
			get_questionnaire_intro(){
                return this.$store.getters.evalvation_questionnaire_detail.intro;
            },
		}, 
        methods: { 		
            ...mapActions([
                'getQuestionnaireDetails',
                'showModal',
                'exportQuestionnaire'
            ]),
        },
        created(){
            //All edits here have to be edited in actions.js in changeFaculty() too!
            this.getQuestionnaireDetails({type: '2_'+this.getFacultyId});
        }
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
