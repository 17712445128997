<template>
    <div v-if="getUserType == 2 || (subsectionStructure.columns && Object.keys(subsectionStructure.columns).length)" class="subsection_table_holder">
        <div class="subsection_table_tools">
            <div class="table_style">
                <input 
                    type="checkbox" 
                    name="table_style" 
                    id="table_style" 
                    v-model="table_style"
                >
                <label for="table_style" style="cursor: pointer;">Alternativni pogled</label>
            </div>

            <!-- Button for copying table data in other facutlies if full width (when levels are not visible - only one level) -->
            <div v-if="getUserType < 3 && active_subsection_id != null && subsectionStructure.columns && 
                Object.keys(subsectionStructure.columns).length && !tableHasOnlyFilesOrEmpty /* && active_subsection_data.predefined == 0 */ &&
                ((!section_submitted && deadline_not_closed) || getUserType == 2)" class="button_holder">
                <div class="button gray" @click="showModal({component: 'sections/ImportDataSubsectionTable'})">
                    Uvoz podatkov v tabelo
                </div>
            </div>
        </div>

        <!-- Table -->
        <div class="subsection_table_table" :class="{alternative: table_style}">

            <!-- Table header row -->
            <div class="row header">
                <!-- Table columns -->
                <div class="column column_header" :class="{highlighted: column.highlighted==1}" 
                        v-for="column in subsectionStructure.columns" :key="column.id">

                    <span class="text">
                        {{ column.name }}
                    </span>

                    <span class="description">
                        {{ column.description }}
                    </span>

                    <div class="row_header multiple_columns">
                        <div class="row_header_item_group wrap">
                            <font-awesome-icon v-if="column.mandatory > 0" class="icon" :icon="['fas', 'exclamation-triangle']" title="Obvezno" />
                            <font-awesome-icon v-if="getUserType == 2 && column.disabled > 0" class="icon" :icon="['fas', 'ban']" title="Onemogočeno urejanje" />
                            <font-awesome-icon v-if="column.personal_data > 0" class="icon" :icon="['fas', 'user-shield']" title="Osebni podatek" />

                            <span class="type row_child_header">
                                ({{ columnTypeText(column.type) }})
                            </span>
                        </div>

                        <div class="row_header_item_group">
                            <div v-if="column.type == 5" class="as_link icon row_child_header" title="Prenesi vse">
                                <font-awesome-icon :icon="['fas', 'download']" @click="exportFilesColumn({column_id: column.id})"/>
                            </div>

                            <div v-if="column.type == 5 && getUserType == 2" class="as_link icon row_child_header zip" title="Prenesi vse (vse članice)">
                                <font-awesome-icon :icon="['fas', 'download']" @click="exportFilesColumn({column_id: column.id, all:true})"/>
                            </div>

                            <div class="as_link icon edit row_child_header" title="Uredi stolpec"  v-if="getUserType == 2">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'sections/EditColumnSectionDetailTable', 
                                    data:{name:column.name, description:column.description, column_id:column.id, order_number:column.order_number, 
                                    mandatory:column.mandatory, type:column.type, disabled_column:column.disabled, highlighted:column.highlighted, personal_data:column.personal_data}})" />
                            </div>

                            <div class="as_link icon remove row_child_header" title="Pobriši stolpec"  v-if="getUserType == 2">
                                <font-awesome-icon :icon="['far', 'trash-alt']" @click="showModal({component: 'sections/DeleteColumnSectionDetailTable', data:{column_id:column.id}})" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Add column -->
                <div class="column add-column" title="Dodaj stolpec" v-if="getUserType == 2" @click="showModal({component: 'sections/CreateColumnDetailTable'})">
                    <div class="as_link icon">
                        <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj stolpec
                    </div>
                </div>

                <!-- Tools table columns -->
                <div class="column tools" v-if="getUserType != 3 && (((!section_submitted && deadline_not_closed) || getUserType > 1) && 
                    !hide_editing_worksection_temp && (active_subsection_data.predefined == 0 || getUserType > 1))">
                </div>
            </div>

            <!-- Table data rows -->
            <div class="row data" :class="{highlight_row: check_highlight_row(key)}" v-for="(row, key) in subsectionData.rows" :key="key">    
                <!-- Table columns -->
                <div class="column" :class="{highlighted: column.highlighted==1}" v-for="column in subsectionStructure.columns" :key="column.id">
                    <div v-if="column.type == 1">
                        <textarea rows="1" :value="data[key][column.id]" 
                            :disabled="getUserType == 3 || ((getUserType != 2 && column.disabled == 1) || !((!section_submitted && deadline_not_closed) || getUserType == 2) || special_column_worksection_temp(column.id))" 
                            @change="cellChanged(key, column.id, column.mandatory, $event)">
                   </textarea>
                    </div>

                    <div v-if="column.type == 2">
                        <input type="text" class="input_number" :value="data[key][column.id]" 
                            :disabled="getUserType == 3 || ((getUserType != 2 && column.disabled == 1) || !((!section_submitted && deadline_not_closed) || getUserType == 2))" 
                            @change="cellChanged(key, column.id, column.mandatory, $event)" @keypress="isNumber($event)" 
                            @paste="isNumberPaste($event, key, column.id, column.mandatory)">
                    </div>

                    <div v-if="column.type == 3">
                        <select :value="data[key][column.id]" :disabled="getUserType == 3 || ((getUserType != 2 && column.disabled == 1) || !((!section_submitted && deadline_not_closed) || getUserType == 2))" 
                            @change="cellChanged(key, column.id, column.mandatory, $event)">
                            <option>DA</option>
                            <option>NE</option>
                            <option>/</option>
                        </select>
                    </div>

                    <div v-if="column.type == 4">
                        <textarea rows="4" :value="data[key][column.id]" :disabled="getUserType == 3 || ((getUserType != 2 && column.disabled == 1) || !((!section_submitted && deadline_not_closed) || getUserType == 2))" 
                                @change="cellChanged(key, column.id, column.mandatory, $event)"></textarea>
                    </div>

                    <div v-if="column.type == 5">
                        <div class="button disabled" title="Naloži datoteko" v-if="!data[key][column.id] && (getUserType == 3 || (((getUserType != 2 && column.disabled == 1) || !((!section_submitted && deadline_not_closed) || getUserType == 2))))">
                            Naloži
                        </div>

                        <div class="button gray" title="Naloži datoteko" v-else-if="!data[key][column.id]" @click="showModal({component: 'sections/EditSectionDetailTableAttachments', 
                            data:{row_id:key, col_id:column.id, mandatory:column.mandatory}})" >
                            Naloži
                        </div>

                        <div class="upload_data" v-if="data[key][column.id] !== undefined && data[key][column.id] !== ''">
                            <div v-if="getUserType != 3 && (getUserType == 2 || (column.disabled == 0 && (!section_submitted && deadline_not_closed)))"
                                class="as_link upload_name gray floatLeft" title="Uredi priponko" 
                                @click="showModal({component: 'sections/EditSectionDetailTableAttachments', 
                                data:{file_data:data[key][column.id], row_id:key, col_id:column.id, mandatory:column.mandatory}})">
                                <font-awesome-icon class="font-inherit mr5 center-vertical" :icon="['fas', 'pencil-alt']" />
                                {{data[key][column.id].name}}
                            </div>
                            <div class="as_link icon left_margin floatLeft" title="Shrani priponko">
                                <a v-bind:href="data[key][column.id].file_url" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /></a>
                            </div>
                        </div>
                    </div>

                    <div v-if="column.type == 6">
                        <select :value="data[key][column.id]" :disabled="getUserType == 3 || ((getUserType != 2 && column.disabled == 1) || !((!section_submitted && deadline_not_closed) || getUserType == 2))" 
                                @change="cellChanged(key, column.id, column.mandatory, $event)">
                            <option v-for="(value, index) in column.dropdown_items" :key="index">{{value}}</option>
                        </select>
                    </div>
                </div>

                <!-- Add column - empty column -->
                <div class="column add-column" v-if="getUserType == 2">
                    {{ (check_highlight_row(key) ? transferred_rows[key].input_year : '') }}
<!-- TODO TEMPORARY !!!!!! -->
                </div>

                <!-- Tools table columns - delete row -->
                <div class="column tools" v-if="getUserType != 3 && (((!section_submitted && deadline_not_closed) || getUserType == 2) && !hide_editing_worksection_temp &&
                        (active_subsection_data.predefined == 0 || getUserType == 2))">
                    <div class="as_link icon" title="Pobriši vrstico" v-if="!isLastRow()">
                        <font-awesome-icon :icon="['far', 'trash-alt']" @click="showModal({component: 'sections/DeleteRowSectionDetailTable', data:{mandatory_columns: num_mandatory_columns, row_id:key}})" />
                    </div>
                </div>
            </div>

            <!-- Table data - add row -->
            <div class="row add-data">
                <div class="as_link icon" title="Dodaj vrstico" @click="addTableRow()"
                    v-if="getUserType != 3 && (subsectionStructure.columns && ((!section_submitted && deadline_not_closed) || getUserType == 2) && !hide_editing_worksection_temp && 
                        (active_subsection_data.predefined == 0 || getUserType == 2))">
                    <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                </div>
            </div>

        </div>
    </div>
</template>


<script>
	import { mapActions } from 'vuex';
	
	export default {
		name: 'SectionsDetailTable',
		components: {

        },
        data: function() {
			return {
                data: {},
                table_style: false,
                num_mandatory_columns: 0,
                num_columns: 0
			}
        },
        props: {
            deadline_not_closed: {
                type: Boolean,
                default: false
            },
            section_submitted: {
                type: Boolean,
                default: false
            }
        },
        computed: {

			// Selected subsection object with structure (table columns, input types...)
			subsectionStructure(){
                let subsectionStructure = this.$store.getters.subsection_data.columns;
                this.num_mandatory_columns = 0;
                this.num_columns = 0;

                //count all columns that are mandatory for this subsection/table
                for(let column in subsectionStructure){
                    this.num_columns ++;
                    this.num_mandatory_columns += parseInt(subsectionStructure[column].mandatory);
                }

                return {columns: subsectionStructure};
            },

            //check if table has only files or is empty
            tableHasOnlyFilesOrEmpty(){
                for(let column of Object.values(this.subsectionStructure.columns)){
                    if(column.type != "5") return false;
                }
                return true;
            },

            // Selected subsection object with data (inserted table data)
			subsectionData(){
                this.data = this.$store.getters.subsection_data.data;
                return {rows: this.data};
            },

            // Get list of transferred rows (last year measures)
			transferred_rows(){
                return this.$store.getters.subsection_data.transferred_rows;
            },

            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },

            active_subsection_id(){
                return this.$store.getters.active_subsection_id;
            },

            //tempbadworksections1
            hide_editing_worksection_temp(){
                if(66 < this.active_subsection_id && this.active_subsection_id < 72 && this.getUserType != 2)
                    return true;
                return false;
            },

            section_level(){
                let section_level = this.$store.getters.section_level;
                return section_level;
            },

            lifelong_learning(){
                let lifelong_learning = this.getSectionStatistics.lifelong_learning;      
                return lifelong_learning;
            },

            getSectionStatistics(){
                let section_id = this.$route.params.section_id;
                return this.$store.getters.sections_statistics[section_id];
            },

            // Selected subsection data
			active_subsection_data(){
                let level = this.$store.getters.section_level;
                let subsections = this.$store.getters.subsections_data[level];
                let active_subsection = this.$store.getters.active_subsection_id;
				return subsections ? subsections[active_subsection] : {};
            },
		}, 
        methods: { 		
            ...mapActions([
                'showModal',
                'addSubsectionTableRow',
                'updateCell',
                'updateProgressBarSimple',
                'exportFilesColumn'
            ]),

            //tempbadworksections1
            special_column_worksection_temp(id_column){
                let disabled_columns_ids = [390, 391, 392, 393, 394, 401, 402, 403, 404, 406, 407, 408, 409, 412, 414];

                if(disabled_columns_ids.includes(parseInt(id_column)))
                    return true
                return false
            },

            addTableRow(){
                this.addSubsectionTableRow({mandatory_columns: this.num_mandatory_columns, all_columns: this.num_columns});
            },
            
            // Get column type text
			columnTypeText(type){

                let text = '';
                if(type == 1)
                    text = 'Besedilo';
                else if(type == 2)
                    text = 'Število';
                else if(type == 3)
                    text = 'Da / Ne';
                else if(type == 4)
                    text = 'Dolgo besedilo';
                else if(type == 5)
                    text = 'Datoteka';
                else if(type == 6)
                    text = 'Roleta';
                else
                    text = 'Drugo';

                return text;
            },

            // When cell is changed
            cellChanged(row_id, column_id, mandatory, event, forced_value=false){
                let newValue = forced_value ? forced_value : event.target.value;
                let oldValue = this.data[row_id][column_id];
                
                if(oldValue == '')
                    this.updateProgressBarSimple({mandatory:mandatory, edit:1});
                else if(newValue == '')
                    this.updateProgressBarSimple({mandatory:mandatory, edit:-1});

                if(this.getSectionStatistics.submitted == '0'){
                    this.updateCell({row_id:row_id, column_id:column_id, value:newValue});
                }

                this.data[row_id][column_id] = newValue;
            },

            // Check if it is the only row in table
            isLastRow(){
                let rowCount = Object.keys(this.subsectionData.rows).length;

                if(rowCount < 2)
                    return true;
                else
                    return false;
            },

            //check if row needs to be highlighted
            check_highlight_row(row_id){
                return (this.transferred_rows ? Object.keys(this.transferred_rows).includes(row_id) : false);
            },

            // Check if input is a number or comma (for number cell)
            isNumber(evt){
                evt = (evt) ? evt : window.event;

                var charCode = (evt.which) ? evt.which : evt.keyCode;

                //if comma, ignore if comma already exist and if string is empty
                if(charCode == 44 && (evt.target.value.includes(',') || evt.target.value == ''))
                    evt.preventDefault();
                //some basic keys (delete, backspace,... + numbers + comma for decimal)
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44)
                    evt.preventDefault();
                else
                    return true;
            },

            // Check if input is a number or comma (for number cell)
            isNumberPaste(evt, row_id, column_id, mandatory){
                //get paste value
                let paste = (evt.clipboardData || window.clipboardData).getData('text');
                let result = paste;

                evt = (evt) ? evt : window.event;
                evt.preventDefault();

                //let numbers = paste.replace(/[^0-9,]/g, "");

                //if paste contains anithing else than 0-9., cancle it
                if(/[^0-9.,]/g.test(paste) || paste.charAt(0) == ',' || paste.charAt(0) == '.'
                        || paste.substr(-1) == ',' || paste.substr(-1) == '.'){
                    return;
                }
                //paste contains only 0-9,.
                else{
                    //remove all dots
                    if(paste.includes('.')){
                        result = paste.replaceAll('.', '');
                    }
                    //only leave first comma, remove others
                    if((paste.match(/,/g) || []).length > 1){
                        let index = 0;
                        result = paste.replace(/\,/g, (item) => (!index++ ? item : ""));
                    }

                    //force cell change (because of preventdefault(), value in event.target is not changed)
                    this.cellChanged(row_id, column_id, mandatory, evt, result);
                }
            },
        }
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
