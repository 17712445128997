<template>
	<header>
		<div class="header_content">

			<div class="top_line">

				<div class="flex">
					<img id="logo" class="logo" src="@/assets/img/UL_logo-header.png"/>
					<!-- <div class="app_name">Univerza v Ljubljani</div> -->
				</div>

				<div class="environment_text">
					{{ middleText }}
				</div>

				<div class="app_name">
					Aplikacija za poročanje UL
				</div>

			</div>

			<ul-navigation v-if="isAuthenticated"></ul-navigation>
		</div>
	</header>
</template>


<script>
import TheNavigation from './TheNavigation.vue';

export default {
	name: 'Header',
	components: {
		'ul-navigation': TheNavigation
	},
	data: function () {
		return {
		}
	},
	computed: {
		// If user is not logged in hide main menu
		isAuthenticated() {
			return this.$store.getters.isAuthenticated;
		},
		middleText() {
			switch (window.location.hostname) {
				case 'localhost':
					return 'DEVELOPMENT';
				case 'porocanje-test.uni-lj.si':
					return 'TEST';
				default:
					return '';
			}
		},
	},
}
</script>


<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
