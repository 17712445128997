<template>
    <div class="main_holder">
        <div class="content_holder">
            <div class="content">
                <h2>Obvestila</h2>

                <div v-if="getUserType==2"> 
                    <div class="faq_texteditor_content">
                        <button class="as_link icon button" title="Dodaj obvestilo" @click="add()">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj obvestilo
                        </button>
                    </div>

                    <div  class="faq_texteditor_content"
                        v-for="notice in notices"
                        :key="notice.id">
                        {{convertDate(notice.created)}}
                        <vue-editor class="text-editor full_width" :editorToolbar="customToolbar" v-model="notice.text"></vue-editor>
                        <button class="button mr5" @click="edit(notice.text, notice.id)">Shrani</button>
                        <button class="button" @click="showModal({component: 'users/DeleteNotice', data:{id: notice.id}})">Izbriši</button>
                    </div> 
                </div>

                <!-- <div v-else>  -->
                    <div class="notices_text_content"
                        v-for="notice in notices"
                        :key="notice.id">
                        {{convertDate(notice.created)}}
                        <span v-html="notice.text"></span>
                    </div>
                <!-- </div>  -->
            </div>
        </div> 
    </div>
</template>


<script>
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import moment from 'moment';

export default {
    name: "Notices",
    components: {
        VueEditor,
    },
    props: {
        tab: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            customToolbar: [["bold", "italic", "underline", "link"],
					[{ list: "ordered" }, { list: "bullet" }]],
        };
    },
    computed: {
        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        notices() {
            if(this.$store.getters.notices)
                return Object.values(this.$store.getters.notices).reverse();
            else
                return [];
        },
    },
    methods: {
        ...mapActions([
            "addNotice",
            "editNotice",
            "getNotices",
            'showModal',
        ]),

        edit(text, id) {
            this.editNotice({
                id: id,
                text: text
            });
        },

        add() {
            this.addNotice({
                tab: this.tab
            });
        },

        convertDate(date){
            return moment(date, 'YYYY-MM-DD HH:mm:ss').format('D. M. YYYY');
        }

    },
    created() {
        this.getNotices({tab: this.tab});
    },
};
</script>


<style lang="scss" scoped>
</style>
