<template>
    <div>
        <div class="dashboard_faculty_item">
            <div class="dashboard_faculty_item_left">
                <div class="as_link_grey dashboard_faculty_item_name" @click="changeFaculty(faculty_data.faculty_data.id)">
                    {{faculty_data.faculty_data.acronym}} - {{faculty_data.faculty_data.name}}
                </div>
                <br><br>
                <div class="deadline_status" :class="deadlineStatus">{{status_text}}</div>
                <div class="dashboard_faculty_item_progress">
                    Izpolnjeno: {{faculty_data.faculty_fill_percent}}%
                </div>
            </div>

            <div class="dashboard_faculty_item_right">
                <!-- <div v-show="nextDeadlineTimestamp > 0" class="dashboard_faculty_item_deadline">
                    Naslednji rok: {{nextDeadline()}}
                </div> -->
                <div class="dashboard_toggle_section_list" 
                    @click="showSections = !showSections" 
                    :class="{'active': showSections}" 
                    v-if="faculty_data.sections != null"
                >
                    prikaži/skrij področja 
                    <font-awesome-icon v-show="!showSections" :icon="['fas', 'angle-down']" />
                    <font-awesome-icon v-show="showSections" :icon="['fas', 'angle-up']" />
                </div>
            </div>
        </div>
        <transition name="expand" @after-enter="afterEnter" @enter="enter" @leave="leave" >
            <div v-show="showSections" class="dashboard_section_list">
                <div class="dashboard_section_item" v-for="section in filteredRowBySections" :key="section.id">
                    <div class="dashboard_section_item_left">

                        <div class="as_link_grey dashboard_section_name" @click="changeFacultySection({data: {faculty_id: faculty_data.faculty_data.id, section_id: section.id}})"> 
                            {{section.name}}</div>
                        <br>

                        <div v-if="section.submitted == 1" class="deadline_status filled">oddano</div>
                        <div v-else class="deadline_status" :class="deadlineStatusSection(section.deadline, section.deadlineClosed).class">
                            {{deadlineStatusSection(section.deadline, section.deadlineClosed).text}}</div>
                        
                        <!-- <div v-if="section.lifelong_learning == 1" class="lifelong_learning">Vseživljensko učenje</div> -->
                    </div>
                    <div class="dashboard_section_item_right">
                        <div class="dashboard_section_item_deadline">
                            <div v-if="section.submitted == 1">Oddano: {{convertDateTime(section.submit_time)}}</div>
                            <div v-else>
                                Rok: {{convertDate(section.deadline)}}  
                                <br>
                                Zadnji rok: {{convertDate(section.deadlineClosed)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>


<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    name: 'SectionsDashboardFacultyListItem',
	
	data: function() {
		return {
            nextDeadlineTimestamp: 0,
            nextDeadlineClosedTimestamp: 0,
            status_text: '',
            showSections: false,
            nearDeadlineDays: 14
		}
    },
    props:{
        faculty_data: {
            type: Object,
            required: true
        }
    },
	computed: {
        deadlineStatus(){
            let temp_date = new Date();
            let timestamp_now = temp_date.getTime();

            //If all submitted
            if(this.faculty_data.section_submitted_count == this.faculty_data.section_count){
                this.status_text = 'oddano';
                this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_filled'});
                return 'filled';
            }
            //If nearest deadline is already closed
            else if(this.nextDeadlineClosedTimestamp > 0 && timestamp_now > this.nextDeadlineClosedTimestamp){
                this.status_text = 'zamujeno';
                this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_after_late'});
                return 'after_late';
            }
            //If deadline exists
            else if(this.nextDeadlineTimestamp > 0){
                //If deadline is passed
                if(timestamp_now > this.nextDeadlineTimestamp){
                    this.status_text = 'zamuda';
                    this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_after'});
                    return 'after';
                }
                //If we are near deadline (14 days)
                else if(timestamp_now > this.nextDeadlineTimestamp - this.nearDeadlineDays*86400000){
                    this.status_text = 'v pripravi';
                    this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_preparation_late'});
                    return 'preparation_late';
                }
                //In preparation
                else {
                    this.status_text = 'v pripravi';
                    this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_preparation'});
                    return 'preparation';
                }
            }
            //In preparation
            else {
                this.status_text = 'v pripravi';
                this.dashboardAddLastStatusByFaculty({id: this.faculty_data.faculty_data.id, status: 'status_preparation'});
                return 'preparation';
            }
		},

        filteredRowBySections: function() {
			return Object.values(this.faculty_data.sections).sort((a, b) => (parseInt(a.order_number) > parseInt(b.order_number)) ? 1 : -1);
		},
    },
    methods: {
        ...mapActions([
            'changeFaculty',
            'changeFacultySection',
            'dashboardAddLastStatusByFaculty'
        ]),

        nextDeadlineClosed(){
            let sections = this.faculty_data.sections;
            let next_deadline = null;
            if(sections){
                for(let section in sections){
                    let this_date = sections[section].deadlineClosed;
                    if(this_date !== '0000-00-00'){
                        let temp_date = new Date(this_date);
                        if(next_deadline == null || next_deadline.getTime() < temp_date.getTime())
                            next_deadline = temp_date;
                    }
                }
            }
            if(next_deadline)
                this.nextDeadlineClosedTimestamp = next_deadline.getTime();
            else
                this.nextDeadlineClosedTimestamp = 0;
        },

        nextDeadline(){
            let sections = this.faculty_data.sections;
            let next_deadline = null;
            if(sections){
                for(let section in sections){
                    let this_date = sections[section].deadline;
                    if(this_date !== '0000-00-00'){
                        let temp_date = new Date(this_date);
                        if(next_deadline == null || next_deadline.getTime() < temp_date.getTime())
                            next_deadline = temp_date;
                    }
                }
            }

            this.nextDeadlineClosed();

            if(next_deadline){
                this.nextDeadlineTimestamp = next_deadline.getTime();
                return next_deadline.toLocaleDateString("sl-SL");
            }
            else{
                this.nextDeadlineTimestamp = 0;
                return '';
            }
        },

        //Convert date
        convertDate: function (date) {
            if(date == '0000-00-00')
                return 'ni določen';
            return moment(date, 'YYYY-MM-DD').format('D. M. YYYY');
        },
        //Convert date time
        convertDateTime: function (date) {
            if(date == '0000-00-00 00:00:00')
                return 'ni določen';
            return moment(date, 'YYYY-MM-DD HH:mm:ss').format('D. M. YYYY');
        },

        deadlineStatusSection(deadline, deadlineClosed){
            let temp_date = new Date();
            let timestamp_now = temp_date.getTime();

            let deadlineTS = 0;
            if(deadline !== '0000-00-00')
                deadlineTS = new Date(deadline).getTime();

            let deadlineClosedTS = 0;
            if(deadlineClosed !== '0000-00-00')  
                deadlineClosedTS = new Date(deadlineClosed).getTime();

            //If nearest deadline is already closed
            if(deadlineClosedTS > 0 && timestamp_now > deadlineClosedTS){
                return {class: 'after_late', text: 'zamujeno'};
            }
            //If deadline exists
            if(deadlineTS > 0){
                //If deadline is passed
                if(timestamp_now > deadlineTS){
                    return {class: 'after', text: 'zamuda'};
                }
                //If we are near deadline (14 days)
                else if(timestamp_now > deadlineTS - this.nearDeadlineDays*86400000){
                    return {class: 'preparation_late', text: 'v pripravi'};
                }
            }
            //In preparation
            return {class: 'preparation', text: 'v pripravi'};
		},

        //ANIMATIONS
        //Source: https://github.com/maoberlehner/transition-to-height-auto-with-vue
        afterEnter(element) {
            element.style.height = `auto`;
        },
        enter(element) {
            const { width } = getComputedStyle(element);

            element.style.width = width;
            element.style.position = `absolute`;
            element.style.visibility = `hidden`;
            element.style.height = `auto`;

            const { height } = getComputedStyle(element);

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // Force repaint to make sure the animation is triggered correctly.
            getComputedStyle(element).height;
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        leave(element) {
            const { height } = getComputedStyle(element);

            element.style.height = height;
            // Force repaint to make sure the animation is triggered correctly.
            getComputedStyle(element).height;
            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "../../scss/style.scss";

    .expand-enter-active,
    .expand-leave-active {
        transition: height 1s ease-in-out;
        overflow: hidden;
    }
    .expand-enter,
    .expand-leave-to {
        height: 0;
    }
</style>

