<template>
    <div>
        <div class="as_link icon bold" v-if="Object.keys(relation_info_table_data).length > 0" @click="show_table = !show_table">
            <font-awesome-icon v-if="!show_table" :icon="['fas', 'chevron-down']" title="Razširi pogled" />
            <font-awesome-icon v-else :icon="['fas', 'chevron-up']" title="Skrči pogled" />
            Tabela načrtovanih aktivnosti v prejšnjem letu
        </div>

        <transition name="expand" mode="out-in">
            <div v-show="show_table" class="table_static_data" style="margin-top: 10px;">
                <table v-if="relation_info_table_data.detail">
                    <thead>
                        <tr>
                            <th v-for="(column, key) of relation_info_table_data.detail" :key="key"> 
                                {{column.name}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, key) of relation_info_table_data.data" :key="key">
                            <td v-for="(column, key) of row" :key="key"> 
                                {{column}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </transition>
    </div>
</template>


<script>
import router from '../../router/router'
import { mapActions } from 'vuex';
//import axios from 'axios'


export default {
    name: 'SectionsDetailRelationInfoTables',
    components: {
    },
    data: function () {
        return {
            show_table: false,
        }
    },
    computed: {
        relation_info_table_data() {
            return this.$store.getters.subsections_relation_info_table_data[this.active_subsection_id];
        },
        active_subsection_id() {
            return this.$store.getters.active_subsection_id;
        },

    },
    methods: {
        ...mapActions([
        ]),
    },
    created() {
    }
}
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

/* Define the expand animation */
.expand-enter-active{
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.expand-enter{
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}

/* Transicije */
.fade-enter-active {
  transition: opacity 0.3s;
}

.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>