<template>
    	<div class="main_holder login">
		
		<div class="content_holder">

			<div class="login_box">

                <h3>Nastavitev gesla</h3>

                <div class="input_holder">
                    Geslo: <input
                        type="password"
                        maxlength="50"
                        class="default_width" 
                        v-model="password"
                        autocomplete="new-password"
                        @keyup="all_filled = check_all_filled()"
                    />
                    Potrditev gesla: <input
                        type="password"
                        maxlength="50"
                        class="default_width" 
                        v-model="password_conf"
                        autocomplete="new-password"
                        @keyup="all_filled = check_all_filled()"
                    />
                </div>

                <div class="input_holder button_holder">
                    <button class="button" @click="send()" :disabled="!all_filled" :class="{disabled: !all_filled}">Shrani</button>
                </div>
            </div>
            
		</div>
	</div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "userProfile",
    components: {
    },
    data: function () {
        return {
            password_conf: "",
            password: "",
            all_filled: false
        };
    },
    computed: {
        // Get loged user id
        getUserID() {
            return this.$store.getters.getUser.id;
        },
    },
    methods: {
        ...mapActions([
            "showModal",
            "setPassword",
            "logoutUser",
            "checkHash"
        ]),
        //check if all fields are filled
        check_all_filled(){
            if(
                this.password !== "" && 
                this.password_conf !== ""
            )
                return true;
            return false;
        },

        send() {
            if(this.password.length < 6)
                this.showModal({ component: 'ModalWarning', data: { title: "Dolžina gesla", 
                    description: "Geslo mora vsebovati najmanj 6 znakov" } });
            else if(this.password !== this.password_conf)
                this.showModal({ component: 'ModalWarning', data: { title: "Ujemanje gesel", 
                    description: "Gesli v obeh poljih morata biti enaki" } });
            else
                this.setPassword({
                        email: this.$route.params.email,
                        hash: this.$route.params.hash,
                        password: this.password
                    });
        },
    },
    created() {
        if(this.getUserID)
            this.logoutUser();

        this.checkHash({email: this.$route.params.email, hash: this.$route.params.hash});
    }
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
