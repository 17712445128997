<template>
    <div>
        <!-- <iframe ref="iframe" name="iframetable" id="iframetable" :src="'@static/img/work_plan_images/'+section_id+'/'+getFacultyAcronym+'_1.html'" frameborder="0" /> -->
        
        <a class="bold" v-show="staticHTMLTableStringSubsection" @click="show_table = !show_table">{{TableLinkText}}</a>
        <div v-show="show_table" class="table_static_data" v-html="staticHTMLTableStringSubsection" style="margin-top: 10px;"></div>
    </div>
</template>


<script>
    import router from '../../router/router'
	import { mapActions } from 'vuex';
    //import axios from 'axios'

	
	export default {
		name: 'SectionsDetailTableStaticData',
		components: {
        },
        data: function() {
			return {
                show_table: false,
			}
        },
        props: {
            file_name: {
                type: String,
                required: true
            }
        },
        computed: {
			// Selected section object for level
			subsections_data(){

                let level = this.$store.getters.section_level;

                let subsections = this.$store.getters.subsections_data[level];
   
                // Return subsections for section and level
				return subsections ? subsections : {};
            },
            active_subsection_id(){
                return this.$store.getters.active_subsection_id;
            },

            // Get loged user faculty id
            getFacultyID() {
                return this.$store.getters.getFaculty.id;
            },

            // Get loged user faculty id
            getFacultyAcronym() {
                return this.$store.getters.getFaculty.acronym;
            },

            getTabCode(){
                if(router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela')
                    return 1;
                return 0;
            },

            section_id(){
                return this.$route.params.section_id;
            },

            // Get static html table string faculty subsection
            staticHTMLTableStringSubsection() {
                return this.$store.getters.staticHTMLTableStringSubsection;
            },

            TableLinkText(){
                return this.show_table ? 'Skrij tabelo' : 'Za pregled načrtovanih aktivnosti v prejšnjem letu razširi pogled';
            }

            /* rty(){
                console.log(frames);
                var cssLink = document.createElement("link");
                cssLink.href = "../../scss/style.scss";  
                cssLink.rel = "stylesheet";  
                cssLink.type = "text/css";  
                frames['iframetable'].document.body.appendChild(cssLink); 

            }, */

            /* getTable(){
                console.log('1111');
                let htmlString = require('@static/img/work_plan_images/8/FDV_1.html');
                console.log(htmlString);

                var parsed=new DOMParser.parseFromString(htmlString,'text/html');
                console.log(parsed);
                return '21321321321321';
            }, */
        }, 
        methods: { 		
            ...mapActions([
                'getStaticHTMLTableString'
            ]),

            /* loadFile() {
                console.log('@static/img/work_plan_images/'+this.section_id+'/'+this.getFacultyAcronym+'_1.html');
                axios({
                    method: "get",
                    url: '@static/img/work_plan_images/'+this.section_id+'/'+this.getFacultyAcronym+'_1.html'
                })
                .then(result => {
                    this.input = result.data;
                })
                .catch(error => {
                    console.error("error getting file");
                });
            } */
        },
        created(){
            /* var cssLink = document.createElement("link");
            cssLink.href = "../../scss/style.scss";  
            cssLink.rel = "stylesheet";  
            cssLink.type = "text/css";  
            frames['iframetable'].document.body.appendChild(cssLink);  */

            this.getStaticHTMLTableString({file_name: this.file_name, section_id: this.section_id, table: 'faculty_sub'});
        }
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
