import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'

import sections_list from './modules/sections_list';
import sections_detail from './modules/sections_detail';
import sections_attachments from './modules/sections_attachments';
import analytics from './modules/analytics';

import evalvation from './modules/evalvation';
import evalvation_questionnaire from './modules/evalvation_questionnaire';
import evalvation_dashboard from './modules/evalvation_dashboard';

import users from './modules/users';



import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

Vue.use(Vuex);

export const state = {
    
    // Data for popup modal
    modalVisible: false,
    modalWorkingVisible: false,
	modalComponent: null,
    modalData: null,
    
    // App version
    version: {},

    // Loged in user data
    token: '',
    isAuthenticated: false,
    user: {},
    // Time in ms when login is about to expire
    expirationTime: 4 * 60 * 60 * 1000,


    // Faculty of logged user
    faculty: {},

    // All faculties
    faculties: {},
    sorted_faculties_by_acronym: [],
        
    // School year
    year: '',
    

    // All years (preserve structure due to usage at copy sections from previous year)
    years: {
        '2023/2024': '2023/2024',
        '2022/2023': '2022/2023',
        '2021/2022': '2021/2022',
        '2020/2021': '2020/2021',
        '2019/2020': '2019/2020',
        '2018/2019': '2018/2019',
    },

    // Date of new active year (months starts with 0!) - param choosen indicates if current choosen year meets active year standards
    active_year_date: {month: 5, day: 1, active_year: ''},

};

export const store = new Vuex.Store({
    state,
	getters,
    mutations,
    actions,
    modules: {
        sections_list,
        sections_detail,
        sections_attachments,
        analytics,
        evalvation,
        evalvation_questionnaire,
        evalvation_dashboard,
        users
    }
});